import React, {FC, useState} from 'react';
import {Trans} from '@lingui/macro';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import FormButton from 'components/formComponents/FormButton';
import {renderUnits} from 'components/outputs/structures/utils';

const Table2DStruct: FC<any> = (props) => {
  const {data} = props;

  const [displayAll, setDisplayAll] = useState<boolean>(false);

  const rowsDisplayedInLessMode = 10;

  const handleSetDisplayAll = () => {
    setDisplayAll((prevState) => !prevState);
  };

  const tableData = data?.['2d_table_data'];

  const renderTableHeadCell = (cell: {label: string; unit?: string}, index: number) => {
    if (cell?.unit) {
      return (
        <TableCell key={index} sx={{py: 1, px: 2, whiteSpace: 'nowrap', verticalAlign: 'baseline'}}>
          {cell?.label} [{renderUnits(cell.unit)}]
        </TableCell>
      );
    } else {
      return (
        <TableCell key={index} sx={{py: 1, px: 2, whiteSpace: 'nowrap', verticalAlign: 'baseline'}}>
          {cell?.label}
        </TableCell>
      );
    }
  };

  return (
    <Paper elevation={2} sx={{width: '100%', padding: 2, mb: 5}}>
      <Typography variant={'body1'} color={'primary'} sx={{fontWeight: 500, mb: 2}}>
        {data?.name}
      </Typography>
      <Box sx={{overflow: 'auto'}}>
        <TableContainer sx={{maxHeight: 412}}>
          <Table stickyHeader={true} sx={{fontSize: 14}} padding={'none'} size={'small'}>
            <TableHead>
              <TableRow>
                {tableData?.header?.map((cell: {label: string; unit?: string}, index: number) =>
                  renderTableHeadCell(cell, index)
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.body?.length > rowsDisplayedInLessMode && !displayAll
                ? tableData?.body
                    ?.slice(0, rowsDisplayedInLessMode)
                    ?.map((record: any, index: number) => (
                      <TableRow key={index} sx={index % 2 ? {} : {backgroundColor: '#eeeeee'}}>
                        {record.map((val: string, index: number) => (
                          <TableCell key={index} sx={{py: 1, px: 2}}>
                            {val}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                : tableData?.body?.map((record: any, index: number) => (
                    <TableRow key={index} sx={index % 2 ? {} : {backgroundColor: '#eeeeee'}}>
                      {record.map((val: string, index: number) => (
                        <TableCell key={index} sx={{py: 1, px: 2}}>
                          {val}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              {tableData?.body?.length > rowsDisplayedInLessMode && (
                <TableRow>
                  <td
                    colSpan={tableData?.header?.length}
                    style={{paddingTop: 20, paddingBottom: 10, textAlign: 'center'}}
                  >
                    <FormButton
                      sx={{px: 4, mr: 4}}
                      text={
                        displayAll ? (
                          <Grid container>
                            <ExpandLessIcon fontSize={'small'} sx={{mr: 2}} />
                            <Trans>Display less</Trans>
                          </Grid>
                        ) : (
                          <Grid container>
                            <ExpandMoreIcon fontSize={'small'} sx={{mr: 2}} />
                            <Trans>Display all</Trans>
                          </Grid>
                        )
                      }
                      onClick={handleSetDisplayAll}
                    />
                  </td>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default Table2DStruct;
