import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {updateBreadcrumbs} from 'redux/actions/breadcrumbsAction';
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import {Trans} from '@lingui/macro';
import {Grid, Step, StepLabel, Stepper} from '@mui/material';
import DatasetBasicInfoForm from 'components/forms/DatasetBasicInfoForm';
import DatasetCalibrationMatricesForm from 'components/forms/DatasetCalibrationMatricesForm';
import DatasetUploadForm from 'components/forms/DatasetUploadForm';
import {DatasetUploadType} from 'types/datasetType';
import PrivateBreadcrumbs from 'layouts/Private/PrivateBreadcrumbs';

type DatasetUploadPageProps = {
  updateBreadcrumbs: (data: any) => void;
};

const DatasetUploadPage: FC<DatasetUploadPageProps> = (props) => {
  const {updateBreadcrumbs} = props;

  const [activeStep, setActiveStep] = useState(0);

  const [calibrationFiles, setCalibrationFiles] = useState<any[]>([]);
  const [datasetUploadsData, setDatasetUploadsData] = useStateWithCallbackLazy<DatasetUploadType[]>(
    []
  );

  const [datasetBasicInfo, setDatasetBasicInfo] = useState({name: '', description: '', tags: []});

  useEffect(() => {
    updateBreadcrumbs({dataset: {}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item xs={12} sm={12} md={12} lg={11} xl={10}>
      <PrivateBreadcrumbs />
      <Grid item container xs={12} sx={{maxHeight: `calc(100vh - 150px)`, overflow: 'auto'}}>
        <Stepper activeStep={activeStep} sx={{width: '100%', pb: 8, px: 8}}>
          <Step>
            <StepLabel color="primary">
              <Trans>Dataset upload</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel color="primary">
              <Trans>Calibration matrices upload</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel color="primary">
              <Trans>Basic dataset info</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 ? (
          <DatasetUploadForm
            setActiveStep={setActiveStep}
            datasetUploadsData={datasetUploadsData}
            setDatasetUploadsData={setDatasetUploadsData}
          />
        ) : activeStep === 1 ? (
          <DatasetCalibrationMatricesForm
            setActiveStep={setActiveStep}
            calibrationFiles={calibrationFiles}
            setCalibrationFiles={setCalibrationFiles}
          />
        ) : (
          <DatasetBasicInfoForm
            setActiveStep={setActiveStep}
            datasetUploadsData={datasetUploadsData}
            calibrationFiles={calibrationFiles}
            datasetBasicInfo={datasetBasicInfo}
            setDatasetBasicInfo={setDatasetBasicInfo}
          />
        )}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({updateBreadcrumbs}, dispatch);
};

export default connect(null, mapDispatchToProps)(DatasetUploadPage);
