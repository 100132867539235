// @ts-ignore
import Plotly from 'plotly.js-dist-min';
import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {getCluster, getFrame} from 'redux/actions/datasetAction';
import {Trans} from '@lingui/macro';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import DownloadButtons from 'components/outputs/DownloadButtons';

const FrameHeatmap: FC<any> = (props) => {
  const {frames, framesLoading, getFrame, getCluster, cluster} = props;

  const [visibleDataStatistic, setVisibleDataStatistic] = useState<any>({
    min: 0,
    max: 0,
    sum: 0,
    mean: 0,
  });

  const {id, pId} = useParams();

  const handleDownload = (format = 'png') => {
    const options = {
      format: format,
      width: 1280,
      height: 960,
      filename: `Frame - num:${cluster?.num} - id:${cluster?.id}`,
    };

    Plotly.downloadImage('frame', options);
  };

  const handleNextFrame = () => {
    getFrame(id, pId, frames.num + 1).then((res: any) => {
      if (res.clusters?.[0]) {
        getCluster(id, pId, res.clusters[0].id);
      }
    });
  };

  const handlePrevFrame = () => {
    getFrame(id, pId, frames.num - 1).then((res: any) => {
      if (res.clusters?.[0]) {
        getCluster(id, pId, res.clusters[0].id);
      }
    });
  };

  const handleGetCluster = (c: any) => {
    if (c.id !== cluster.id) {
      getCluster(id, pId, c.id);
    }
  };

  const getHeatmapData = (data: number[]) => {
    return {
      z: data,
      type: 'heatmap',
      colorscale: 'YlGnBu',
    };
  };

  const plotLayout = {
    margin: {
      l: 60,
      r: 20,
      t: 40,
      b: 40,
    },
    xaxis: {
      title: {
        text: 'X [px]',
      },
    },
    yaxis: {
      title: {
        text: 'Y [px]',
      },
    },
    legend: {
      yanchor: 'right',
      xanchor: 'top',
      y: 1.1,
    },
  };

  useEffect(() => {
    const boxWidth = document.getElementById('frame')?.clientWidth;

    if (frames.data) {
      if (boxWidth) {
        Plotly.react('frame', [getHeatmapData(frames.data)], {
          ...plotLayout,
          width: boxWidth,
          height: boxWidth * 0.83,
        });
      }
      const flatten = frames.data?.reduce((prev: any, curr: any) => {
        return [...prev, ...curr];
      }, []);

      const min = Math.min(...flatten);

      const max = Math.max(...flatten);

      const sum = flatten.reduce((prev: any, curr: any) => {
        return prev + (curr ? curr : 0);
      }, 0);

      const mean = flatten?.length / sum;

      setVisibleDataStatistic({min: min, max: max, sum: sum, mean: mean});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frames]);

  useEffect(() => {
    Plotly.newPlot('frame', [], plotLayout, {displaylogo: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion
      sx={{
        width: '100%',
        mb: 5,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& .MuiCollapse-root': {
          width: '100%',
        },
      }}
      elevation={2}
      disableGutters={true}
      defaultExpanded={true}
      expanded={true}
    >
      <AccordionSummary>
        <Typography variant={'h6'} align={'center'} sx={{width: '100%'}}>
          <Trans>Frame</Trans>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
          <IconButton size={'small'} onClick={handlePrevFrame} disabled={!frames.has_prev}>
            <ChevronLeftIcon />
          </IconButton>
          <Box sx={{minWidth: 60, display: 'inline-flex', justifyContent: 'center'}}>
            {frames.num}
          </Box>
          <IconButton size={'small'} onClick={handleNextFrame} disabled={!frames.has_next}>
            <ChevronRightIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12} sx={{position: 'relative'}}>
          <LoadingMask
            loading={framesLoading}
            containerStyle={{display: 'flex', justifyContent: 'center'}}
          >
            <Box id="frame" sx={{width: '100%', boxSizing: 'border-box'}} />
          </LoadingMask>
        </Grid>

        <DownloadButtons handleDownload={handleDownload} />

        <Grid container item xs={12} sx={{mt: 4}}>
          <Grid item xs={5.5}>
            <Typography variant={'body2'} align={'center'} sx={{fontStyle: 'italic'}}>
              <Trans>Frame statistics</Trans>
            </Typography>
            <List sx={{fontSize: 14, paddingBottom: 0}}>
              <ListItem disablePadding>
                <Grid container justifyContent={'space-between'}>
                  <Box sx={{maxWidth: 60, width: '100%'}}>
                    <Trans>Min:</Trans>
                  </Box>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {visibleDataStatistic.min.toFixed(1)}
                  </Box>
                </Grid>
              </ListItem>
              <ListItem disablePadding>
                <Grid container justifyContent={'space-between'}>
                  <Box sx={{maxWidth: 60, width: '100%'}}>
                    <Trans>Max:</Trans>
                  </Box>
                  <Box sx={{display: 'inline-flex', justifyContent: 'flex-end'}}>
                    {visibleDataStatistic.max.toFixed(1)}
                  </Box>
                </Grid>
              </ListItem>
              <ListItem disablePadding>
                <Grid container justifyContent={'space-between'}>
                  <Box sx={{maxWidth: 60, width: '100%'}}>
                    <Trans>Mean:</Trans>
                  </Box>
                  <Box sx={{display: 'inline-flex', justifyContent: 'flex-end'}}>
                    {visibleDataStatistic.mean.toFixed(1)}
                  </Box>
                </Grid>
              </ListItem>
              <ListItem disablePadding>
                <Grid container justifyContent={'space-between'}>
                  <Box sx={{maxWidth: 60, width: '100%'}}>
                    <Trans>Sum:</Trans>
                  </Box>
                  <Box sx={{display: 'inline-flex', justifyContent: 'flex-end'}}>
                    {visibleDataStatistic.sum.toFixed(1)}
                  </Box>
                </Grid>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={1}>
            <Divider orientation="vertical" sx={{mx: '50%'}} />
          </Grid>

          <Grid item xs={5.5}>
            <Typography variant={'body2'} align={'center'} sx={{fontStyle: 'italic'}}>
              <Trans>Clusters in frame</Trans>
            </Typography>
            <ul style={{fontSize: 14, marginTop: 8, marginBottom: 0}}>
              {frames?.clusters?.map((cluster: {num: number; id: number}) => {
                return (
                  <li key={cluster.id}>
                    <Box
                      sx={{
                        cursor: 'pointer',
                        color: '#0097A4',
                        '&:hover': {color: '#006070', textDecoration: 'underline'},
                      }}
                      onClick={() => handleGetCluster(cluster)}
                    >
                      <Trans>cluster num.</Trans> {cluster.num}
                    </Box>
                  </li>
                );
              })}
            </ul>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (store: any) => {
  return {
    frames: store.datasets.frames,
    framesLoading: store.datasets.framesLoading,
    cluster: store.datasets.cluster,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getFrame,
      getCluster,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FrameHeatmap);
