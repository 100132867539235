import {GET_TAGS_FAIL, GET_TAGS_REQUEST, GET_TAGS_SUCCESS} from 'redux/actionTypes';
import {RequestThunkAction} from 'types/general';
import endpoints from 'utils/endpoints';
import {globalApiErrorHandler} from 'utils/functions';
import httpClient from 'utils/httpClient';

export const getTags = (limit = 100, offset = 0): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_TAGS_REQUEST});
    return httpClient
      .get(endpoints.tags, {limit: limit, offset: offset})
      .then((res) => {
        dispatch({type: GET_TAGS_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_TAGS_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const postTag = (data: {name: string; color: string}): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .post(endpoints.tags, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const deleteTag = (id: number): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .del(`${endpoints.tags}/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};
