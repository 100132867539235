import React, {FC} from 'react';
import {Trans} from '@lingui/macro';
import {Grid, Typography} from '@mui/material';
import DialogWindow from 'components/DialogWindow';
import GraphCheckBox from 'components/outputs/GraphCheckBox';

const HistogramDialog: FC<any> = (props) => {
  const {
    setSelectedClass,
    selectedClass,
    open,
    onClose,
    subPlots,
    handleChangeScale,
    logScaleX,
    logScaleY,
    logScaleZ,
    is2D,
    selectedPlot,
  } = props;

  const handleChangeClassFilter = (subPlot: string, checked: boolean) => {
    if (is2D) {
      setSelectedClass(subPlot);
    } else {
      if (checked) {
        setSelectedClass((prevState: string) => {
          if (Array.isArray(prevState)) {
            return [...prevState, subPlot];
          } else {
            return [subPlot];
          }
        });
      } else {
        setSelectedClass((prevState: string) => {
          if (Array.isArray(prevState)) {
            return prevState.filter((plot: string) => plot !== subPlot);
          }
        });
      }
    }
  };

  return (
    <DialogWindow
      maxWidth={subPlots ? 'md' : 'xs'}
      fullWidth={true}
      title={
        is2D ? <Trans>2D Histogram plot setting</Trans> : <Trans>1D Histogram plot setting</Trans>
      }
      open={open}
      onClose={onClose}
    >
      <Grid container>
        {subPlots && (
          <Grid container item xs={7} alignContent={'flex-start'}>
            {selectedPlot?.type ? (
              <Typography variant={'body1'}>
                <Trans>Sub plots</Trans>
              </Typography>
            ) : (
              <Typography variant={'body1'}>
                <Trans>Particle classes</Trans>
              </Typography>
            )}

            {Object.entries(subPlots).map(([key, value], index) => (
              <Grid item xs={12} key={index}>
                <GraphCheckBox
                  label={value}
                  name={String(key)}
                  checked={selectedClass?.includes(key)}
                  handleChange={(checked: boolean) => handleChangeClassFilter(key, checked)}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Grid container item xs={subPlots ? 5 : 12} alignContent={'flex-start'}>
          {is2D ? (
            <>
              <Typography variant={'body1'}>
                <Trans>Scales</Trans>
              </Typography>
              <Grid item xs={12}>
                <GraphCheckBox
                  label={<Trans>Logarithmic scale Z</Trans>}
                  checked={logScaleZ}
                  handleChange={() => handleChangeScale('z')}
                />
              </Grid>
            </>
          ) : (
            <>
              <Typography variant={'body1'}>
                <Trans>Scales</Trans>
              </Typography>
              <Grid item xs={12}>
                <GraphCheckBox
                  label={<Trans>Logarithmic scale X</Trans>}
                  checked={logScaleX}
                  handleChange={() => handleChangeScale('x')}
                />
              </Grid>
              <Grid item xs={12}>
                <GraphCheckBox
                  label={<Trans>Logarithmic scale Y</Trans>}
                  checked={logScaleY}
                  handleChange={() => handleChangeScale('y')}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </DialogWindow>
  );
};

export default HistogramDialog;
