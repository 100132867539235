// @ts-ignore
import Plotly from 'plotly.js-dist-min';
import React, {FC, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {getCluster, getFrame} from 'redux/actions/datasetAction';
import {Trans} from '@lingui/macro';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import DownloadButtons from 'components/outputs/DownloadButtons';
import {ClusterType} from 'types/datasetType';

const ClusterHeatmap: FC<any> = (props) => {
  const {getFrame, getCluster, frames, cluster, clusterLoading} = props;

  const {id, pId} = useParams();

  const handleDownload = (format = 'png') => {
    const options = {
      format: format,
      width: 1280,
      height: 960,
      filename: `Cluster - num:${cluster?.num} - id:${cluster?.id}`,
    };

    Plotly.downloadImage('cluster', options);
  };

  const handleNextCluster = () => {
    const nextCluster = frames.clusters.find(
      (cls: any) => Number(cls.num) === Number(cluster.num + 1)
    );

    if (nextCluster?.id) {
      getCluster(id, pId, nextCluster.id);
    } else {
      getFrame(id, pId, frames.num + 1).then((res: any) => {
        if (res.clusters?.[0]) {
          getCluster(id, pId, res.clusters[0].id);
        }
      });
    }
  };

  const handlePrevCluster = () => {
    const prevCluster = frames.clusters.find(
      (cls: any) => Number(cls.num) === Number(cluster.num - 1)
    );

    if (prevCluster?.id) {
      getCluster(id, pId, prevCluster.id);
    } else {
      getFrame(id, pId, frames.num - 1).then((res: any) => {
        if (res.clusters?.[0]) {
          getCluster(id, pId, res.clusters[0].id);
        }
      });
    }
  };

  const getHeatmapData = (data: number[]) => {
    return {
      z: data,
      type: 'heatmap',
      colorscale: 'YlGnBu',
    };
  };

  const plotLayout = {
    margin: {
      l: 60,
      r: 20,
      t: 40,
      b: 40,
    },
    xaxis: {
      title: {
        text: 'X [px]',
      },
    },
    yaxis: {
      title: {
        text: 'Y [px]',
      },
    },
    legend: {
      yanchor: 'right',
      xanchor: 'top',
      y: 1.1,
    },
  };

  useEffect(() => {
    const boxWidth = document.getElementById('cluster')?.clientWidth;
    if (cluster.data) {
      if (boxWidth) {
        Plotly.react('cluster', [getHeatmapData(cluster.data)], {
          ...plotLayout,
          width: boxWidth,
          height: boxWidth * 0.83,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster]);

  useEffect(() => {
    Plotly.newPlot('cluster', [], plotLayout, {displaylogo: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion
      sx={{
        width: '100%',
        mb: 5,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& .MuiCollapse-root': {
          width: '100%',
        },
      }}
      elevation={2}
      disableGutters={true}
      defaultExpanded={true}
      expanded={true}
    >
      <AccordionSummary>
        <Typography variant={'h6'} align={'center'} sx={{width: '100%'}}>
          <Trans>Cluster</Trans>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
          <IconButton size={'small'} onClick={handlePrevCluster} disabled={!cluster.has_prev}>
            <ChevronLeftIcon />
          </IconButton>
          <Box sx={{minWidth: 60, display: 'inline-flex', justifyContent: 'center'}}>
            {cluster.num}
          </Box>
          <IconButton size={'small'} onClick={handleNextCluster} disabled={!cluster.has_next}>
            <ChevronRightIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{position: 'relative'}}>
          <LoadingMask
            loading={clusterLoading}
            containerStyle={{display: 'flex', justifyContent: 'center'}}
          >
            <Box id="cluster" sx={{width: '100%', boxSizing: 'border-box'}} />
          </LoadingMask>
        </Grid>

        <DownloadButtons handleDownload={handleDownload} />

        <Grid item xs={12} sx={{mt: 4}}>
          <Typography variant={'body2'} align={'center'} sx={{fontStyle: 'italic'}}>
            <Trans>Features</Trans>
          </Typography>
          <Table sx={{fontSize: 14}} padding={'none'} size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell sx={{p: 1}}>Name</TableCell>
                <TableCell sx={{p: 1}} align="right">
                  Value
                </TableCell>
                <TableCell sx={{p: 1}}>Unit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cluster?.features?.map((feat: ClusterType, index: number) => {
                return (
                  <TableRow key={index} sx={index % 2 ? {} : {backgroundColor: '#eeeeee'}}>
                    <TableCell sx={{p: 1}}>{`${feat.feature}`}</TableCell>
                    <TableCell sx={{p: 1}} align="right">{`${feat.value}`}</TableCell>
                    <TableCell sx={{p: 1}}>{`${feat.unit}`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (store: any) => {
  return {
    frames: store.datasets.frames,
    cluster: store.datasets.cluster,
    clusterLoading: store.datasets.clusterLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getFrame,
      getCluster,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ClusterHeatmap);
