import React, {FC} from 'react';
import {Trans, t} from '@lingui/macro';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FormButton from 'components/formComponents/FormButton';
import {ADDITIONAL_PARAMS_TYPES} from 'constants/enums';
import {AdditionalParamType} from 'types/datasetType';
import {createFile} from 'utils/functions';
import i18nProvider from 'utils/i18n';

const AdditionalParamsForm: FC<any> = (props) => {
  const {initData, setActiveStep, handleCreateProcessing, setAdditionalParams, additionalParams} =
    props;

  const handleUploadAdditionalParamsFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files && Array.from(event.target.files);

    files?.forEach((file: any) => {
      const reader = new FileReader();

      reader.onloadend = function (event: any) {
        if (event.target.readyState !== FileReader.DONE) {
          return;
        }

        setAdditionalParams((prevState: AdditionalParamType[]) => [
          ...prevState,
          {
            data: createFile(event.target.result, file.name),
            filename: file.name,
            index: prevState?.length,
            type: '',
            number: null,
          },
        ]);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleChangeParamType = (param: AdditionalParamType, e: SelectChangeEvent<unknown>) => {
    setAdditionalParams((prevState: AdditionalParamType[]) => [
      ...prevState.filter((p: AdditionalParamType) => p.index !== param.index),
      {
        ...param,
        type: e.target.value,
      },
    ]);
  };

  const handleChangeParamNumber = (
    param: AdditionalParamType,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAdditionalParams((prevState: AdditionalParamType[]) => [
      ...prevState.filter((p: AdditionalParamType) => p.index !== param.index),
      {
        ...param,
        number: e.target.value,
      },
    ]);
  };

  const handleRemoveAdditionalFile = (param: AdditionalParamType) => {
    setAdditionalParams((prevState: AdditionalParamType[]) => [
      ...prevState.filter((p: AdditionalParamType) => p.index !== param.index),
    ]);
  };

  return (
    <>
      <Grid container item xs={12} sx={{minHeight: 200}} alignItems={'flex-start'}>
        <Grid item xs={4}>
          <Typography variant={'h6'}>Additional param files</Typography>
          <Stack direction="row" alignItems={'center'} spacing={5} sx={{mt: 2.5}}>
            <Button
              variant={'contained'}
              size={'small'}
              onClick={() => {
                // reset file input values
                const input = document.getElementById('additional-params');

                input?.setAttribute('type', 'text');
                input?.setAttribute('type', 'file');

                input?.click();
              }}
            >
              <Trans>Upload</Trans>
            </Button>
          </Stack>
          <input
            style={{display: 'none'}}
            id="additional-params"
            type={'file'}
            multiple={true}
            onChange={(e) => handleUploadAdditionalParamsFile(e)}
          />
        </Grid>

        <Grid container item xs={8}>
          <Typography variant={'h6'}>Params file list</Typography>
          {additionalParams?.length ? (
            additionalParams
              ?.sort((a: AdditionalParamType, b: AdditionalParamType) => a.index - b.index)
              .map((param: AdditionalParamType, index: number) => (
                <Grid
                  container
                  item
                  key={index}
                  xs={12}
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                  sx={{mb: 2}}
                >
                  {index !== 0 && (
                    <Grid item xs={12}>
                      <Divider light={true} />
                    </Grid>
                  )}
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'}>{param.filename}</Typography>
                  </Grid>
                  <Grid item xs={'auto'} container alignItems={'flex-end'}>
                    <Select
                      size={'small'}
                      variant={'standard'}
                      sx={{height: 33, minHeight: 33, minWidth: 160, fontSize: 14, mr: 2}}
                      onChange={(e) => handleChangeParamType(param, e)}
                      value={param.type || ''}
                      displayEmpty={true}
                    >
                      <MenuItem value={''}>
                        <Typography variant={'body2'} color={'text.secondary'}>
                          <em>
                            <Trans>Type</Trans>
                          </em>
                        </Typography>
                      </MenuItem>
                      {ADDITIONAL_PARAMS_TYPES?.map((option: any, index: number) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>

                    <Box sx={{minWidth: 110, width: 110, display: 'flex', alignItems: 'flex-end'}}>
                      {param.type === 'masking_n' && (
                        <TextField
                          variant={'standard'}
                          type={'number'}
                          sx={{
                            height: 33,
                            minHeight: 33,
                            fontSize: 14,
                            mr: 2,
                            justifyContent: 'flex-end',
                            '& input': {
                              textAlign: 'end',
                            },
                          }}
                          placeholder={i18nProvider._(t`N number`)}
                          value={param.number}
                          inputProps={{min: 0, step: 1}}
                          onChange={(e) => handleChangeParamNumber(param, e)}
                        />
                      )}
                    </Box>

                    <IconButton color={'error'} onClick={() => handleRemoveAdditionalFile(param)}>
                      <DeleteOutlineIcon fontSize={'small'} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))
          ) : (
            <Grid container item xs={12} alignItems={'center'}>
              <Typography
                color={'text.disabled'}
                variant={'body1'}
                sx={{width: '100%', py: 10}}
                align={'center'}
              >
                <Trans>No additional files uploaded</Trans>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid sx={{mt: 5}} container item xs={12} justifyContent={'space-between'}>
        <FormButton
          variant={'text'}
          sx={{px: 4}}
          text={<Trans>Back</Trans>}
          onClick={() => setActiveStep(0)}
        />
        <FormButton
          disabled={additionalParams?.some((param: AdditionalParamType) => param.type === '')}
          sx={{px: 4}}
          text={initData?.id ? <Trans>Edit</Trans> : <Trans>Create</Trans>}
          onClick={handleCreateProcessing}
        />
      </Grid>
    </>
  );
};

export default AdditionalParamsForm;
