import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {getDataForSpecifiedTab} from 'redux/actions/outputAction';
import {Grid} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import {renderStruct} from 'components/outputs/structures/utils';

type TabWrapper = {
  lists: [];
  listsLoading: boolean;
  tabRef: string;
  getDataForSpecifiedTab: any;
};

const TabWrapper = (props: TabWrapper) => {
  const {tabRef, lists, listsLoading, getDataForSpecifiedTab} = props;

  const {id, pId} = useParams();

  useEffect(() => {
    if (tabRef) {
      getDataForSpecifiedTab(id, pId, {tab_ref: tabRef});
    }
    // eslint-disable-next-line
  }, [tabRef]);

  return (
    <Grid container sx={{position: 'relative'}}>
      <LoadingMask loading={listsLoading} containerStyle={{width: '100%'}}>
        <>
          {lists?.map((record: any, index: number) => (
            <Grid xs={12} item key={index}>
              {renderStruct(record, tabRef)}
            </Grid>
          ))}
        </>
      </LoadingMask>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getDataForSpecifiedTab,
    },
    dispatch
  );
};

const mapStateToProps = (store: any) => {
  return {
    lists: store.outputs.lists,
    listsLoading: store.outputs.listsLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabWrapper);
