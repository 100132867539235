import {
  GET_CLUSTER_FAIL,
  GET_CLUSTER_REQUEST,
  GET_CLUSTER_SUCCESS,
  GET_DATASET_FAIL,
  GET_DATASET_LIST_FAIL,
  GET_DATASET_LIST_REQUEST,
  GET_DATASET_LIST_SUCCESS,
  GET_DATASET_PARAMS_FAIL,
  GET_DATASET_PARAMS_REQUEST,
  GET_DATASET_PARAMS_SUCCESS,
  GET_DATASET_PROCESSINGS_FAIL,
  GET_DATASET_PROCESSINGS_REQUEST,
  GET_DATASET_PROCESSINGS_SUCCESS,
  GET_DATASET_PROCESSING_FAIL,
  GET_DATASET_PROCESSING_REQUEST,
  GET_DATASET_PROCESSING_SUCCESS,
  GET_DATASET_REQUEST,
  GET_DATASET_SUCCESS,
  GET_DATASET_UPLOAD_FAIL,
  GET_DATASET_UPLOAD_REQUEST,
  GET_DATASET_UPLOAD_SUCCESS,
  GET_FRAMES_FAIL,
  GET_FRAMES_REQUEST,
  GET_FRAMES_SUCCESS,
  GET_PLOTS_FAIL,
  GET_PLOTS_REQUEST,
  GET_PLOTS_SUCCESS,
  GET_SAMPLING_LIST_FAIL,
  GET_SAMPLING_LIST_REQUEST,
  GET_SAMPLING_LIST_SUCCESS,
  SET_PARTICLE_CLASSES_FILTER,
} from 'redux/actionTypes';

const initState = {
  listLoading: false,
  listData: [],
  listMeta: {},

  detailLoading: false,
  detailData: {},

  datasetUpload: {},
  datasetUploadLoading: false,

  datasetProcessingData: [],
  datasetProcessingLoading: false,
  datasetProcessingMeta: {},

  processingParamsData: [],
  processingParamsLoading: false,
  processingParamsMeta: {},

  processingDetailData: {},
  processingDetailLoading: false,

  samplingList: {},
  samplingListLoading: false,

  particleClasses: {},
  particleClassesLoading: false,
  particleClassesFilters: [],

  frames: {},
  framesLoading: false,

  cluster: {},
  clusterLoading: false,

  plots: [],
  plotsLoading: false,
};

export const datasetReducer = (state = initState, action: any = null) => {
  switch (action.type) {
    case GET_DATASET_LIST_REQUEST: {
      return {
        ...state,
        listLoading: true,
      };
    }
    case GET_DATASET_LIST_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        listData: action.data.objects,
        listMeta: action.data.meta,
      };
    }
    case GET_DATASET_LIST_FAIL: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case GET_DATASET_REQUEST: {
      return {
        ...state,
        detailLoading: true,
      };
    }
    case GET_DATASET_SUCCESS: {
      return {
        ...state,
        detailLoading: false,
        detailData: action.data,
      };
    }
    case GET_DATASET_FAIL: {
      return {
        ...state,
        detailLoading: false,
      };
    }
    case GET_DATASET_UPLOAD_REQUEST: {
      return {
        ...state,
        datasetUploadLoading: true,
      };
    }
    case GET_DATASET_UPLOAD_SUCCESS: {
      return {
        ...state,
        datasetUploadLoading: false,
        datasetUpload: action.data,
      };
    }
    case GET_DATASET_UPLOAD_FAIL: {
      return {
        ...state,
        datasetUploadLoading: false,
      };
    }
    case GET_DATASET_PROCESSINGS_REQUEST: {
      return {
        ...state,
        datasetProcessingLoading: true,
      };
    }
    case GET_DATASET_PROCESSINGS_SUCCESS: {
      return {
        ...state,
        datasetProcessingData: action.data.objects,
        datasetProcessingLoading: false,
        datasetProcessingMeta: action.data.meta,
      };
    }
    case GET_DATASET_PROCESSINGS_FAIL: {
      return {
        ...state,
        datasetProcessingLoading: false,
      };
    }
    case GET_DATASET_PROCESSING_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_DATASET_PROCESSING_SUCCESS: {
      return {
        ...state,
      };
    }
    case GET_DATASET_PROCESSING_FAIL: {
      return {
        ...state,
      };
    }
    case GET_DATASET_PARAMS_REQUEST: {
      return {
        ...state,
        processingParamsLoading: true,
      };
    }
    case GET_DATASET_PARAMS_SUCCESS: {
      return {
        ...state,
        processingParamsLoading: false,
        processingParamsMeta: action.data.meta,
        processingParamsData: action.data.objects,
      };
    }
    case GET_DATASET_PARAMS_FAIL: {
      return {
        ...state,
        processingParamsLoading: false,
      };
    }
    case GET_SAMPLING_LIST_REQUEST: {
      return {
        ...state,
        samplingListLoading: true,
        particleClassesLoading: true,
      };
    }
    case GET_SAMPLING_LIST_SUCCESS: {
      return {
        ...state,
        samplingListLoading: false,
        samplingList: action.data,
        particleClasses: action.data?.particle_classes,
        particleClassesFilters: action.data?.particle_classes?.map((particle: any) => ({
          ...particle,
          visible: true,
        })),
      };
    }
    case GET_SAMPLING_LIST_FAIL: {
      return {
        ...state,
        samplingListLoading: false,
      };
    }
    case SET_PARTICLE_CLASSES_FILTER: {
      return {
        ...state,
        particleClassesFilters: action.data,
      };
    }
    case GET_FRAMES_REQUEST: {
      return {
        ...state,
        framesLoading: true,
      };
    }
    case GET_FRAMES_SUCCESS: {
      return {
        ...state,
        framesLoading: false,
        frames: action.data,
      };
    }
    case GET_FRAMES_FAIL: {
      return {
        ...state,
        framesLoading: false,
      };
    }
    case GET_CLUSTER_REQUEST: {
      return {
        ...state,
        clusterLoading: true,
      };
    }
    case GET_CLUSTER_SUCCESS: {
      return {
        ...state,
        clusterLoading: false,
        cluster: action.data,
      };
    }
    case GET_CLUSTER_FAIL: {
      return {
        ...state,
        clusterLoading: false,
      };
    }
    case GET_PLOTS_REQUEST: {
      return {
        ...state,
        plotsLoading: true,
      };
    }
    case GET_PLOTS_SUCCESS: {
      return {
        ...state,
        plotsLoading: false,
        plots: action.data,
      };
    }
    case GET_PLOTS_FAIL: {
      return {
        ...state,
        plotsLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
