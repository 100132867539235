// @ts-ignore
import Plotly from 'plotly.js-dist-min';
import React, {FC, useEffect} from 'react';
import {Box, Grid} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import DownloadButtons from 'components/outputs/DownloadButtons';
import {getHistogramData, getPlotLayout} from 'components/outputs/SpatialMap/helpers';

const SpatialMapGraph: FC<any> = (props) => {
  const {logScaleZ, graphId, selectedPlot, graphData, graphDataLoading, selectedSubplot} = props;

  const handleDownload = (format = 'png') => {
    const options = {
      format: format,
      width: 1280,
      height: 960,
      filename: `${selectedPlot?.feature} - ${selectedSubplot}`,
    };

    Plotly.downloadImage(graphId, options);
  };

  useEffect(() => {
    if (graphData) {
      Plotly.react(
        graphId,
        [getHistogramData(graphData, logScaleZ)],
        getPlotLayout(graphId, graphData, logScaleZ)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, logScaleZ]);

  useEffect(() => {
    Plotly.newPlot(graphId, [], getPlotLayout(graphId), {displaylogo: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12} sx={{position: 'relative'}}>
        <LoadingMask
          loading={graphDataLoading}
          containerStyle={{display: 'flex', justifyContent: 'center'}}
        >
          <Box sx={{width: '100%', boxSizing: 'border-box'}} id={graphId} />
        </LoadingMask>
      </Grid>

      <DownloadButtons handleDownload={handleDownload} />
    </>
  );
};

export default SpatialMapGraph;
