import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {post1dHistogram, post2dHistogram} from 'redux/actions/datasetAction';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import HistogramDialog from 'components/outputs/HistogramDialog';
import HistogramGraph from 'components/outputs/HistogramGraph';
import SpatialMapGraph from 'components/outputs/SpatialMapGraph';

const HistogramStruct: FC<any> = (props) => {
  const {
    post1dHistogram,
    post2dHistogram,
    data,
    plots,
    plotsLoading,
    graphId,
    plotIndex = 0,
  } = props;

  const {id, pId} = useParams();

  const [logScaleX, setLogScaleX] = useState<boolean>(false);
  const [logScaleY, setLogScaleY] = useState<boolean>(false);
  const [logScaleZ, setLogScaleZ] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [selectedPlot, setSelectedPlot] = useState<{
    category: string;
    feature: string;
    sub_plots: any;
  }>({category: '', feature: '', sub_plots: null});

  const [selectedSubplot, setSelectedSubplot] = useState<string | null | string[]>(null);

  const [histogramLoading, setHistogramLoading] = useState<boolean>(false);
  const [histogramData, setHistogramData] = useState();

  const [histogram2dLoading, setHistogram2dLoading] = useState<boolean>(false);
  const [histogram2dData, setHistogram2dData] = useState();

  const handleChangeScale = (axis: 'x' | 'y' | 'z') => {
    if (axis === 'x') {
      setLogScaleX((prevState: boolean) => !prevState);
    } else if (axis === 'y') {
      setLogScaleY((prevState: boolean) => !prevState);
    } else {
      setLogScaleZ((prevState: boolean) => !prevState);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePlot = (e: any) => {
    setSelectedPlot(e.target.value);
  };

  const setInitSubplot = (subPlots: Record<string, any>) => {
    if (subPlots) {
      if (subPlots['Total']) {
        setSelectedSubplot(['Total']);
      } else {
        setSelectedSubplot([Object.keys(subPlots)?.[0]]);
      }
    }
  };

  useEffect(() => {
    if (plots?.[plotIndex]) {
      setSelectedPlot(plots[plotIndex]);
      setInitSubplot(plots[plotIndex]?.sub_plots);
    } else if (data?.category && data?.feature) {
      setSelectedPlot(data);
      setInitSubplot(data?.sub_plots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plots, plotsLoading]);

  useEffect(() => {
    if (selectedPlot?.feature && selectedSubplot?.[0]) {
      if (selectedPlot?.category?.includes('2d_histogram')) {
        setHistogram2dLoading(true);
        post2dHistogram(id, pId, selectedPlot.category, selectedPlot.feature, ...selectedSubplot)
          .then((res: any) => {
            setHistogram2dData(res);
          })
          .finally(() => {
            setHistogram2dLoading(false);
          });
      } else if (selectedPlot?.category?.includes('1d_histogram')) {
        setHistogramLoading(true);
        post1dHistogram(id, pId, {
          category: selectedPlot.category,
          feature: selectedPlot.feature,
          sub_plots: selectedSubplot,
        })
          .then((res: any) => {
            setHistogramData(res);
          })
          .finally(() => {
            setHistogramLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlot, selectedSubplot]);

  return (
    <Paper elevation={2} sx={{width: '100%', padding: 2, mb: 5}}>
      <HistogramDialog
        open={openDialog}
        onClose={handleCloseDialog}
        handleChangeScale={handleChangeScale}
        logScaleX={logScaleX}
        logScaleY={logScaleY}
        logScaleZ={logScaleZ}
        selectedClass={selectedSubplot}
        setSelectedClass={setSelectedSubplot}
        selectedPlot={selectedPlot}
        subPlots={selectedPlot?.sub_plots}
        is2D={selectedPlot?.category.includes('2d_histogram')}
      />
      <Grid container alignItems={'center'} direction={'row'} wrap={'nowrap'}>
        <Grid item xs={'auto'}>
          <IconButton
            onClick={() => {
              setOpenDialog(true);
            }}
            color={'primary'}
            sx={{mr: 2, p: 0}}
          >
            <SettingsIcon fontSize={'small'} />
          </IconButton>
        </Grid>
        <Grid item xs={true} sx={{overflow: 'auto'}}>
          {plots ? (
            <Select
              fullWidth={true}
              value={selectedPlot}
              onChange={handleChangePlot}
              variant={'standard'}
              endAdornment={
                plotsLoading && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} color="primary" sx={{mr: 8}} />
                  </InputAdornment>
                )
              }
              sx={{width: '100%', height: 33, minHeight: 33, fontSize: 14}}
            >
              {plots?.map((plot: any) => (
                <MenuItem value={plot} key={plot.feature}>
                  {plot.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            data?.name
          )}
        </Grid>
      </Grid>
      <Grid container>
        {selectedPlot?.category === 'histograms__2d_histogram' ? (
          <SpatialMapGraph
            logScaleZ={logScaleZ}
            graphId={graphId}
            selectedPlot={selectedPlot}
            graphData={!histogram2dLoading && histogram2dData}
            graphDataLoading={histogram2dLoading}
            selectedSubplot={selectedSubplot}
          />
        ) : (
          <HistogramGraph
            logScaleX={logScaleX}
            logScaleY={logScaleY}
            graphData={!histogramLoading && histogramData}
            graphDataLoading={histogramLoading}
            selectedPlot={selectedPlot}
            selectedSubplot={selectedSubplot}
            graphId={graphId}
          />
        )}
      </Grid>
    </Paper>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (store: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      post1dHistogram,
      post2dHistogram,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HistogramStruct);
