import React, {FC} from 'react';
import {Box} from '@mui/material';

const TabPanel: FC<any> = (props) => {
  const {children, value, index, sx = {p: 5}, ...other} = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box sx={sx}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
