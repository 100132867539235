import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {updateBreadcrumbs} from 'redux/actions/breadcrumbsAction';
import {updateGlobalSetting} from 'redux/actions/globalSettingAction';
import {
  deleteUser,
  forgottenPassword,
  getUsers,
  patchUser,
  welcomeUser,
} from 'redux/actions/userManagementActions';
import {Trans} from '@lingui/macro';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/KeyOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import {Box, Button, Chip, Grid, IconButton} from '@mui/material';
import AdminTable from 'components/AdminTable';
import CustomTooltip from 'components/CustomTooltip';
import DialogWindow from 'components/DialogWindow';
import UserManagementForm from 'components/forms/UserManagementForm';
import {useWarningDialog} from 'hooks/useWarningDialog';
import {ResponseMetaType, TableOrderType} from 'types/general';
import {RoleType, UserType} from 'types/userType';
import PrivateBreadcrumbs from 'layouts/Private/PrivateBreadcrumbs';

type UserManagementPageProps = {
  getUsers: any;
  deleteUser: any;
  userListData: [];
  userListLoading: boolean;
  userListMeta: ResponseMetaType;
  rowsPerPageStore: number;
  rowsPerPageOptions: number[];
  updateGlobalSetting: any;
  welcomeUser: any;
  patchUser: any;
  updateBreadcrumbs: any;
  forgottenPassword: any;
};

const UserManagementPage: FC<UserManagementPageProps> = (props) => {
  const {
    getUsers,
    deleteUser,
    userListData,
    userListLoading,
    userListMeta,
    rowsPerPageStore,
    rowsPerPageOptions,
    updateGlobalSetting,
    welcomeUser,
    patchUser,
    updateBreadcrumbs,
    forgottenPassword,
  } = props;

  const [openDialog, setOpenDialog] = useState<Record<string, any>>({
    visible: false,
    row: null,
  });

  const {
    Dialog: DeleteWarningDialog,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
    handleSetLoading: handleSetLoadingDeleteDialog,
    dialogData: deleteDialogData,
  } = useWarningDialog();

  const {
    Dialog: DisableWarningDialog,
    handleOpenDialog: handleOpenDisableDialog,
    handleCloseDialog: handleCloseDisableDialog,
    handleSetLoading: handleSetLoadingDisableDialog,
    dialogData: disableDialogData,
  } = useWarningDialog();

  const {
    Dialog: WelcomeWarningDialog,
    handleOpenDialog: handleOpenWelcomeDialog,
    handleCloseDialog: handleCloseWelcomeDialog,
    handleSetLoading: handleSetLoadingWelcomeDialog,
    dialogData: welcomeDialogData,
  } = useWarningDialog();

  const {
    Dialog: ResetWarningDialog,
    handleOpenDialog: handleOpenResetDialog,
    handleCloseDialog: handleCloseResetDialog,
    handleSetLoading: handleSetLoadingResetDialog,
    dialogData: resetDialogData,
  } = useWarningDialog();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageStore);
  const [tableOrder, setTableOrder] = useState({orderBy: 'id', orderDirection: 'DESC'});

  const onColumnOrder = (order: TableOrderType) => {
    setTableOrder(order);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    updateGlobalSetting({rowsPerPage: parseInt(event.target.value, 10)});
  };

  const handleOpenDialog = (row: UserType | null) => () => {
    setOpenDialog({visible: true, row: row});
  };

  const handleCloseDialog = () => {
    setOpenDialog({visible: false, row: null});
  };

  const handleDeleteUser = () => {
    handleSetLoadingDeleteDialog(true);
    return deleteUser(deleteDialogData?.id).then(() => {
      handleCloseDeleteDialog();
      handleFetchUsers();
    });
  };

  const handleSendWelcomeEmail = () => {
    handleSetLoadingWelcomeDialog(true);
    return welcomeUser(welcomeDialogData?.id).then(() => {
      handleCloseWelcomeDialog();
      handleFetchUsers();
    });
  };

  const handleToggleUser = () => {
    handleSetLoadingDisableDialog(true);
    return patchUser(disableDialogData?.id, {
      is_active: !disableDialogData?.is_active,
    }).then(() => {
      handleCloseDisableDialog();
      handleFetchUsers();
    });
  };

  const handleFetchUsers = () => {
    getUsers(rowsPerPage, page * rowsPerPage, tableOrder);
  };

  const handleSendResetPasswordEmail = () => {
    handleSetLoadingResetDialog(true);
    return forgottenPassword(resetDialogData?.id).then(() => {
      handleCloseResetDialog();
    });
  };

  const userManagementTableColumns = [
    {
      name: 'id',
      orderKey: 'id',
      label: <Trans>Id</Trans>,
    },
    {
      name: 'email',
      orderKey: 'email',
      label: <Trans>E-mail</Trans>,
    },
    {
      name: 'name',
      orderKey: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'roles',
      label: <Trans>Roles</Trans>,
      render: (val: RoleType[]) => (
        <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: 1}}>
          {val.map((role: RoleType, index: number) => (
            <Chip
              sx={{
                fontSize: 12,
                backgroundColor: (theme) => theme.palette.primary.main + 'dd',
                color: (theme) => theme.palette.common.white,
              }}
              size={'small'}
              key={index}
              label={role?.name}
            />
          ))}
        </Box>
      ),
    },
    {
      name: 'actions',
      render: (value: any, row: UserType) => {
        return (
          <Grid container justifyContent={'flex-end'} flexWrap={'nowrap'}>
            <Grid item sx={{pr: 5}}>
              <CustomTooltip title={<Trans>Edit user</Trans>}>
                <IconButton color={'info'} sx={{p: 0}} onClick={handleOpenDialog(row)}>
                  <EditIcon fontSize={'small'} />
                </IconButton>
              </CustomTooltip>
            </Grid>
            <Grid item sx={{pr: 5}}>
              <CustomTooltip
                title={
                  row.welcomed_at ? (
                    <Trans>Send welcome e-mail again</Trans>
                  ) : (
                    <Trans>Send welcome e-mail</Trans>
                  )
                }
              >
                <IconButton
                  color={row.welcomed_at ? 'success' : 'warning'}
                  sx={{p: 0}}
                  onClick={() => handleOpenWelcomeDialog(row)}
                >
                  {row.welcomed_at ? (
                    <MarkEmailReadOutlinedIcon fontSize={'small'} />
                  ) : (
                    <MailOutlineIcon fontSize={'small'} />
                  )}
                </IconButton>
              </CustomTooltip>
            </Grid>
            <Grid item sx={{pr: 5}}>
              <CustomTooltip title={<Trans>Send reset password e-mail</Trans>}>
                <IconButton
                  color={'warning'}
                  sx={{p: 0}}
                  onClick={() => handleOpenResetDialog(row)}
                >
                  <KeyIcon fontSize={'small'} />
                </IconButton>
              </CustomTooltip>
            </Grid>
            <Grid item sx={{pr: 5}}>
              <CustomTooltip
                title={row.is_active ? <Trans>Disable user</Trans> : <Trans>Activate user</Trans>}
              >
                <IconButton
                  color={'warning'}
                  sx={{p: 0}}
                  onClick={() => handleOpenDisableDialog(row)}
                >
                  {row.is_active ? (
                    <LockOutlinedIcon fontSize={'small'} />
                  ) : (
                    <LockOpenOutlinedIcon fontSize={'small'} />
                  )}
                </IconButton>
              </CustomTooltip>
            </Grid>
            <Grid item>
              <CustomTooltip title={<Trans>Delete user</Trans>}>
                <IconButton color={'error'} sx={{p: 0}} onClick={() => handleOpenDeleteDialog(row)}>
                  <DeleteOutlineIcon fontSize={'small'} />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];
  useEffect(() => {
    handleFetchUsers();
    updateBreadcrumbs({'user-management': {}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, tableOrder]);

  return (
    <Grid container>
      <DeleteWarningDialog
        onOk={handleDeleteUser}
        message={<Trans>Delete user {deleteDialogData?.email}</Trans>}
      />

      <DisableWarningDialog
        onOk={handleToggleUser}
        message={<Trans>Disable user {disableDialogData?.email}</Trans>}
      />
      <WelcomeWarningDialog
        onOk={handleSendWelcomeEmail}
        message={<Trans>Welcome user {welcomeDialogData?.email}</Trans>}
      />
      <ResetWarningDialog
        onOk={handleSendResetPasswordEmail}
        message={<Trans>Send reset password e-mail for user {resetDialogData?.email}</Trans>}
      />
      <DialogWindow
        maxWidth={'sm'}
        fullWidth={true}
        title={openDialog.row ? <Trans>Edit user</Trans> : <Trans>Create new user</Trans>}
        open={openDialog.visible}
        onClose={handleCloseDialog}
      >
        <UserManagementForm
          handleFetchUsers={handleFetchUsers}
          onClose={handleCloseDialog}
          initData={openDialog.row}
        />
      </DialogWindow>
      <PrivateBreadcrumbs
        actions={
          <CustomTooltip title={<Trans>Create new user</Trans>}>
            <Button variant="contained" onClick={handleOpenDialog(null)} size={'small'}>
              <Trans>New</Trans>
            </Button>
          </CustomTooltip>
        }
      />
      <Grid container>
        <AdminTable
          data={userListData}
          columns={userManagementTableColumns}
          loading={userListLoading}
          pagination={true}
          page={page}
          count={userListMeta?.total_count}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onColumnOrder={onColumnOrder}
          tableOrder={tableOrder}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (store: any) => {
  return {
    userListData: store.users.listData,
    userListLoading: store.users.listLoading,
    userListMeta: store.users.listMeta,
    rowsPerPageStore: store.setting.rowsPerPage,
    rowsPerPageOptions: store.setting.rowsPerPageOptions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getUsers,
      patchUser,
      deleteUser,
      welcomeUser,
      updateGlobalSetting,
      updateBreadcrumbs,
      forgottenPassword,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementPage);
