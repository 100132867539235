import React, {FC, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {getCluster, getFrame} from 'redux/actions/datasetAction';
import {Grid} from '@mui/material';
import ClusterHeatmap from 'components/outputs/ClusterHeatmap';
import FrameHeatmap from 'components/outputs/FrameHeatmap';

const FrameViewerTab: FC<any> = (props) => {
  const {getFrame, getCluster} = props;

  const {id, pId} = useParams();

  useEffect(() => {
    // GET first frame and first cluster
    getFrame(id, pId, 1).then((res: any) => {
      if (res?.clusters?.[0]?.id) {
        getCluster(id, pId, res?.clusters?.[0]?.id);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <FrameHeatmap />
      </Grid>
      <Grid item xs={6}>
        <ClusterHeatmap />
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (store: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getFrame,
      getCluster,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FrameViewerTab);
