import React from 'react';
import {
  GET_USER_ROLES_FAIL,
  GET_USER_ROLES_REQUEST,
  GET_USER_ROLES_SUCCESS,
} from 'redux/actionTypes';
import {Trans} from '@lingui/macro';
import {LS_LOGGED_USER, LS_SESSION_ID_NAME} from 'constants/config';
import {RequestThunkAction} from 'types/general';
import {LoginType, UserType} from 'types/userType';
import endpoints from 'utils/endpoints';
import {fireSuccessToast, globalApiErrorHandler, setItemToStorage} from 'utils/functions';
import httpClient from 'utils/httpClient';

export const login = (data: LoginType): RequestThunkAction => {
  return () => {
    return httpClient
      .post(endpoints.login, data)
      .then((res) => {
        if (res.status === 200) {
          setItemToStorage(LS_SESSION_ID_NAME, res.headers['session-id']);
          setItemToStorage(LS_LOGGED_USER, res.data?.user);
        }
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const logout = (): RequestThunkAction => {
  return () => {
    return httpClient
      .del(endpoints.logout)
      .then((res) => {
        if (res.status === 204) {
          localStorage.removeItem(LS_SESSION_ID_NAME);
          localStorage.removeItem(LS_LOGGED_USER);
        }
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const forgottenPassword = (data: {email: string}): RequestThunkAction => {
  return () => {
    return httpClient
      .post(endpoints.forgottenPassword, data)
      .then((res) => {
        fireSuccessToast(<Trans>E-mail for password reset was sent.</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const setPassword = (data: {password: string}, token: string): RequestThunkAction => {
  return () => {
    return httpClient
      .post(`${endpoints.resetPassword}/${token}`, data)
      .then((res) => {
        if (res.status === 200) {
          setItemToStorage(LS_SESSION_ID_NAME, res.headers['session-id']);
          setItemToStorage(LS_LOGGED_USER, res.data?.user);
        }
        fireSuccessToast(<Trans>New password was set.</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const register = (data: UserType): RequestThunkAction => {
  return () => {
    return httpClient
      .post(endpoints.registration, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const changePassword = (
  id: number,
  // eslint-disable-next-line
  data: {new_password: 'string'; old_password: 'string'}
): RequestThunkAction => {
  return () => {
    return httpClient
      .patch(`${endpoints.users}/${id}/change-password`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const getRoles = (): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_USER_ROLES_REQUEST});
    return httpClient
      .get(endpoints.roles)
      .then((res) => {
        dispatch({type: GET_USER_ROLES_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_USER_ROLES_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const getCurrentUser = (): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .get(endpoints.currentUser)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};
