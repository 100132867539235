import React, {FC, useEffect, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {updateBreadcrumbs} from 'redux/actions/breadcrumbsAction';
import {patchDataset, postDataset} from 'redux/actions/datasetAction';
import {getTags, postTag} from 'redux/actions/tagAction';
import {Trans, t} from '@lingui/macro';
import SettingsIcon from '@mui/icons-material/Settings';
import {Autocomplete, Box, Chip, Grid, TextField, Typography} from '@mui/material';
import DatasetTagDialog from 'components/datasets/DatasetTagDialog';
import FormButton from 'components/formComponents/FormButton';
import CustomTextField from 'components/formComponents/TextField';
import {TAG_COLORS} from 'constants/enums';
import {CalibrationMatrixType} from 'types/datasetType';
import {ResponseMetaType} from 'types/general';
import {mapErrorsToForm, redirectTo} from 'utils/functions';
import i18nProvider from 'utils/i18n';

type NewDatasetFormProps = {
  updateBreadcrumbs: any;
  patchDataset: any;
  postDataset: any;
  setActiveStep: any;
  datasetUploadsData: any[];
  calibrationFiles: any[];
  detailData?: any;
  edit?: boolean;
  getTags: any;
  postTag: any;
  tagList: any[];
  tagMeta: ResponseMetaType;
  tagLoading: boolean;
  datasetBasicInfo?: any;
  setDatasetBasicInfo?: any;
  detailLoading: boolean;
};

const DatasetBasicInfoForm: FC<NewDatasetFormProps> = (props) => {
  const {
    detailData,
    updateBreadcrumbs,
    postDataset,
    patchDataset,
    setActiveStep,
    datasetUploadsData,
    calibrationFiles,
    edit = false,
    getTags,
    tagList,
    tagLoading,
    datasetBasicInfo,
    setDatasetBasicInfo,
    detailLoading,
  } = props;

  const [visibleTagDialog, setVisibleTagDialog] = useState(false);
  const [tagValue, setTagValue] = useState<any>([]);

  const handleChangeTag = (e: any, value: any) => {
    if (Array.isArray(value)) {
      setTagValue(value);
    }
  };

  const handleChangeField = (e: any, name: string) => {
    setDatasetBasicInfo((prevState: any) => ({...prevState, [name]: e}));
  };

  const handleCreateDataset = (values: any) => {
    const formData = new FormData();

    values.name && formData.append('name', values.name);
    values.description && formData.append('description', values.description);

    tagValue.forEach((tag: any) => formData.append('tag_ids', tag.id));

    if (!edit) {
      datasetUploadsData?.forEach((dataset: any, index: number) => {
        formData.append(`dataset_upload_ids[${index}]`, dataset.id);
      });

      calibrationFiles?.forEach((file: CalibrationMatrixType, index: number) => {
        formData.append(`calibration_matrices[${index}]file`, file.data);
        formData.append(`calibration_matrices[${index}]type`, file.type);
      });
    }

    if (detailData?.id) {
      return patchDataset(detailData.id, formData)
        .then(() => {
          redirectTo(`/dataset/${detailData.id}`);
        })
        .catch((err: any) => {
          return mapErrorsToForm(err);
        });
    } else {
      return postDataset(formData)
        .then(() => {
          redirectTo('/dataset');
        })
        .catch((err: any) => {
          return mapErrorsToForm(err);
        });
    }
  };

  useEffect(() => {
    getTags();
    updateBreadcrumbs({dataset: {}});
    setTagValue(detailData?.tags || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const required = (value: string) => (value ? undefined : <Trans>Field is required</Trans>);

  return (
    <Form
      onSubmit={handleCreateDataset}
      initialValues={{
        description: datasetBasicInfo?.description,
        name: datasetBasicInfo?.name,
      }}
      render={({handleSubmit, submitting}) => (
        <form onSubmit={handleSubmit} style={{width: '100%'}}>
          <Grid container alignItems={'center'} justifyContent={'flex-start'} spacing={5}>
            <Grid container item xs={12} sm={6}>
              <Grid item xs={12}>
                <Typography variant={'h6'}>
                  <Trans>Basic dataset info</Trans>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Field
                  validate={required}
                  name={'name'}
                  render={(props) => (
                    <CustomTextField
                      onCustomChange={handleChangeField}
                      label={<Trans>Dataset name</Trans>}
                      {...props}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  validate={required}
                  name={'description'}
                  render={(props) => (
                    <CustomTextField
                      onCustomChange={handleChangeField}
                      multiline={true}
                      label={<Trans>Description</Trans>}
                      {...props}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} sm={6} alignSelf={'flex-start'}>
              <Grid item container xs={12} alignContent={'center'} sx={{mb: 4}}>
                <Typography variant={'h6'} component={'span'} sx={{pr: 2}}>
                  <Trans>Tags</Trans>
                </Typography>
                <SettingsIcon
                  sx={{cursor: 'pointer'}}
                  fontSize={'small'}
                  color={'primary'}
                  onClick={() => setVisibleTagDialog(true)}
                />
                <DatasetTagDialog
                  open={visibleTagDialog}
                  onClose={() => setVisibleTagDialog(false)}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  value={tagValue}
                  onChange={handleChangeTag}
                  onInputChange={handleChangeTag}
                  loading={tagLoading || detailLoading}
                  sx={{width: 'calc(100% - 2px)', fontSize: 14}}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.id === value.id;
                  }}
                  autoComplete={true}
                  multiple
                  size={'small'}
                  disableClearable={true}
                  disableCloseOnSelect={true}
                  options={tagList}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value: any[], getTagProps) => {
                    return value.map((option: any, index: number) => (
                      <Chip
                        // @ts-ignore
                        key={index}
                        size={'small'}
                        variant="filled"
                        label={option?.name}
                        style={{
                          color: '#ffffff',
                          fontSize: 12,
                          border:
                            '2px solid ' + TAG_COLORS.find((c) => c.value === option.color)?.color,
                          backgroundColor:
                            TAG_COLORS.find((c) => c.value === option.color)?.color + 'dd',
                        }}
                        {...getTagProps({index})}
                      />
                    ));
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <Grid container alignItems={'center'}>
                          <Box
                            sx={{
                              mr: 2,
                              width: 12,
                              height: 12,
                              backgroundColor:
                                TAG_COLORS.find((c) => c.value === option.color)?.color + 'dd',
                              border: `2px solid ${
                                TAG_COLORS.find((c) => c.value === option.color)?.color
                              }`,
                            }}
                          />
                          <Typography variant={'body2'}>{option.name}</Typography>
                        </Grid>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        '& .MuiInputLabel-root': {
                          fontSize: 14,
                          lineHeight: 1.9,
                        },
                      }}
                      InputProps={{sx: {minHeight: 33, fontSize: 14}, ...params.InputProps}}
                      variant="standard"
                      placeholder={i18nProvider._(t`Select tags`)}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid sx={{mt: 5, pb: 5}} container item xs={12} justifyContent={'space-between'}>
              <FormButton
                variant={'text'}
                disabled={submitting}
                sx={{px: 4}}
                text={<Trans>Back</Trans>}
                onClick={() => setActiveStep(1)}
              />
              <FormButton
                disabled={submitting}
                sx={{px: 4}}
                text={detailData?.id ? <Trans>Save</Trans> : <Trans>Create</Trans>}
                type={'submit'}
              />
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      updateBreadcrumbs,
      postDataset,
      patchDataset,
      getTags,
      postTag,
    },
    dispatch
  );
};

const mapStateToProps = (store: any) => {
  return {
    tagList: store.tags.tagList,
    tagMeta: store.tags.tagMeta,
    tagLoading: store.tags.tagLoading,
    detailLoading: store.datasets.detailLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetBasicInfoForm);
