import React from 'react';
import {Trans} from '@lingui/macro';

export const ADDITIONAL_PARAMS_TYPES = [
  {
    value: 'masking',
    label: <Trans>Masking</Trans>,
  },
  {
    value: 'masking_n',
    label: <Trans>Mask for significant vector</Trans>,
  },
  {
    value: 'filtering',
    label: <Trans>Filtering</Trans>,
  },
  {
    value: 'histograms',
    label: <Trans>Histograms</Trans>,
  },
  {
    value: 'significant_vectors',
    label: <Trans>Significant vectors</Trans>,
  },
  {
    value: 'compton_camera',
    label: <Trans>Compton camera</Trans>,
  },
];

export const CALIBRATION_MATRIX_TYPES = [
  {
    value: 'a',
    label: <Trans>a</Trans>,
  },
  {
    value: 'b',
    label: <Trans>b</Trans>,
  },
  {
    value: 'c',
    label: <Trans>c</Trans>,
  },
  {
    value: 't',
    label: <Trans>t</Trans>,
  },
];

export const GRAPH_COLORS = [
  {
    value: 'total',
    label: <Trans>All particles</Trans>,
    color: '#1f77b4',
  },
  {
    value: '1',
    label: <Trans>Class 1</Trans>,
    color: '#ff7f0e',
  },
  {
    value: '2',
    label: <Trans>Class 2</Trans>,
    color: '#2ca02c',
  },
  {
    value: '3',
    label: <Trans>Class 3</Trans>,
    color: '#d62728',
  },
  {
    value: '4',
    label: <Trans>Class 4</Trans>,
    color: '#9467bd',
  },
  {
    value: '5',
    label: <Trans>Class 5</Trans>,
    color: '#8c564b',
  },
  {
    value: '6',
    label: <Trans>Class 6</Trans>,
    color: '#e377c2',
  },
  {
    value: '7',
    label: <Trans>Class 7</Trans>,
    color: '#7f7f7f',
  },
  {
    value: '8',
    label: <Trans>Class 8</Trans>,
    color: '#bcbd22',
  },
  {
    value: 'others',
    label: <Trans>Other particles</Trans>,
    color: '#17becf',
  },
];

export const TAG_COLORS = [
  {
    value: 'BLUE',
    label: <Trans>Blue</Trans>,
    color: '#1f77b4',
  },
  {
    value: 'RED',
    label: <Trans>Red</Trans>,
    color: '#d62728',
  },
  {
    value: 'ORANGE',
    label: <Trans>Orange</Trans>,
    color: '#ff7f0e',
  },
  {
    value: 'GREEN',
    label: <Trans>Green</Trans>,
    color: '#2ca02c',
  },
  {
    value: 'GREY',
    label: <Trans>Grey</Trans>,
    color: '#7f7f7f',
  },
  {
    value: 'YELLOW',
    label: <Trans>Yellow</Trans>,
    color: '#C8C91A',
  },
];
