import React from 'react';
import {GET_USERS_FAIL, GET_USERS_REQUEST, GET_USERS_SUCCESS} from 'redux/actionTypes';
import {Trans} from '@lingui/macro';
import {RequestThunkAction} from 'types/general';
import {UserType} from 'types/userType';
import endpoints from 'utils/endpoints';
import {fireSuccessToast, globalApiErrorHandler} from 'utils/functions';
import httpClient from 'utils/httpClient';

export const getUsers = (limit: number, offset: number, meta: any): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_USERS_REQUEST});
    return httpClient
      .get(endpoints.users, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_USERS_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_USERS_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const postUser = (data: UserType): RequestThunkAction => {
  return () => {
    return httpClient
      .post(endpoints.users, data)
      .then((res) => {
        fireSuccessToast(<Trans>New user created</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const patchUser = (id: number, data: UserType): RequestThunkAction => {
  return () => {
    return httpClient
      .patch(`${endpoints.users}/${id}`, data)
      .then((res) => {
        fireSuccessToast(<Trans>User updated</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const deleteUser = (id: number): RequestThunkAction => {
  return () => {
    return httpClient
      .del(`${endpoints.users}/${id}`)
      .then((res) => {
        fireSuccessToast(<Trans>User deleted</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const welcomeUser = (id: number): RequestThunkAction => {
  return () => {
    return httpClient
      .post(`${endpoints.users}/${id}/welcome`)
      .then((res) => {
        fireSuccessToast(<Trans>Welcome e-mail was sent</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const forgottenPassword = (id: number): RequestThunkAction => {
  return () => {
    return httpClient
      .post(`${endpoints.users}/${id}/forgotten-password`)
      .then((res) => {
        fireSuccessToast(<Trans>E-mail for reset password was sent</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};
