// @ts-ignore
import Plotly from 'plotly.js-dist-min';
import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {
  getTimePlotDepositedEnergy,
  getTimePlotDoseRate,
  getTimePlotParticleFlux,
} from 'redux/actions/datasetAction';
import {Box, Grid} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import DownloadButtons from 'components/outputs/DownloadButtons';
import TimePlotSettingDialog from 'components/outputs/TimePlotSettingDialog';
import {GRAPH_COLORS} from 'constants/enums';

const LinearGraph: FC<any> = (props) => {
  const {
    getTimePlotParticleFlux,
    getTimePlotDepositedEnergy,
    particleClassesFilters,
    getTimePlotDoseRate,
    yAxisTitle,
    type,
    logScale,
    setLogScale,
    openDialog,
    setOpenDialog,
  } = props;

  const [timePlotData, setTimePlotData] = useState([]);
  const [graphDataLoading, setGraphDataLoading] = useState<any>({});

  const {id, pId} = useParams();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeScale = () => {
    const newLayout = {
      xaxis: {
        title: {
          text: 'Time [s]',
        },
      },
      yaxis: {
        type: logScale ? 'linear' : 'log',
        title: {
          text: yAxisTitle,
        },
      },
    };

    setLogScale((prevState: any) => !prevState);
    Plotly.relayout(type, {...plotLayout, ...newLayout});
  };

  const handleDownload = (format = 'png') => {
    const options = {
      format: format,
      width: 1280,
      height: 960,
      filename: yAxisTitle,
    };

    Plotly.downloadImage(type, options);
  };

  const setDataToState = (data: any) => {
    const finalData: any = [];

    if (data.length === 1) {
      data?.map((record: any) => {
        Object.entries(record)?.forEach(([key, value]) => {
          finalData.push({
            mode: 'markers',
            type: 'scatter',
            meta: {key: key},
            x: [record.time],
            y: [value],
            name: particleClassesFilters.find((cls: any) => String(cls.class_num) === String(key))
              ?.class_name,
          });
        });
      });
    } else {
      data?.map((record: any) => {
        Object.entries(record)?.forEach(([key, value], index: number) => {
          if (finalData?.[index]?.mode) {
            finalData?.[index]?.y?.push(value);
            finalData?.[index]?.x?.push(record.time);
          } else {
            finalData.push({
              mode: 'lines',
              type: 'scatter',
              meta: {key: key},
              line: {
                color: GRAPH_COLORS.find((col: any) => String(col.value) === String(key))?.color,
              },
              x: [record.time],
              y: [value],
              name: particleClassesFilters.find((cls: any) => String(cls.class_num) === String(key))
                ?.class_name,
            });
          }
        });
      });
    }

    finalData && setTimePlotData(finalData.filter((data: any) => !!data.name));
  };

  const plotLayout = {
    margin: {
      l: 60,
      r: 20,
      t: 80,
      b: 60,
    },
    xaxis: {
      title: {
        text: 'Time [s]',
      },
    },
    yaxis: {
      type: logScale ? 'log' : 'linear',
      title: {
        text: yAxisTitle,
      },
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      yanchor: 'right',
      xanchor: 'top',
      y: 6,
    },
  };

  useEffect(() => {
    if (type === 'flux') {
      setGraphDataLoading(true);
      getTimePlotParticleFlux(id, pId, {
        particle_classes: particleClassesFilters
          .filter((particleClass: any) => particleClass.visible)
          .map((particleClass: any) => particleClass.class_num),
      })
        .then((res: any) => setDataToState(res))
        .finally(() => setGraphDataLoading(false));
    } else if (type === 'energy') {
      setGraphDataLoading(true);
      getTimePlotDepositedEnergy(id, pId, {
        particle_classes: particleClassesFilters
          .filter((particleClass: any) => particleClass.visible)
          .map((particleClass: any) => particleClass.class_num),
      })
        .then((res: any) => setDataToState(res))
        .finally(() => setGraphDataLoading(false));
    } else if (type === 'dose') {
      setGraphDataLoading(true);
      getTimePlotDoseRate(id, pId, {
        particle_classes: particleClassesFilters
          .filter((particleClass: any) => particleClass.visible)
          .map((particleClass: any) => particleClass.class_num),
      })
        .then((res: any) => setDataToState(res))
        .finally(() => setGraphDataLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [particleClassesFilters, type]);

  useEffect(() => {
    Plotly.newPlot(type, timePlotData, plotLayout, {displaylogo: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (document.getElementById(type)) {
      if (timePlotData.length) {
        Plotly.react(type, timePlotData, plotLayout);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePlotData, yAxisTitle]);

  return (
    <Grid container>
      <TimePlotSettingDialog
        open={openDialog}
        onClose={handleCloseDialog}
        handleChangeScale={handleChangeScale}
        logScale={logScale}
      />
      <Grid container item xs={12} justifyContent={'center'} sx={{position: 'relative'}}>
        <LoadingMask
          loading={graphDataLoading}
          containerStyle={{display: 'flex', justifyContent: 'center', width: '100%'}}
        >
          <Box
            sx={{
              maxWidth: 'calc(100% - 16px)',
              width: '100%',
              height: 440,
              boxSizing: 'border-box',
            }}
            id={type}
          />
        </LoadingMask>
      </Grid>

      <DownloadButtons handleDownload={handleDownload} />
    </Grid>
  );
};

const mapStateToProps = (store: any) => {
  return {
    particleClassesFilters: store.datasets.particleClassesFilters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTimePlotParticleFlux,
      getTimePlotDepositedEnergy,
      getTimePlotDoseRate,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearGraph);
