import React, {FC, ReactElement} from 'react';
import {Tooltip} from '@mui/material';

type CustomTooltipProp = {
  title: ReactElement;
  children: ReactElement;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
};

const CustomTooltip: FC<CustomTooltipProp> = (props) => {
  const {title, children, placement = 'top'} = props;

  return (
    <Tooltip title={title} placement={placement}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
