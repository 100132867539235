import React, {FC, useState} from 'react';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {Trans, t} from '@lingui/macro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import AdminTable from 'components/AdminTable';
import LoadingMask from 'components/LoadingMask';
import TabPanel from 'components/TabPanel';
import LinearGraph from 'components/outputs/LinearGraph';
import {
  depositColumns,
  doseColumns,
  fluxColumns,
  physicalProductsColumns,
} from 'components/outputs/tableOutputColumns';
import i18nProvider from 'utils/i18n';

type TableOutputAndTimePlotsProps = {
  samplingList: Record<string, any>;
  samplingListLoading: boolean;
};

const TableOutputAndTimePlots: FC<TableOutputAndTimePlotsProps> = (props) => {
  const {samplingList, samplingListLoading} = props;

  const [tabValue, setTabValue] = useState(0);
  const [logScale, setLogScale] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Grid container sx={{position: 'relative'}}>
      <LoadingMask loading={samplingListLoading}>
        <Grid container>
          <Grid item xs={12}>
            <Accordion
              sx={{width: '100%'}}
              elevation={2}
              disableGutters={true}
              defaultExpanded={true}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant={'h6'}>
                  <Trans>Physical products for used classes</Trans>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AdminTable
                  data={samplingList?.physical_products_for_used_classes}
                  columns={physicalProductsColumns}
                  loading={false}
                  pagination={false}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{width: '100%', mt: 5}}>
              <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabValue} onChange={handleChange}>
                  <Tab sx={{textTransform: 'none'}} label={<Trans>Flux [#/cm²/s]</Trans>} />
                  <Tab sx={{textTransform: 'none'}} label={<Trans>Deposited energy [keV]</Trans>} />
                  <Tab sx={{textTransform: 'none'}} label={<Trans>Dose rate [μGy/h]</Trans>} />
                </Tabs>
              </Box>

              <TabPanel value={tabValue} index={0} sx={{px: 0, py: 5}}>
                <Accordion
                  sx={{width: '100%', mb: 5}}
                  elevation={2}
                  disableGutters={true}
                  defaultExpanded={true}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <IconButton
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpenDialog(true);
                      }}
                      color={'primary'}
                      sx={{mr: 2, p: 0}}
                    >
                      <SettingsIcon fontSize={'small'} />
                    </IconButton>
                    <Typography variant={'h6'}>
                      <Trans>Time plot</Trans>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <LinearGraph
                      openDialog={openDialog}
                      setOpenDialog={setOpenDialog}
                      type={'flux'}
                      yAxisTitle={i18nProvider._(t`Flux [#/cm²/s]`)}
                      logScale={logScale}
                      setLogScale={setLogScale}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{width: '100%'}}
                  elevation={2}
                  disableGutters={true}
                  defaultExpanded={true}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant={'h6'}>
                      <Trans>Table output</Trans>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AdminTable
                      data={samplingList?.particles_flux}
                      columns={fluxColumns(samplingList)}
                      loading={false}
                      pagination={false}
                    />
                  </AccordionDetails>
                </Accordion>
              </TabPanel>

              <TabPanel value={tabValue} index={1} sx={{px: 0, py: 5}}>
                <Accordion
                  sx={{width: '100%', mb: 5}}
                  elevation={1}
                  disableGutters={true}
                  defaultExpanded={true}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <IconButton
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpenDialog(true);
                      }}
                      color={'primary'}
                      sx={{mr: 2, p: 0}}
                    >
                      <SettingsIcon fontSize={'small'} />
                    </IconButton>
                    <Typography variant={'h6'}>
                      <Trans>Time plot</Trans>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <LinearGraph
                      openDialog={openDialog}
                      setOpenDialog={setOpenDialog}
                      type={'energy'}
                      yAxisTitle={i18nProvider._(t`Deposited energy [keV]`)}
                      logScale={logScale}
                      setLogScale={setLogScale}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{width: '100%'}}
                  elevation={2}
                  disableGutters={true}
                  defaultExpanded={true}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant={'h6'}>
                      <Trans>Table output</Trans>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AdminTable
                      data={samplingList?.deposited_energy}
                      columns={depositColumns(samplingList)}
                      loading={false}
                      pagination={false}
                    />
                  </AccordionDetails>
                </Accordion>
              </TabPanel>

              <TabPanel value={tabValue} index={2} sx={{px: 0, py: 5}}>
                <Accordion
                  sx={{width: '100%', mb: 5}}
                  elevation={2}
                  disableGutters={true}
                  defaultExpanded={true}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <IconButton
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpenDialog(true);
                      }}
                      color={'primary'}
                      sx={{mr: 2, p: 0}}
                    >
                      <SettingsIcon fontSize={'small'} />
                    </IconButton>
                    <Typography variant={'h6'}>
                      <Trans>Time plot</Trans>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <LinearGraph
                      openDialog={openDialog}
                      setOpenDialog={setOpenDialog}
                      type={'dose'}
                      yAxisTitle={i18nProvider._(t`Dose rate [μGy/h]`)}
                      logScale={logScale}
                      setLogScale={setLogScale}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  sx={{width: '100%'}}
                  elevation={2}
                  disableGutters={true}
                  defaultExpanded={true}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant={'h6'}>
                      <Trans>Table output</Trans>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AdminTable
                      data={samplingList?.dose_rate}
                      columns={doseColumns(samplingList)}
                      loading={false}
                      pagination={false}
                    />
                  </AccordionDetails>
                </Accordion>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </LoadingMask>
    </Grid>
  );
};

const mapStateToProps = (store: any) => {
  return {
    samplingList: store.datasets.samplingList,
    samplingListLoading: store.datasets.samplingListLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TableOutputAndTimePlots);
