import React, {useState} from 'react';
import {FC} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {login} from 'redux/actions/authAction';
import {Trans} from '@lingui/macro';
import {Grid} from '@mui/material';
import DialogWindow from 'components/DialogWindow';
import HoverTypography from 'components/HoverTypography';
import FormButton from 'components/formComponents/FormButton';
import TextField from 'components/formComponents/TextField';
import ForgottenPasswordForm from 'components/forms/ForgottenPasswordForm';
import {LoginType} from 'types/userType';
import {mapErrorsToForm, redirectTo} from 'utils/functions';

type LoginFormProps = {
  login: (data: LoginType) => any;
};

const LoginForm: FC<LoginFormProps> = (props) => {
  const {login} = props;

  const [dialogVisible, setDialogVisible] = useState(false);

  const openDialog = () => {
    setDialogVisible(true);
  };
  const closeDialog = () => {
    setDialogVisible(false);
  };

  const redirectToRegistration = () => {
    redirectTo('/registration');
  };

  const handleLogin = (values: LoginType) => {
    return login(values)
      .then(() => {
        redirectTo('/home');
      })
      .catch((err: any) => {
        return mapErrorsToForm(err);
      });
  };

  return (
    <>
      <DialogWindow
        title={<Trans>Send e-mail for password reset</Trans>}
        open={dialogVisible}
        onClose={closeDialog}
      >
        <ForgottenPasswordForm onClose={closeDialog} />
      </DialogWindow>

      <Form
        onSubmit={handleLogin}
        render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <Grid container alignItems={'center'} justifyContent={'flex-start'}>
              <Grid item xs={12}>
                <Field
                  name={'email'}
                  render={(props) => <TextField label={<Trans>E-mail</Trans>} {...props} />}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={'password'}
                  render={(props) => (
                    <TextField type={'password'} label={<Trans>Password</Trans>} {...props} />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <HoverTypography
                  variant={'body2'}
                  onClick={openDialog}
                  text={<Trans>Forgotten password?</Trans>}
                />
              </Grid>

              <Grid
                sx={{mt: 6}}
                item
                xs={12}
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <HoverTypography
                  variant={'body2'}
                  text={<Trans>Register</Trans>}
                  onClick={redirectToRegistration}
                />

                <FormButton
                  disabled={submitting}
                  sx={{px: 4}}
                  text={<Trans>Login</Trans>}
                  type={'submit'}
                  size={'small'}
                />
              </Grid>
            </Grid>
          </form>
        )}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({login}, dispatch);
};

export default connect(null, mapDispatchToProps)(LoginForm);
