// @ts-ignore
import Plotly from 'plotly.js-dist-min';
import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {post2dHistogram} from 'redux/actions/datasetAction';
import {getBitmaps} from 'redux/actions/outputAction';
import {Trans} from '@lingui/macro';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import DownloadButtons from 'components/outputs/DownloadButtons';
import HistogramDialog from 'components/outputs/HistogramDialog';
import {getHistogramData, getPlotLayout} from 'components/outputs/SpatialMap/helpers';

const SpatialMap: FC<any> = (props) => {
  const {
    post2dHistogram,
    plots,
    graphId,
    isBitmap = false,
    getBitmaps,
    plotIndex = 0,
    plotsLoading = false,
  } = props;

  const {id, pId} = useParams();

  const [logScaleZ, setLogScaleZ] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [graphData, setGraphData] = useState<any>({});
  const [graphDataLoading, setGraphDataLoading] = useState<boolean>(false);

  const [selectedPlot, setSelectedPlot] = useState<{
    category: string;
    feature: string;
    sub_plots: any;
    name?: string;
  }>({category: '', feature: '', sub_plots: null});

  const [selectedSubplot, setSelectedSubplot] = useState<string | null>(null);

  const handleChangePlot = (e: any) => {
    setSelectedPlot(e.target.value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDownload = (format = 'png') => {
    const options = {
      format: format,
      width: 1280,
      height: 960,
      filename: `${selectedPlot?.feature} - ${selectedSubplot}`,
    };

    Plotly.downloadImage(graphId, options);
  };

  const handleChangeScale = () => {
    if (graphData) {
      setLogScaleZ((prevState: boolean) => !prevState);
      Plotly.react(
        graphId,
        [getHistogramData(graphData, !logScaleZ)],
        getPlotLayout(graphId, graphData, !logScaleZ)
      );
    }
  };

  useEffect(() => {
    if (graphData) {
      Plotly.react(
        graphId,
        [getHistogramData(graphData, logScaleZ)],
        getPlotLayout(graphId, graphData, logScaleZ)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, graphId]);

  useEffect(() => {
    Plotly.newPlot(graphId, [], getPlotLayout(graphId), {displaylogo: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPlot?.category && selectedPlot?.feature) {
      if (isBitmap) {
        setGraphDataLoading(true);
        getBitmaps(id, pId, selectedPlot.category, selectedPlot.feature, selectedSubplot)
          .then((res: any) => {
            setGraphData(res);
          })
          .finally(() => setGraphDataLoading(false));
      } else {
        setGraphDataLoading(true);
        post2dHistogram(id, pId, selectedPlot.category, selectedPlot.feature, selectedSubplot)
          .then((res: any) => {
            setGraphData(res);
          })
          .finally(() => setGraphDataLoading(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlot, selectedSubplot]);

  const setInitSubplot = (subPlots: Record<string, any>) => {
    if (subPlots) {
      if (subPlots['Total']) {
        setSelectedSubplot('Total');
      } else {
        setSelectedSubplot(Object.keys(subPlots)?.[0]);
      }
    }
  };

  useEffect(() => {
    if (plots?.[plotIndex]) {
      setSelectedPlot(plots[plotIndex]);
      setInitSubplot(plots[plotIndex].sub_plots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plots]);

  return (
    <Paper elevation={2} sx={{width: '100%', padding: 2, mb: 5}}>
      <HistogramDialog
        open={openDialog}
        onClose={handleCloseDialog}
        handleChangeScale={handleChangeScale}
        selectedClass={selectedSubplot}
        setSelectedClass={setSelectedSubplot}
        logScaleZ={logScaleZ}
        subPlots={selectedPlot?.sub_plots}
        selectedPlot={selectedPlot}
        is2D={true}
      />

      <Grid container alignItems={'center'} direction={'row'} wrap={'nowrap'}>
        <Grid item xs={'auto'}>
          <IconButton
            onClick={() => {
              setOpenDialog(true);
            }}
            color={'primary'}
            sx={{mr: 2, p: 0}}
          >
            <SettingsIcon fontSize={'small'} />
          </IconButton>
        </Grid>
        <Grid item xs={true} sx={{overflow: 'auto'}}>
          {plots?.length > 1 ? (
            <Select
              fullWidth={true}
              value={selectedPlot}
              onChange={handleChangePlot}
              variant={'standard'}
              endAdornment={
                plotsLoading && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} color="primary" sx={{mr: 8}} />
                  </InputAdornment>
                )
              }
              sx={{width: '100%', height: 33, minHeight: 33, fontSize: 14}}
            >
              {plots?.map((plot: any) => (
                <MenuItem value={plot} key={plot.feature}>
                  {plot.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            plots?.[0]?.name || <Trans>Spatial map</Trans>
          )}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{position: 'relative'}}>
          <LoadingMask
            loading={graphDataLoading}
            containerStyle={{display: 'flex', justifyContent: 'center'}}
          >
            <Box id={graphId} sx={{width: '100%', boxSizing: 'border-box'}} />
          </LoadingMask>
        </Grid>
      </Grid>
      <DownloadButtons handleDownload={handleDownload} />
    </Paper>
  );
};

const mapStateToProps = (store: any) => {
  return {
    histogram2dFeatures: store.datasets.histogram2dFeatures,
    histogram2dFeaturesLoading: store.datasets.histogram2dFeaturesLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      post2dHistogram,
      getBitmaps,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SpatialMap);
