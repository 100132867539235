import React, {FC, ReactElement, useId} from 'react';
import {FieldRenderProps} from 'react-final-form';
import {FormControl, FormHelperText, Input, InputLabel} from '@mui/material';

interface TextFieldProps extends FieldRenderProps<string> {
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  label: ReactElement | string;
  type?: string;
  autoComplete?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  onCustomChange?: any;
}

const TextField: FC<TextFieldProps> = (props) => {
  const {
    variant = 'standard',
    input,
    meta,
    label,
    type,
    autoComplete = 'off',
    disabled,
    multiline,
    helperText,
    onCustomChange,
  } = props;

  const id = useId();

  const hasError = meta.touched && ((meta.submitError && meta.submitFailed) || !!meta.error);
  const error = meta.submitError || meta.error;

  return (
    <FormControl disabled={meta.submitting} error={hasError} variant={variant} fullWidth={true}>
      <InputLabel sx={{fontSize: 14}} htmlFor={id}>
        {label}
      </InputLabel>
      <Input
        multiline={multiline}
        disabled={disabled}
        id={id}
        type={type}
        {...input}
        autoComplete={autoComplete}
        onChange={(e) => {
          input?.onChange(e);
          onCustomChange && onCustomChange(e.target.value, input?.name);
        }}
        sx={multiline ? {minHeight: 33, fontSize: 14} : {height: 33, minHeight: 33, fontSize: 14}}
      />
      <FormHelperText sx={{minHeight: 24, fontSize: 11}}>
        {(hasError && error) || helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default TextField;
