import React, {FC} from 'react';
import Loading from 'components/Loading';
import DownloadsTree from 'pages/Private/Donwloads/components/DownloadsTree';
import {useDownloadsQuery} from 'pages/Private/Donwloads/services/downloads';

const DownloadsPage: FC = () => {
  const {data, isLoading} = useDownloadsQuery();

  if (isLoading) {
    return <Loading />;
  }

  return <>{data && <DownloadsTree items={data.objects} />}</>;
};

export default DownloadsPage;
