import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {updateBreadcrumbs} from 'redux/actions/breadcrumbsAction';
import {delDataset, getDatasets, postDatasetSearch} from 'redux/actions/datasetAction';
import {updateGlobalSetting} from 'redux/actions/globalSettingAction';
import {Trans, t} from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import {Button, Grid, IconButton, InputAdornment, TextField} from '@mui/material';
import AdminTable from 'components/AdminTable';
import CustomTooltip from 'components/CustomTooltip';
import {useWarningDialog} from 'hooks/useWarningDialog';
import {TableOrderType} from 'types/general';
import {redirectTo} from 'utils/functions';
import i18nProvider from 'utils/i18n';
import PrivateBreadcrumbs from 'layouts/Private/PrivateBreadcrumbs';

let searchInterval: any = null;

const DatasetPage: FC<any> = (props) => {
  const {
    rowsPerPageStore,
    rowsPerPageOptions,
    updateGlobalSetting,
    getDatasets,
    delDataset,
    listLoading,
    listData,
    listMeta,
    updateBreadcrumbs,
    postDatasetSearch,
  } = props;

  const {
    Dialog: DeleteWarning,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
    handleSetLoading: handleSetLoadingDeleteDialog,
    dialogData: deleteDialogData,
  } = useWarningDialog();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageStore);
  const [tableOrder, setTableOrder] = useState({orderBy: 'id', orderDirection: 'DESC'});
  const [searchValue, setSearchValue] = useState('');
  const [searchPostValue, setSearchPostValue] = useState('');

  const onColumnOrder = (order: TableOrderType) => {
    setTableOrder(order);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    updateGlobalSetting({rowsPerPage: parseInt(event.target.value, 10)});
  };

  const handleDeleteDataset = () => {
    handleSetLoadingDeleteDialog(true);
    return delDataset(deleteDialogData.id).then(() => {
      handleCloseDeleteDialog();
      handleFetchDatasets();
    });
  };

  const handleFetchDatasets = () => {
    getDatasets(rowsPerPage, page * rowsPerPage, tableOrder);
  };

  const handleChangeSearchField = (e: any) => {
    setSearchValue(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = (value: string) => {
    clearTimeout(searchInterval);
    searchInterval = setTimeout(() => {
      setSearchPostValue(value);
    }, 500);
  };

  const handleResetSearch = () => {
    setSearchValue('');
    setSearchPostValue('');
  };

  const handleTableRowClick = (id: number) => {
    redirectTo('/dataset/' + id);
  };

  const datasetTableColumns = [
    {
      name: 'id',
      orderKey: 'id',
      label: <Trans>Id</Trans>,
    },
    {
      name: 'name',
      orderKey: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'actions',
      render: (value: any, row: any) => {
        return (
          <Grid container justifyContent={'flex-end'} flexWrap={'nowrap'}>
            <Grid item sx={{pr: 5}}>
              <CustomTooltip title={<Trans>Dataset detail</Trans>}>
                <IconButton
                  color={'info'}
                  sx={{p: 0}}
                  onClick={() => redirectTo(`/dataset/${row.id}`)}
                >
                  <EditIcon fontSize={'small'} />
                </IconButton>
              </CustomTooltip>
            </Grid>
            <Grid item>
              <CustomTooltip title={<Trans>Delete dataset</Trans>}>
                <IconButton
                  color={'error'}
                  sx={{p: 0}}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenDeleteDialog(row);
                  }}
                >
                  <DeleteOutlineIcon fontSize={'small'} />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  useEffect(() => {
    if (searchPostValue) {
      postDatasetSearch(rowsPerPage, page * rowsPerPage, searchPostValue);
    } else {
      handleFetchDatasets();
    }
    updateBreadcrumbs({dataset: {}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, tableOrder, searchPostValue]);

  return (
    <Grid container>
      <DeleteWarning
        onOk={handleDeleteDataset}
        message={<Trans>Delete dataset {deleteDialogData?.name}</Trans>}
      />

      <PrivateBreadcrumbs
        actions={
          <Grid container>
            <TextField
              sx={{
                lineHeight: 1.3,
                mr: 5,
                width: 250,
                maxHeight: '34px !important',
                '& .MuiOutlinedInput-root': {maxHeight: '34px !important'},
              }}
              size={'small'}
              value={searchValue}
              onChange={handleChangeSearchField}
              placeholder={i18nProvider._(t`Search...`)}
              InputProps={{
                endAdornment: searchValue && (
                  <InputAdornment position="end">
                    <CloseIcon
                      fontSize={'small'}
                      sx={{cursor: 'pointer'}}
                      onClick={handleResetSearch}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <CustomTooltip title={<Trans>Create new dataset</Trans>}>
              <Button variant="contained" onClick={() => redirectTo('/dataset/new')} size={'small'}>
                <Trans>New</Trans>
              </Button>
            </CustomTooltip>
          </Grid>
        }
      />

      <Grid container>
        <AdminTable
          data={listData}
          columns={datasetTableColumns}
          loading={listLoading}
          pagination={true}
          page={page}
          count={listMeta?.total_count}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onColumnOrder={onColumnOrder}
          tableOrder={tableOrder}
          onTableRowClick={handleTableRowClick}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (store: any) => {
  return {
    listLoading: store.datasets.listLoading,
    listData: store.datasets.listData,
    listMeta: store.datasets.listMeta,
    rowsPerPageStore: store.setting.rowsPerPage,
    rowsPerPageOptions: store.setting.rowsPerPageOptions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getDatasets,
      delDataset,
      updateGlobalSetting,
      updateBreadcrumbs,
      postDatasetSearch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetPage);
