import React from 'react';
import {Trans} from '@lingui/macro';
import {Box} from '@mui/material';
import {AdminTableColumnType} from 'types/general';

export const doseColumns = (samplingList: any) => {
  const columns: AdminTableColumnType[] = [
    {
      name: 'sample_num',
      label: <Trans>Sample</Trans>,
    },

    {
      name: 'total_dose_rate',
      label: <Trans>Total</Trans>,
      align: 'right',
      render: (val: number) => val?.toFixed(1),
    },
  ];

  samplingList?.dose_rate?.[0]?.dose_rate_in_classes?.forEach(
    (record: Record<string, any>, index: number) => {
      columns.push({
        name: `dose_rate_in_classes`,
        label: <span>{record?.class_num}</span>,
        align: 'right',
        render: (val: any) => {
          return val?.[index]?.dose_rate?.toFixed(1);
        },
      });
    }
  );

  return columns;
};

export const depositColumns = (samplingList: any) => {
  const columns: AdminTableColumnType[] = [
    {
      name: 'sample_num',
      label: <Trans>Sample</Trans>,
    },

    {
      name: 'total_deposited_energy',
      label: <Trans>Total</Trans>,
      align: 'right',
      render: (val: number) => val?.toFixed(1),
    },
  ];

  samplingList?.deposited_energy?.[0]?.deposited_energy_in_classes?.forEach(
    (record: Record<string, any>, index: number) => {
      columns.push({
        name: `deposited_energy_in_classes`,
        label: <span>{record?.class_num}</span>,
        align: 'right',
        render: (val: any) => {
          return val?.[index]?.deposited_energy?.toFixed(1);
        },
      });
    }
  );

  columns.push({
    name: 'deposited_energy_in_others_class',
    label: <Trans>Others</Trans>,
    align: 'right',
    render: (val: number) => val?.toFixed(1),
  });

  return columns;
};

export const fluxColumns = (samplingList: any) => {
  const columns: AdminTableColumnType[] = [
    {
      name: 'sample_num',
      label: <Trans>Sample</Trans>,
    },
    {
      name: 'total_particles_flux',
      label: <Trans>Total</Trans>,
      align: 'right',
      render: (val: number) => val?.toFixed(1),
    },
  ];

  samplingList?.particles_flux?.[0]?.particles_flux_in_classes?.forEach(
    (record: Record<string, any>, index: number) => {
      columns.push({
        name: `particles_flux_in_classes`,
        label: <span>{record?.class_num}</span>,
        align: 'right',
        render: (val: any) => {
          return val?.[index]?.particles?.toFixed(1);
        },
      });
    }
  );

  columns.push({
    name: 'particles_flux_in_others_class',
    label: <Trans>Others</Trans>,
    align: 'right',
    render: (val: number) => val?.toFixed(1),
  });

  return columns;
};

export const physicalProductsColumns = [
  {
    name: 'class_num',
    label: <Trans>#</Trans>,
  },
  {
    name: 'class_name',
    label: <Trans>Class</Trans>,
    width: '400px',
    render: (val: string) => (
      <Box
        sx={{
          fontWeight: 500,
          maxWidth: 400,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {val}
      </Box>
    ),
  },
  {
    name: 'count',
    label: <Trans>Count</Trans>,
    align: 'right',
  },
  {
    name: 'particles_flux',
    label: (
      <span>
        <Trans>Flux</Trans>
        <br />
        <Trans>[#/cm²/s]</Trans>
      </span>
    ),
    align: 'right',
    render: (val: number) => val?.toFixed(1),
  },
  {
    name: 'deposited_energy',
    label: (
      <span>
        <Trans>Deposited energy</Trans>
        <br />
        <Trans>[keV]</Trans>
      </span>
    ),
    align: 'right',
    render: (val: number) => val?.toFixed(1),
  },
  {
    name: 'dose_rate',
    label: (
      <span>
        <Trans>Dose rate</Trans>
        <br />
        <Trans>[μGy/h]</Trans>
      </span>
    ),
    align: 'right',
    render: (val: number) => val?.toFixed(1),
  },
];
