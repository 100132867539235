export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS';

export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_USER_ROLES_REQUEST = 'GET_USER_ROLES_REQUEST';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAIL = 'GET_USER_ROLES_FAIL';

export const GET_DATASET_LIST_REQUEST = 'GET_DATASET_LIST_REQUEST';
export const GET_DATASET_LIST_SUCCESS = 'GET_DATASET_LIST_SUCCESS';
export const GET_DATASET_LIST_FAIL = 'GET_DATASET_LIST_FAIL';

export const GET_DATASET_REQUEST = 'GET_DATASET_REQUEST';
export const GET_DATASET_SUCCESS = 'GET_DATASET_SUCCESS';
export const GET_DATASET_FAIL = 'GET_DATASET_FAIL';

export const GET_DATASET_UPLOAD_REQUEST = 'GET_DATASET_UPLOAD_REQUEST';
export const GET_DATASET_UPLOAD_SUCCESS = 'GET_DATASET_UPLOAD_SUCCESS';
export const GET_DATASET_UPLOAD_FAIL = 'GET_DATASET_UPLOAD_FAIL';

export const GET_DATASET_PARAMS_REQUEST = 'GET_DATASET_PARAMS_REQUEST';
export const GET_DATASET_PARAMS_SUCCESS = 'GET_DATASET_PARAMS_SUCCESS';
export const GET_DATASET_PARAMS_FAIL = 'GET_DATASET_PARAMS_FAIL';

export const GET_DATASET_PROCESSINGS_REQUEST = 'GET_DATASET_PROCESSINGS_REQUEST';
export const GET_DATASET_PROCESSINGS_SUCCESS = 'GET_DATASET_PROCESSINGS_SUCCESS';
export const GET_DATASET_PROCESSINGS_FAIL = 'GET_DATASET_PROCESSINGS_FAIL';

export const GET_DATASET_PROCESSING_REQUEST = 'GET_DATASET_PROCESSING_REQUEST';
export const GET_DATASET_PROCESSING_SUCCESS = 'GET_DATASET_PROCESSING_SUCCESS';
export const GET_DATASET_PROCESSING_FAIL = 'GET_DATASET_PROCESSING_FAIL';

export const GET_SAMPLING_LIST_REQUEST = 'GET_SAMPLING_LIST_REQUEST';
export const GET_SAMPLING_LIST_SUCCESS = 'GET_SAMPLING_LIST_SUCCESS';
export const GET_SAMPLING_LIST_FAIL = 'GET_SAMPLING_LIST_FAIL';

export const SET_PARTICLE_CLASSES_FILTER = 'SET_PARTICLE_CLASSES_FILTER';

export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAIL = 'GET_TAGS_FAIL';

export const GET_FRAMES_REQUEST = 'GET_FRAMES_REQUEST';
export const GET_FRAMES_SUCCESS = 'GET_FRAMES_SUCCESS';
export const GET_FRAMES_FAIL = 'GET_FRAMES_FAIL';

export const GET_CLUSTER_REQUEST = 'GET_CLUSTER_REQUEST';
export const GET_CLUSTER_SUCCESS = 'GET_CLUSTER_SUCCESS';
export const GET_CLUSTER_FAIL = 'GET_CLUSTER_FAIL';

export const GET_PLOTS_REQUEST = 'GET_PLOTS_REQUEST';
export const GET_PLOTS_SUCCESS = 'GET_PLOTS_SUCCESS';
export const GET_PLOTS_FAIL = 'GET_PLOTS_FAIL';

export const GET_TABS_REQUEST = 'GET_TABS_REQUEST';
export const GET_TABS_SUCCESS = 'GET_TABS_SUCCESS';
export const GET_TABS_FAIL = 'GET_TABS_FAIL';

export const GET_PARTICLES_REQUEST = 'GET_PARTICLES_REQUEST';
export const GET_PARTICLES_SUCCESS = 'GET_PARTICLES_SUCCESS';
export const GET_PARTICLES_FAIL = 'GET_PARTICLES_FAIL';

export const GET_LISTS_REQUEST = 'GET_LISTS_REQUEST';
export const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
export const GET_LISTS_FAIL = 'GET_LISTS_FAIL';
