import React, {useState} from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import LoadingMask from 'components/LoadingMask';
import {AdminTableColumnType, TableOrderType} from 'types/general';

type AdminTableType = {
  data: any;
  columns: AdminTableColumnType[];
  loading: boolean;
  pagination?: boolean;
  page?: any;
  count?: any;
  rowsPerPage?: any;
  rowsPerPageOptions?: any;
  onPageChange?: any;
  onRowsPerPageChange?: any;
  onColumnOrder?: any;
  tableOrder?: TableOrderType;
  size?: 'small' | 'medium';
  tableBodyStyle?: any;
  stickyHeader?: boolean;
  tableContainerStyle?: any;
  onTableRowClick?: any;
};

const AdminTable = (props: AdminTableType) => {
  const {
    data,
    columns,
    loading,
    pagination = true,
    page,
    count = 0,
    rowsPerPage,
    rowsPerPageOptions,
    onPageChange,
    onRowsPerPageChange,
    onColumnOrder,
    tableOrder,
    size = 'small',
    stickyHeader = true,
    tableContainerStyle,
    onTableRowClick,
  } = props;

  const [orderDirection, setOrderDirection] = useState(tableOrder?.orderDirection);
  const [orderBy, setOrderBy] = useState(tableOrder?.orderBy);

  const onOrderClick = (sortKey: string) => () => {
    if (orderBy === sortKey && orderDirection === 'ASC') {
      setOrderDirection('DESC');
      setOrderBy(sortKey);
      onColumnOrder({orderBy: sortKey, orderDirection: 'DESC'});
    } else if (orderBy === sortKey && orderDirection === 'DESC') {
      setOrderDirection('ASC');
      setOrderBy(null);
      onColumnOrder({orderBy: null, orderDirection: 'ASC'});
    } else {
      setOrderDirection('ASC');
      setOrderBy(sortKey);
      onColumnOrder({orderBy: sortKey, orderDirection: 'ASC'});
    }
  };

  return (
    <Grid container sx={{position: 'relative'}}>
      <LoadingMask loading={loading} containerStyle={{width: '100%'}}>
        <>
          <TableContainer sx={{...tableContainerStyle}}>
            <Table size={size} stickyHeader={stickyHeader}>
              <TableHead>
                <TableRow>
                  {columns?.map((col: any, colIndex: number) => (
                    <TableCell
                      size={size}
                      key={colIndex}
                      align={col.align ? col.align : 'inherit'}
                      style={{
                        maxWidth: col.width ? col.width : 'auto',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: 1.4,
                        fontWeight: 600,
                        borderBottomColor: '#666',
                        borderBottomWidth: 2,
                      }}
                    >
                      {onColumnOrder && col.orderKey ? (
                        <TableSortLabel
                          onClick={onOrderClick(col.orderKey)}
                          active={orderBy === col.orderKey}
                          direction={orderDirection?.toLowerCase()}
                          sx={{
                            '& svg': {
                              color: (theme) => theme.palette.primary.main + 'dd !important',
                            },
                          }}
                        >
                          {col.label}
                        </TableSortLabel>
                      ) : (
                        col.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: any, rowIndex: number) => (
                  <TableRow
                    key={rowIndex}
                    onClick={() => onTableRowClick && onTableRowClick(row.id)}
                    sx={onTableRowClick ? {cursor: 'pointer'} : {}}
                  >
                    {columns.map((col, colIndex) => (
                      <TableCell
                        size={size}
                        key={colIndex}
                        style={{maxWidth: col.width ? col.width : 'auto'}}
                        align={col.align ? col.align : 'inherit'}
                        id={`${col.name}-${colIndex}`}
                        sx={{
                          backgroundColor: rowIndex % 2 ? 'background.default' : '',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {col.width ? (
                          <CustomTooltip title={col.name && row[col.name] ? row[col.name] : ''}>
                            <Box
                              sx={{
                                cursor: 'default',
                                maxWidth: col.width ? col.width : 'auto',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {col.render
                                ? col.render(row[col.name], row, rowIndex)
                                : row[col.name]}
                            </Box>
                          </CustomTooltip>
                        ) : col.render ? (
                          col.render(row[col.name], row, rowIndex)
                        ) : (
                          row[col.name]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {pagination && (
            <TablePagination
              sx={{fontSize: '14px !important', width: '100%'}}
              component="div"
              count={count}
              page={page}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          )}
        </>
      </LoadingMask>
    </Grid>
  );
};

export default AdminTable;
