import React, {FC} from 'react';
import LoginForm from 'components/forms/LoginForm';
import PublicCard from 'layouts/Public/PublicCard';
import PublicLayout from 'layouts/Public/PublicLayout';

const LoginPage: FC = () => {
  return (
    <PublicLayout>
      <PublicCard>
        <LoginForm />
      </PublicCard>
    </PublicLayout>
  );
};

export default LoginPage;
