import SuspenseComponent from 'app/SuspenseComponent';
import React from 'react';
import {unstable_HistoryRouter as HistoryRouter, Navigate, Route, Routes} from 'react-router-dom';
import browserHistory from 'utils/history';
import PrivateLayout from 'layouts/Private/PrivateLayout';
import DatasetPage from 'pages/Private/DatasetPage';
import DatasetUploadPage from 'pages/Private/DatasetUploadPage';
import DetailDatasetPage from 'pages/Private/DetailDatasetPage';
import DownloadsPage from 'pages/Private/Donwloads/DownloadsPage';
import EditDatasetUploadPage from 'pages/Private/EditDatasetUploadPage';
import HelpPage from 'pages/Private/Help/HelpPage';
import ProcessingResultPage from 'pages/Private/ProcessingResultPage';
import UserManagementPage from 'pages/Private/UserManagementPage';
import UserProfilePage from 'pages/Private/UserProfilePage';
import LoginPage from 'pages/Public/LoginPage';
import RegisterPage from 'pages/Public/RegisterPage';
import ResetPasswordPage from 'pages/Public/SetPasswordPage';

const App = () => {
  return (
    <HistoryRouter history={browserHistory}>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route path="/login" element={<SuspenseComponent component={<LoginPage />} />} />
        <Route
          path="/reset-password/:token"
          element={<SuspenseComponent component={<ResetPasswordPage />} />}
        />
        <Route path="/registration" element={<SuspenseComponent component={<RegisterPage />} />} />

        {/* PRIVATE ROUTES */}
        <Route element={<SuspenseComponent component={<PrivateLayout />} />}>
          <Route
            path="/dataset"
            element={<SuspenseComponent nested={true} component={<DatasetPage />} />}
          />
          <Route
            path="/dataset/new"
            element={<SuspenseComponent nested={true} component={<DatasetUploadPage />} />}
          />
          <Route
            path="/dataset/:id"
            element={<SuspenseComponent nested={true} component={<DetailDatasetPage />} />}
          />
          <Route
            path="/dataset/:id/processing"
            element={<SuspenseComponent nested={true} component={<DetailDatasetPage />} />}
          />
          <Route
            path="/dataset/:id/edit"
            element={<SuspenseComponent nested={true} component={<EditDatasetUploadPage />} />}
          />
          <Route
            path="/dataset/:id/processing/:pId"
            element={<SuspenseComponent nested={true} component={<ProcessingResultPage />} />}
          />
          <Route
            path="/dataset/:id/processing/:pId/result"
            element={<SuspenseComponent nested={true} component={<ProcessingResultPage />} />}
          />
          <Route
            path="/user-management"
            element={<SuspenseComponent nested={true} component={<UserManagementPage />} />}
          />
          <Route
            path="/user-profile"
            element={<SuspenseComponent nested={true} component={<UserProfilePage />} />}
          />
          <Route
            path="/help"
            element={<SuspenseComponent nested={true} component={<HelpPage />} />}
          />
          <Route
            path="/downloads"
            element={<SuspenseComponent nested={true} component={<DownloadsPage />} />}
          />
        </Route>

        {/* GO TO HOME WHEN ROUTE NOT FOUND */}
        <Route path="*" element={<Navigate to="/dataset" replace={true} />} />
      </Routes>
    </HistoryRouter>
  );
};

export default App;
