import React, {FC} from 'react';
import RegisterForm from 'components/forms/RegisterForm';
import PublicCard from 'layouts/Public/PublicCard';
import PublicLayout from 'layouts/Public/PublicLayout';

const RegisterPage: FC<any> = () => {
  return (
    <PublicLayout>
      <PublicCard>
        <RegisterForm />
      </PublicCard>
    </PublicLayout>
  );
};

export default RegisterPage;
