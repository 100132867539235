import React, {FC, Suspense} from 'react';
import {CircularProgress, Grid} from '@mui/material';
import {DRAWER_WIDTH} from 'constants/config';

type SuspenseComponentProps = {
  component: any;
  nested?: boolean;
};

const SuspenseComponent: FC<SuspenseComponentProps> = (props) => {
  const {component, nested} = props;

  return (
    <Suspense
      fallback={
        <Grid
          item
          container
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            minHeight: `${nested ? 'calc(100vh - 90px)' : '100vh'}`,
            minWidth: `${nested ? `calc(100vw - ${DRAWER_WIDTH})` : '100vh'}`,
          }}
        >
          <CircularProgress size={40} thickness={3.6} />
        </Grid>
      }
    >
      {component}
    </Suspense>
  );
};

export default SuspenseComponent;
