import {breadcrumbsReducer} from 'redux/reducers/breadcrumbsReducer';
import {datasetReducer} from 'redux/reducers/datasetReducer';
import {globalSettingReducer} from 'redux/reducers/globalSettingReducer';
import {outputReducer} from 'redux/reducers/outputReducer';
import {tagReducer} from 'redux/reducers/tagReducer';
import {userManagementReducer} from 'redux/reducers/userManagementReducer';
import {configureStore} from '@reduxjs/toolkit';
import {ENVIRONMENT} from 'constants/config';
import {downloadsApi} from 'pages/Private/Donwloads/services/downloads';
import {navigationApi} from 'pages/Private/Help/services/spina/spina';

export const store = configureStore({
  reducer: {
    users: userManagementReducer,
    setting: globalSettingReducer,
    datasets: datasetReducer,
    breadcrumbs: breadcrumbsReducer,
    tags: tagReducer,
    outputs: outputReducer,
    [navigationApi.reducerPath]: navigationApi.reducer,
    [downloadsApi.reducerPath]: downloadsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(navigationApi.middleware, downloadsApi.middleware),
  devTools: ENVIRONMENT !== 'production',
});
