import React, {FC} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import {Box, Button, CircularProgress, Grid} from '@mui/material';
import SelectField from 'components/formComponents/SelectField';
import SwitchField from 'components/formComponents/SwitchField';
import TextField from 'components/formComponents/TextField';
import {ProcessingParamType} from 'types/datasetType';

const NewDatasetProcessingForm: FC<any> = (props) => {
  const {
    initData,
    setProcessingData,
    processingParamsData,
    processingParamsLoading,
    setActiveStep,
  } = props;

  const getInitialValues = () => {
    const data: any = {};
    initData?.params.forEach((param: {ref: string; value: string}) => {
      data[param.ref] = param.value;
    });
    return data;
  };

  const handleSubmit = (values: any) => {
    setProcessingData(values);
    setActiveStep(1);
  };

  const groupBy = (arr: any, property: string) => {
    return arr.reduce(function (memo: any, x: any) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  };

  const renderInput = (processing: ProcessingParamType) => {
    if (processing.enum === '') {
      if (processing.type === 'BOOL') {
        return (
          <Grid item xs={6} key={processing.id}>
            <Field
              name={processing.ref}
              type="checkbox"
              render={(props) => (
                <SwitchField label={processing.label} {...props} helperText={processing.help} />
              )}
            />
          </Grid>
        );
      } else if (processing.type === 'FLOAT') {
        return (
          <Grid item xs={6} key={processing.id}>
            <Field
              name={processing.ref}
              type={'number'}
              render={(props) => (
                <TextField label={processing.label} {...props} helperText={processing.help} />
              )}
            />
          </Grid>
        );
      } else if (processing.type === 'STRING') {
        return (
          <Grid item xs={6} key={processing.id}>
            <Field
              name={processing.ref}
              render={(props) => (
                <TextField label={processing.label} {...props} helperText={processing.help} />
              )}
            />
          </Grid>
        );
      }
    } else {
      const enumJson = processing.enum && JSON.parse(processing.enum);
      if (enumJson) {
        return (
          <Grid item xs={6} key={processing.id}>
            <Field
              name={processing.ref}
              render={(props) => (
                <SelectField
                  options={enumJson}
                  label={processing.label}
                  {...props}
                  helperText={processing.help}
                />
              )}
            />
          </Grid>
        );
      }
    }
    return null;
  };

  return (
    <>
      {processingParamsLoading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Form
          onSubmit={handleSubmit}
          initialValues={getInitialValues()}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <Grid item xs={12} container justifyContent={'flex-end'}>
                <>
                  {Object.entries(groupBy(processingParamsData, 'category'))
                    .sort((a: any, b: any) => {
                      if (a?.[0] > b?.[0]) {
                        return -1;
                      } else if (a?.[0] < b?.[0]) {
                        return 1;
                      } else {
                        return 0;
                      }
                    })
                    .map((a: any, i: number) => (
                      <Grid key={i} container item xs={12} sx={{mt: i === 0 ? 0 : 5}}>
                        {a[0] || <Trans>Uncategorized</Trans>}
                        <Grid container item xs={12} spacing={5}>
                          {a[1].map((processing: ProcessingParamType) => {
                            return renderInput(processing);
                          })}
                        </Grid>
                      </Grid>
                    ))}
                </>

                <Grid container item xs={12} justifyContent={'flex-end'}>
                  <Button type={'submit'} size={'small'} variant={'contained'}>
                    <Trans>Continue</Trans>
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      )}
    </>
  );
};

const mapStateToProps = (store: any) => {
  return {
    processingParamsLoading: store.datasets.processingParamsLoading,
    processingParamsMeta: store.datasets.processingParamsMeta,
    processingParamsData: store.datasets.processingParamsData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDatasetProcessingForm);
