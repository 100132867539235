import React, {FC, ReactElement} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {Box} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type DialogWindowProps = {
  open: boolean;
  onClose: () => void;
  children: ReactElement;
  title: ReactElement;
  maxWidth?: any;
  fullWidth?: boolean;
  scroll?: 'body' | 'paper' | undefined;
};

const DialogWindow: FC<DialogWindowProps> = (props) => {
  const {open, title, onClose, children, maxWidth, fullWidth, scroll = 'paper'} = props;

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open={open}
      onClose={onClose}
      PaperProps={{square: true}}
      scroll={scroll}
    >
      <Box sx={{position: 'absolute', right: 0, p: 4}}>
        <CloseIcon fontSize={'small'} sx={{cursor: 'pointer'}} onClick={onClose} />
      </Box>
      <DialogTitle sx={{pr: 14}}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogWindow;
