import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {getPlots} from 'redux/actions/datasetAction';
import {Grid} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import SpatialMap from 'components/outputs/SpatialMap';

const BitmapStruct: FC<any> = (props) => {
  const {getPlots} = props;

  const [plotsLoading, setPlotsLoading] = useState(false);
  const [plots, setPlots] = useState([]);

  const {id, pId} = useParams();

  useEffect(() => {
    setPlotsLoading(true);
    getPlots(id, pId, 'pregenerated_plots__bitmap')
      .then((res: any) => {
        setPlots(res);
      })
      .finally(() => {
        setPlotsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container sx={{position: 'relative'}}>
      <LoadingMask loading={plotsLoading} containerStyle={{width: '100%'}}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <SpatialMap
              plotsLoading={plotsLoading}
              plots={plots?.filter((plot: any) => plot.feature === 'EnergyIntegrated')}
              graphId={'bitmap-one'}
              isBitmap={true}
            />
          </Grid>
          <Grid item xs={6}>
            <SpatialMap
              plotsLoading={plotsLoading}
              plots={plots?.filter((plot: any) => plot.feature === 'EnergyPartial')}
              graphId={'bitmap-two'}
              isBitmap={true}
            />
          </Grid>
          <Grid item xs={6}>
            <SpatialMap
              plotsLoading={plotsLoading}
              plots={plots?.filter((plot: any) => plot.feature === 'Energy')}
              graphId={'bitmap-three'}
              isBitmap={true}
            />
          </Grid>
          <Grid item xs={6}>
            <SpatialMap
              plotsLoading={plotsLoading}
              plots={plots?.filter((plot: any) => plot.feature === 'MaxSize')}
              graphId={'bitmap-four'}
              isBitmap={true}
            />
          </Grid>
        </Grid>
      </LoadingMask>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getPlots}, dispatch);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (store: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BitmapStruct);
