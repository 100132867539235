import React, {FC} from 'react';
import {Trans} from '@lingui/macro';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {Grid} from '@mui/material';
import FormButton from 'components/formComponents/FormButton';

const DownloadButtons: FC<any> = (props) => {
  const {handleDownload} = props;

  return (
    <Grid item xs={12}>
      <FormButton
        sx={{px: 2, fontSize: 12}}
        text={
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <FileDownloadOutlinedIcon sx={{mr: 1, fontSize: 16}} />
            <Trans>PNG</Trans>
          </Grid>
        }
        onClick={() => handleDownload('png')}
        variant={'text'}
      />
      <FormButton
        sx={{px: 2, mx: 2, fontSize: 12}}
        text={
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <FileDownloadOutlinedIcon fontSize={'small'} sx={{mr: 1, fontSize: 16}} />
            <Trans>SVG</Trans>
          </Grid>
        }
        onClick={() => handleDownload('svg')}
        variant={'text'}
      />
    </Grid>
  );
};

export default DownloadButtons;
