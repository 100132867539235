import {GET_TAGS_FAIL, GET_TAGS_REQUEST, GET_TAGS_SUCCESS} from 'redux/actionTypes';

const initState = {
  tagList: [],
  tagMeta: {},
  tagLoading: false,
};

export const tagReducer = (state = initState, action: any = null) => {
  switch (action.type) {
    case GET_TAGS_REQUEST: {
      return {
        ...state,
        tagLoading: true,
      };
    }
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        tagLoading: false,
        tagList: action.data.objects,
        tagMeta: action.data.meta,
      };
    }
    case GET_TAGS_FAIL: {
      return {
        ...state,
        tagLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
