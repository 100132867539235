import {
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_ROLES_FAIL,
  GET_USER_ROLES_REQUEST,
  GET_USER_ROLES_SUCCESS,
} from 'redux/actionTypes';

const initState = {
  listLoading: false,
  listData: [],
  listMeta: {},

  rolesLoading: false,
  roles: [],
  rolesMeta: {},
};

export const userManagementReducer = (state = initState, action: any = null) => {
  switch (action.type) {
    case GET_USERS_REQUEST: {
      return {
        ...state,
        listLoading: true,
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        listData: action.data.objects,
        listMeta: action.data.meta,
      };
    }
    case GET_USERS_FAIL: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case GET_USER_ROLES_REQUEST: {
      return {
        ...state,
        rolesLoading: true,
      };
    }
    case GET_USER_ROLES_SUCCESS: {
      return {
        ...state,
        rolesLoading: false,
        roles: action.data.objects,
        rolesMeta: action.data.meta,
      };
    }
    case GET_USER_ROLES_FAIL: {
      return {
        ...state,
        rolesLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
