import {
  GET_LISTS_FAIL,
  GET_LISTS_REQUEST,
  GET_LISTS_SUCCESS,
  GET_PARTICLES_FAIL,
  GET_PARTICLES_REQUEST,
  GET_PARTICLES_SUCCESS,
  GET_TABS_FAIL,
  GET_TABS_REQUEST,
  GET_TABS_SUCCESS,
} from 'redux/actionTypes';

const initState = {
  tabs: [],
  tabsLoading: false,

  particleClasses: [],
  particleClassesLoading: false,

  listsLoading: false,
  lists: [],
};

export const outputReducer = (state = initState, action: any = null) => {
  switch (action.type) {
    case GET_TABS_REQUEST: {
      return {
        ...state,
        tabsLoading: true,
      };
    }
    case GET_TABS_SUCCESS: {
      return {
        ...state,
        tabsLoading: false,
        tabs: action.data,
      };
    }
    case GET_TABS_FAIL: {
      return {
        ...state,
        tabsLoading: false,
      };
    }
    case GET_PARTICLES_REQUEST: {
      return {
        ...state,
        particleClassesLoading: true,
      };
    }
    case GET_PARTICLES_SUCCESS: {
      return {
        ...state,
        particleClassesLoading: false,
        particleClasses: action.data,
      };
    }
    case GET_PARTICLES_FAIL: {
      return {
        ...state,
        particleClassesLoading: false,
      };
    }
    case GET_LISTS_REQUEST: {
      return {
        ...state,
        listsLoading: true,
      };
    }
    case GET_LISTS_SUCCESS: {
      return {
        ...state,
        listsLoading: false,
        lists: action.data,
      };
    }
    case GET_LISTS_FAIL: {
      return {
        ...state,
        listsLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
