import {getServiceUrl} from 'utils/functions';

const endpoints = {
  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  forgottenPassword: `${getServiceUrl('auth')}/forgotten-password`,
  resetPassword: `${getServiceUrl('auth')}/reset-password`,
  registration: `${getServiceUrl('auth')}/registration`,
  users: `${getServiceUrl('auth')}/users`,
  currentUser: `${getServiceUrl('auth')}/current-user`,
  roles: `${getServiceUrl('auth')}/roles`,

  datasets: `${getServiceUrl('app')}/datasets`,
  datasetUploads: `${getServiceUrl('app')}/dataset-uploads`,
  datesetProcessingParams: `${getServiceUrl('app')}/dataset-processing-params`,
  outputs: (dId: number, pId: number) =>
    `${getServiceUrl('app')}/datasets/${dId}/processings/${pId}/outputs`,
  tags: `${getServiceUrl('app')}/tags`,
};

export default endpoints;
