import md5 from 'md5';
import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {
  getDatasetUpload,
  postDatasetUpload,
  postDatasetUploadChunk,
  postDatasetUploadFinalize,
} from 'redux/actions/datasetAction';
import {Trans} from '@lingui/macro';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import AdminTable from 'components/AdminTable';
import FormButton from 'components/formComponents/FormButton';
import {CHUNK_SIZE} from 'constants/config';
import {DatasetUploadType} from 'types/datasetType';
import {createFile, fireWarningToast, globalApiErrorHandler} from 'utils/functions';

let cancelUpload = false;

const DatasetUploadForm: FC<any> = (props) => {
  const {
    postDatasetUpload,
    postDatasetUploadChunk,
    postDatasetUploadFinalize,
    setActiveStep,
    datasetUploadsData,
    setDatasetUploadsData,
  } = props;

  const [numberOfFiles, setNumberOfFiles] = useState(0);

  const chunkSizeBytes = CHUNK_SIZE;

  const calculateProgress = () => {
    if (numberOfFiles) {
      return (
        (100 *
          datasetUploadsData.filter((upload: any) => !upload.remaining_chunks.length)?.length) /
        numberOfFiles
      );
    } else {
      return 0;
    }
  };

  const handleUpload = (e: any) => {
    const filesArray = e.target.files;

    cancelUpload = false;
    setNumberOfFiles(filesArray?.length);

    iterateThroughFiles(filesArray, 0);
  };

  const iterateThroughFiles = (filesArray: any[], index: number) => {
    if (index < filesArray?.length) {
      uploadSingleFile(filesArray[index]).then(() => {
        iterateThroughFiles(filesArray, index + 1);
      });
    }
  };

  const uploadSingleFile = async (file: any) => {
    if (!cancelUpload) {
      return new Promise<void>((resolve) => {
        const filename = file.name;

        // create new file reader for chunk
        const reader = new FileReader();

        // on load of reader do chunk upload
        reader.onloadend = async function (event: any) {
          if (event.target.readyState !== FileReader.DONE) {
            return;
          }

          // get chunk data
          const fileChecksum = md5(event.target.result);
          const numberOfChunks = Math.ceil(file.size / chunkSizeBytes);

          postDatasetUpload({
            chunks: numberOfChunks,
            chunk_size: Number(chunkSizeBytes),
            checksum: fileChecksum,
            filename: filename,
          }).then((res: any) => {
            setDatasetUploadsData(
              (prevState: DatasetUploadType[]) => [
                ...prevState.filter((upload: DatasetUploadType) => upload.id !== res.id),
                {
                  filename: filename,
                  id: res.id,
                  chunks: numberOfChunks,
                  chunk_size: Number(chunkSizeBytes),
                  checksum: fileChecksum,
                  remaining_chunks: res.remaining_chunks,
                  uploaded_chunks: res.uploaded_chunks,
                },
              ],
              () => {
                startChunkUpload(res.remaining_chunks, file, res.id).then(() => {
                  resolve();
                });
              }
            );
          });
        };

        reader.readAsArrayBuffer(file);
      });
    }
  };

  const startChunkUpload = (chunkArray: number[], file: any, uploadId: number) => {
    return new Promise<void>((resolve) => {
      uploadChunks(chunkArray, file, uploadId).then(() => {
        resolve();
      });
    });
  };

  const uploadChunks = async (chunkArray: number[], file: any, uploadId: number) => {
    if (!cancelUpload) {
      return new Promise<void>((resolve) => {
        if (chunkArray?.length) {
          const recursiveUpload = (number: number, fileToUpload: any) => {
            handleUploadSingleChunk(number, fileToUpload, uploadId)?.then((res: any) => {
              if (res?.remaining_chunks?.length) {
                recursiveUpload(res?.remaining_chunks[0], file);
              } else {
                resolve();
              }
            });
          };
          recursiveUpload(chunkArray[0], file);
        }
      });
    }
  };

  const handleUploadSingleChunk = (chunkNumber: number, file: any, uploadId: number) => {
    if (!cancelUpload) {
      return new Promise((resolve) => {
        // calculate starting point and ending point of chunk to upload
        const chunkStart = (chunkNumber - 1) * chunkSizeBytes;
        const chunkEnd = Math.min(Number(chunkStart) + Number(chunkSizeBytes), file.size);

        // slice chunk of file
        const chunk = file.slice(chunkStart, chunkEnd);

        // create new file reader for chunk
        const reader = new FileReader();

        // on load of reader do chunk upload
        reader.onloadend = async function (event: any) {
          if (event.target.readyState !== FileReader.DONE) {
            return;
          }

          // get chunk data
          const result = event.target.result;

          const newFileName = `uploadId:${uploadId}-filename:${file.name}-chunkNumber:${chunkNumber}.txt`;

          handleUploadChunkInFormData(result, newFileName, chunkNumber, uploadId)?.then((res) => {
            resolve(res);
          });
        };

        reader.readAsArrayBuffer(chunk);
      });
    }
  };

  const handleUploadChunkInFormData = async (
    result: any,
    newFileName: string,
    tmpChunkNumber: number,
    uploadId: number
  ) => {
    if (!cancelUpload) {
      return new Promise((resolve) => {
        // create form data
        const formData = new FormData();

        formData.append(`data`, createFile(result, newFileName));
        formData.append(`chunk`, String(tmpChunkNumber));

        // reset retry count
        const retryCount = 0;

        const handlePostDatasetUploadChunk = (retryCount: number) => {
          postDatasetUploadChunk(uploadId, formData)
            .then((res: any) => {
              setDatasetUploadsData(
                (prevState: DatasetUploadType[]) => [
                  ...prevState.filter((upload: DatasetUploadType) => upload.id !== res.id),
                  {
                    filename: prevState.find((upload: DatasetUploadType) => upload.id === res.id)
                      ?.filename,
                    id: res.id,
                    chunks: prevState.find((upload: DatasetUploadType) => upload.id === res.id)
                      ?.chunks,
                    chunk_size: res.chunk_size,
                    checksum: res.checksum,
                    remaining_chunks: res.remaining_chunks,
                    uploaded_chunks: res.uploaded_chunks,
                  },
                ],
                null
              );

              if (res.remaining_chunks?.length === 0) {
                handleFinalize(uploadId).then(() => resolve(res));
              } else {
                resolve(res);
              }
            })
            .catch((err: any) => {
              retryCount += 1;
              if (retryCount < 15) {
                // retry dataset chunk upload
                setTimeout(() => {
                  handlePostDatasetUploadChunk(retryCount);
                }, 2000);
              } else {
                return globalApiErrorHandler(err);
              }
            });
        };

        // run dataset chunk upload
        handlePostDatasetUploadChunk(retryCount);
      });
    }
  };

  const handleFinalize = (id: number) => {
    if (id) {
      return postDatasetUploadFinalize(id);
    }
  };

  const datasetColumns = [
    {
      name: 'id',
      label: <Trans>Id</Trans>,
    },
    {
      name: 'filename',
      label: <Trans>File name</Trans>,
    },
    {
      name: 'remaining_chunks',
      label: <Trans>Upload progress</Trans>,
      render: (val: any) => {
        return (
          <Grid
            container
            item
            alignItems={'center'}
            flexWrap={'nowrap'}
            sx={{minHeight: 35, height: 35}}
          >
            <Grid container item sx={{width: 40}} alignItems={'center'}>
              {val?.length > 0 ? (
                <CircularProgress color={'warning'} size={17} sx={{mx: 0.75}} />
              ) : (
                <CheckIcon fontSize={'small'} color={'success'} />
              )}
            </Grid>
          </Grid>
        );
      },
    },
  ];

  useEffect(() => {
    if (numberOfFiles === 0 && datasetUploadsData?.length) {
      setNumberOfFiles(datasetUploadsData?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetUploadsData]);

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant={'body2'}>
          <Trans>Select files to upload</Trans>
        </Typography>
        <Button
          sx={{mt: 2.5}}
          variant={'contained'}
          size={'small'}
          onClick={() => {
            // reset file input values
            const input = document.getElementById('file-upload');

            input?.setAttribute('type', 'text');
            input?.setAttribute('type', 'file');

            document.getElementById('file-upload')?.click();
          }}
        >
          <Trans>Upload</Trans>
        </Button>
        <Button
          sx={{mt: 2.5, ml: 2.5}}
          variant={'text'}
          size={'small'}
          disabled={!numberOfFiles || cancelUpload}
          onClick={() => {
            cancelUpload = true;
            fireWarningToast(<Trans>Dataset upload canceled</Trans>);
          }}
        >
          Cancel
        </Button>
        <input
          style={{display: 'none'}}
          id="file-upload"
          type={'file'}
          onChange={handleUpload}
          multiple
        />
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{my: 8, mx: 12}} />
      </Grid>

      <Grid container item xs={12}>
        <Grid item container xs={6}>
          <Grid item xs={5}>
            <Typography variant={'body2'}>
              <Trans>Number of files:</Trans>
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant={'body2'}>{numberOfFiles}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{mt: 8, mx: 12}} />
      </Grid>

      <Grid container item xs={12} sx={{mb: 8, mt: 4}}>
        <Accordion sx={{width: '100%'}} elevation={0} disableGutters={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
              <Box sx={{pr: 4}}>
                <Typography variant="body2" color="text.primary" noWrap={true}>
                  <Trans>Upload progress</Trans>
                </Typography>
              </Box>
              <Box sx={{width: 'calc(100% - 40px)', pr: 4}}>
                <LinearProgress
                  variant="determinate"
                  value={calculateProgress()}
                  sx={{height: 6}}
                />
              </Box>
              <Box sx={{width: 40}}>
                <Typography variant="body2" color="text.primary">{`${Math.round(
                  calculateProgress()
                )}%`}</Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <AdminTable
              data={datasetUploadsData.sort((a: any, b: any) => b.id - a.id)}
              columns={datasetColumns}
              loading={false}
              pagination={false}
              size={'small'}
              stickyHeader={true}
              tableContainerStyle={{maxHeight: 200, overflowY: 'auto'}}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12} container sx={{mt: 2.5}} justifyContent={'flex-end'}>
        <FormButton
          sx={{my: 2.5, px: 4}}
          disabled={!numberOfFiles || datasetUploadsData?.length !== numberOfFiles}
          onClick={() => {
            setActiveStep(1);
          }}
          text={<Trans>Continue</Trans>}
        />
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      postDatasetUpload,
      postDatasetUploadChunk,
      postDatasetUploadFinalize,
      getDatasetUpload,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(DatasetUploadForm);
