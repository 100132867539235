import React, {FC, ReactElement} from 'react';
import {CircularProgress, Grid} from '@mui/material';

type LoadingMaskProps = {
  children: ReactElement;
  loading: boolean;
  containerStyle?: Record<string, any>;
};

const LoadingMask: FC<LoadingMaskProps> = (props) => {
  const {children, loading, containerStyle} = props;
  return (
    <>
      {loading && (
        <Grid
          sx={{
            position: 'absolute',
            height: 'calc(100% - 20px)',
            width: 'calc(100% - 20px)',
            pointerEvents: 'auto',
            zIndex: 1,
          }}
          container
        >
          <Grid item container xs={12} alignContent={'center'} justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      <span
        style={{filter: loading ? 'blur(1.5px)' : '', transition: 'all 200ms', ...containerStyle}}
      >
        {children}
      </span>
    </>
  );
};

export default LoadingMask;
