import {AxiosResponse} from 'axios';
import React from 'react';
import {
  GET_CLUSTER_FAIL,
  GET_CLUSTER_REQUEST,
  GET_CLUSTER_SUCCESS,
  GET_DATASET_FAIL,
  GET_DATASET_LIST_FAIL,
  GET_DATASET_LIST_REQUEST,
  GET_DATASET_LIST_SUCCESS,
  GET_DATASET_PARAMS_FAIL,
  GET_DATASET_PARAMS_REQUEST,
  GET_DATASET_PARAMS_SUCCESS,
  GET_DATASET_PROCESSINGS_FAIL,
  GET_DATASET_PROCESSINGS_REQUEST,
  GET_DATASET_PROCESSINGS_SUCCESS,
  GET_DATASET_PROCESSING_FAIL,
  GET_DATASET_PROCESSING_REQUEST,
  GET_DATASET_PROCESSING_SUCCESS,
  GET_DATASET_REQUEST,
  GET_DATASET_SUCCESS,
  GET_DATASET_UPLOAD_FAIL,
  GET_DATASET_UPLOAD_REQUEST,
  GET_DATASET_UPLOAD_SUCCESS,
  GET_FRAMES_FAIL,
  GET_FRAMES_REQUEST,
  GET_FRAMES_SUCCESS,
  GET_PLOTS_FAIL,
  GET_PLOTS_REQUEST,
  GET_PLOTS_SUCCESS,
  GET_SAMPLING_LIST_FAIL,
  GET_SAMPLING_LIST_REQUEST,
  GET_SAMPLING_LIST_SUCCESS,
  SET_PARTICLE_CLASSES_FILTER,
} from 'redux/actionTypes';
import {Trans} from '@lingui/macro';
import {Histograms1D} from 'types/datasets/processing/outputs';
import {RequestThunkAction} from 'types/general';
import endpoints from 'utils/endpoints';
import {fireSuccessToast, globalApiErrorHandler} from 'utils/functions';
import httpClient from 'utils/httpClient';

export const getDatasets = (limit: number, offset: number, meta: any): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_DATASET_LIST_REQUEST});
    return httpClient
      .get(endpoints.datasets, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_DATASET_LIST_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_DATASET_LIST_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const postDataset = (data: any): RequestThunkAction => {
  return () => {
    return httpClient
      .post(endpoints.datasets, data)
      .then((res) => {
        fireSuccessToast(<Trans>New dataset created</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const postDatasetSearch = (
  limit: number,
  offset: number,
  search: any
): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_DATASET_LIST_REQUEST});

    const urlParams = `?limit=${limit}&offset=${offset}`;

    return httpClient
      .post(`${endpoints.datasets}/search${urlParams}`, {query: search || null})
      .then((res) => {
        dispatch({type: GET_DATASET_LIST_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_DATASET_LIST_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const getDataset = (id: number): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_DATASET_REQUEST});
    return httpClient
      .get(`${endpoints.datasets}/${id}`)
      .then((res) => {
        dispatch({type: GET_DATASET_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_DATASET_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const delDataset = (id: number): RequestThunkAction => {
  return () => {
    return httpClient
      .del(`${endpoints.datasets}/${id}`)
      .then((res) => {
        fireSuccessToast(<Trans>Dataset deleted</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const patchDataset = (id: number, data: any): RequestThunkAction => {
  return () => {
    return httpClient
      .patch(`${endpoints.datasets}/${id}`, data)
      .then((res) => {
        fireSuccessToast(<Trans>Dataset edited</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const postDatasetUpload = (data: any): RequestThunkAction => {
  return () => {
    return httpClient
      .post(endpoints.datasetUploads, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const getDatasetUpload = (id: number): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_DATASET_UPLOAD_REQUEST});
    return httpClient
      .get(`${endpoints.datasetUploads}/${id}`)
      .then((res) => {
        dispatch({type: GET_DATASET_UPLOAD_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_DATASET_UPLOAD_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const delDatasetUpload = (id: number): RequestThunkAction => {
  return () => {
    return httpClient
      .del(`${endpoints.datasetUploads}/${id}`)
      .then((res) => {
        fireSuccessToast(<Trans>Dataset upload canceled</Trans>);
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const postDatasetUploadChunk = (id: number, data: any): RequestThunkAction => {
  return () => {
    return httpClient
      .post(`${endpoints.datasetUploads}/${id}/chunk`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
};

export const postDatasetUploadFinalize = (id: number): RequestThunkAction => {
  return () => {
    return httpClient
      .post(`${endpoints.datasetUploads}/${id}/finalize`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const getDatasetProcessingParams = (): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_DATASET_PARAMS_REQUEST});
    return httpClient
      .get(endpoints.datesetProcessingParams)
      .then((res) => {
        dispatch({type: GET_DATASET_PARAMS_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_DATASET_PARAMS_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

/*
 * PROCESSING
 */

export const getDatasetProcessings = (datasetId: number): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_DATASET_PROCESSINGS_REQUEST});
    return httpClient
      .get(`${endpoints.datasets}/${datasetId}/processings`, {order_by: 'id:DESC', limit: 100})
      .then((res) => {
        dispatch({type: GET_DATASET_PROCESSINGS_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_DATASET_PROCESSINGS_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const getDatasetProcessing = (
  datasetId: number,
  processingId: number
): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_DATASET_PROCESSING_REQUEST});
    return httpClient
      .get(`${endpoints.datasets}/${datasetId}/processings/${processingId}`)
      .then((res) => {
        dispatch({type: GET_DATASET_PROCESSING_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_DATASET_PROCESSING_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const postDatasetProcessing = (datasetId: number, data: any): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.datasets}/${datasetId}/processings`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const patchDatasetProcessing = (
  datasetId: number,
  processingId: number,
  data: any
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .patch(`${endpoints.datasets}/${datasetId}/processings/${processingId}`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const deleteDatasetProcessing = (
  datasetId: number,
  processingId: number
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .del(`${endpoints.datasets}/${datasetId}/processings/${processingId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const enqueueDatasetProcessing = (
  datasetId: number,
  processingId: number
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .put(`${endpoints.datasets}/${datasetId}/processings/${processingId}/enqueue`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const getProcessingOutputsDownload = (
  datasetId: number,
  processingId: number
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.outputs(datasetId, processingId)}/download`,
        {},
        {
          headers: {
            'Content-Type': 'application/zip',
            Accept: 'application/json',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const getProcessingSamplingList = (
  datasetId: number,
  processingId: number
): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_SAMPLING_LIST_REQUEST});
    return httpClient
      .get(`${endpoints.datasets}/${datasetId}/processings/${processingId}/outputs/sampling-list`)
      .then((res) => {
        dispatch({type: GET_SAMPLING_LIST_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_SAMPLING_LIST_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const getTimePlotParticleFlux = (
  datasetId: number,
  processingId: number,
  params: any
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .post(
        `${endpoints.datasets}/${datasetId}/processings/${processingId}/outputs/time-plots/particles-flux`,
        params
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const getTimePlotDoseRate = (
  datasetId: number,
  processingId: number,
  params: {particle_classes: string[]}
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .post(
        `${endpoints.datasets}/${datasetId}/processings/${processingId}/outputs/time-plots/dose-rate`,
        params
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const getTimePlotDepositedEnergy = (
  datasetId: number,
  processingId: number,
  params: {particle_classes: string[]}
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .post(
        `${endpoints.datasets}/${datasetId}/processings/${processingId}/outputs/time-plots/deposited-energy`,
        params
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const changeParticleClassFilter = (data: any): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: SET_PARTICLE_CLASSES_FILTER, data: data});
  };
};

export const post1dHistogram = (
  datasetId: number,
  processingId: number,
  data: {feature: string; category: string; sub_plots: string[]}
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .post(
        `${endpoints.datasets}/${datasetId}/processings/${processingId}/outputs/1d-histograms`,
        data
      )
      .then((res: AxiosResponse<Histograms1D>) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const post2dHistogram = (
  datasetId: number,
  processingId: number,
  category: string,
  feature: string,
  sub_plot: string | null
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    const data: {feature: string; category: string; sub_plot?: string} = {
      category,
      feature,
    };
    if (sub_plot) {
      data.sub_plot = sub_plot;
    }
    return httpClient
      .post(
        `${endpoints.datasets}/${datasetId}/processings/${processingId}/outputs/2d-histograms`,
        data
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const getFrame = (
  datasetId: number,
  processingId: number,
  frameNumber: number
): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_FRAMES_REQUEST});
    return httpClient
      .get(
        `${endpoints.datasets}/${datasetId}/processings/${processingId}/outputs/frames/${frameNumber}`
      )
      .then((res) => {
        dispatch({type: GET_FRAMES_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_FRAMES_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const getCluster = (
  datasetId: number,
  processingId: number,
  clusterId: number
): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_CLUSTER_REQUEST});
    return httpClient
      .get(
        `${endpoints.datasets}/${datasetId}/processings/${processingId}/outputs/clusters/${clusterId}`
      )
      .then((res) => {
        dispatch({type: GET_CLUSTER_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_CLUSTER_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const getPlots = (
  datasetId: number,
  processingId: number,
  categories: string
): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_PLOTS_REQUEST});
    return httpClient
      .get(
        `${endpoints.datasets}/${datasetId}/processings/${processingId}/outputs/plots?categories=${categories}`
      )
      .then((res) => {
        dispatch({type: GET_PLOTS_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_PLOTS_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};
