// @ts-ignore
import Plotly from 'plotly.js-dist-min';
import React, {FC, useEffect} from 'react';
import {Box, Grid} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import DownloadButtons from 'components/outputs/DownloadButtons';
import {Histograms1D} from 'types/datasets/processing/outputs';

const HistogramGraph: FC<any> = (props) => {
  const {
    logScaleX,
    logScaleY,
    graphData,
    graphDataLoading,
    selectedSubplot,
    selectedPlot,
    graphId,
  } = props;

  const handleDownload = (format = 'png') => {
    const options = {
      format: format,
      width: 1280,
      height: 960,
      filename: selectedPlot?.feature,
    };

    Plotly.downloadImage(graphId, options);
  };

  const getPlotLayout = (data?: HistogramData, logScaleX?: boolean, logScaleY?: boolean) => {
    const plotLayout = {
      margin: {
        l: 60,
        r: 20,
        t: 40,
        b: 40,
      },
      xaxis: {
        type: logScaleX ? 'log' : 'linear',
        title: {
          text: graphData?.axis?.x_label ? graphData?.axis?.x_label : 'X',
        },
      },
      yaxis: {
        type: logScaleY ? 'log' : 'linear',
        title: {
          text: graphData?.axis?.x_label ? graphData?.axis?.y_label : 'Y',
        },
      },
      bargap: 0,
      bargroupgap: 0,
      legend: {
        yanchor: 'right',
        xanchor: 'top',
        y: 1.1,
      },
      showlegend: false,
    };

    if (data) {
      let newLayout = {};
      if (logScaleY) {
        newLayout = {
          yaxis: {
            ...plotLayout.yaxis,
            range: [Math.log10(0.9), Math.log10(data.xbins.end as number)],
          },
        };
      }

      return {
        ...plotLayout,
        ...newLayout,
      };
    }

    return plotLayout;
  };

  const calculateXValues = (data: Histograms1D, y: number[], filteredIndexPoints: number[]) => {
    if (data.axis?.x_bin_size) {
      return new Array(y.length)
        .fill(0)
        .map((val: any, index: number) => index * data.axis.x_bin_size + data.axis.x_min);
    } else {
      return data.axis?.x_bin_bounds
        ?.filter((bound: number, index: number) => {
          return !filteredIndexPoints[index];
        })
        .map((bound: any) => {
          return Number(bound);
        });
    }
  };

  const calculateWidthValues = (data: Histograms1D) => {
    if (data.axis?.x_bin_size) {
      return data.axis.x_bin_size;
    } else {
      return data.axis.x_bin_bounds?.map((bound: any, index: any) => {
        if (index !== 0) {
          return Number(bound) - Number(data.axis.x_bin_bounds?.[index - 1]);
        } else {
          return Number(bound);
        }
      });
    }
  };

  const extendsValuesForBetterGraphVisualization = (x: number[], y: number[]) => {
    // let newY, newX;
    const newX = [x[0]];

    for (let i = 1; i < x.length; i += 1) {
      newX.push(x[i]);
      newX.push(x[i]);
    }

    const newY = [y[0]];

    for (let i = 1; i < y.length; i += 1) {
      newY.push(y[i - 1]);
      newY.push(y[i]);
    }

    x = newX;
    y = newY;
    return [x, y];
  };

  type HistogramData = {
    mode: 'lines';
    name: any;
    x: number[];
    width: number | number[];
    y: number[];
    type: 'scattergl';
    xbins: {start: number | undefined; end: number | undefined};
    ybins: {start: number | undefined; end: number | undefined};
  };

  const removeZeroValuesFromEnd = (x: number[], y: number[]) => {
    let lastNoneZeroIndex = y.length - 1;
    y.forEach((value, index) => {
      if (value > 0) {
        lastNoneZeroIndex = index;
      }
    });
    return [x.slice(0, lastNoneZeroIndex), y.slice(0, lastNoneZeroIndex)];
  };

  const getHistogramData = (
    data: Histograms1D,
    isLogX = false,
    isLogY = false
  ): HistogramData[] | any => {
    return Object.values(selectedSubplot)?.map((subPlot: any) => {
      const filteredIndexPoints: number[] = [];
      const y: number[] = [];
      const dataY = data.data[subPlot];

      if (!dataY) {
        return [];
      }

      dataY
        ?.map((value, index) => {
          return {value, originalIndex: index};
        })
        ?.forEach(({value, originalIndex}) => {
          y[originalIndex] = value;
        });

      let [newX, newY] = extendsValuesForBetterGraphVisualization(
        calculateXValues(data, y, filteredIndexPoints),
        y
      );

      [newX, newY] = removeZeroValuesFromEnd(newX, newY);

      if (isLogX || isLogY) {
        newY = newY?.map((value) => {
          if (value === 0) {
            return 0.00000000000000000001;
          }
          return value;
        });
      }

      const isOnlyEmptyValue: {[key: string]: boolean} = {};

      newX?.forEach((value, index) => {
        const key = value.toString();
        if (isOnlyEmptyValue[key] === undefined) {
          isOnlyEmptyValue[key] = true;
        }

        if (isOnlyEmptyValue[key]) {
          isOnlyEmptyValue[key] = newY[index] === 0;
        }
      });

      newY = newY?.filter((value, index) => {
        return !isOnlyEmptyValue[newX[index]];
      });

      newX = newX?.filter((value, index) => {
        return !isOnlyEmptyValue[newX[index]];
      });

      let maxX = data?.axis?.x_max;

      if (logScaleY) {
        maxX = Math.max(...newY);
      }

      return {
        name: selectedPlot?.sub_plots?.[subPlot] || subPlot,
        y: newY,
        x: newX,
        width: calculateWidthValues(data),
        type: 'scattergl',
        mode: 'lines',
        xbins: {
          end: maxX,
          start: data?.axis?.x_min,
        },
      };
    });
  };

  const getCoverLine = (data: HistogramData) => {
    return {
      x: [data.x[0], data.x[data.x.length - 1]],
      y: [0, 0],
      type: 'scattergl',
      mode: 'lines',
      name: '',
      marker: {
        color: 'gray',
      },
    };
  };

  useEffect(() => {
    const boxWidth = document.getElementById(graphId)?.clientWidth;
    if (boxWidth) {
      if (graphData) {
        const data = getHistogramData(graphData, logScaleX, logScaleY);

        if (data?.[0]) {
          Plotly.react(graphId, [...data, getCoverLine(data[0])], {
            ...getPlotLayout(data[0], logScaleX, logScaleY),
            width: boxWidth,
            height: boxWidth > 600 ? 600 : boxWidth * 0.83,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData]);

  useEffect(() => {
    if (graphData) {
      const data = getHistogramData(graphData, logScaleX, logScaleY);

      if (data?.[0]) {
        Plotly.react(graphId, [...data, getCoverLine(data[0])], {
          ...getPlotLayout(data[0], logScaleX, logScaleY),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logScaleY, logScaleX]);

  useEffect(() => {
    Plotly.newPlot(graphId, [], getPlotLayout(), {displaylogo: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12} sx={{position: 'relative'}}>
        <LoadingMask
          loading={graphDataLoading}
          containerStyle={{display: 'flex', justifyContent: 'center'}}
        >
          <Box sx={{width: '100%', boxSizing: 'border-box'}} id={graphId} />
        </LoadingMask>
      </Grid>

      <DownloadButtons handleDownload={handleDownload} />
    </>
  );
};

export default HistogramGraph;
