import * as React from 'react';
import {FC} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FolderIcon from '@mui/icons-material/Folder';
import TreeItem, {TreeItemProps, treeItemClasses} from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Box from '@mui/material/Box';
import {SvgIconProps} from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {DownloadsItem} from 'pages/Private/Donwloads/types';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({theme}) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const StyledTreeItem = (props: StyledTreeItemProps) => {
  const {bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, ...other} = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{display: 'flex', alignItems: 'center', p: 0.5, pr: 0}}>
          <Box component={LabelIcon} color="inherit" sx={{mr: 1}} />
          <Typography variant="body2" sx={{fontWeight: 'inherit', flexGrow: 1}}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
};

type DownloadsTreeProps = {items: DownloadsItem[]};
const DownloadsTree: FC<DownloadsTreeProps> = ({items}) => {
  const downloadFile = (item: DownloadsItem) => {
    if (item.url) {
      window.open(item.url, '_blank')?.focus();
    }
  };

  const renderItem = (item: DownloadsItem, depth = 0) => {
    if (item.kind === 'file') {
      return (
        <StyledTreeItem
          sx={{marginLeft: 4 * depth}}
          key={item.id}
          onClick={() => downloadFile(item)}
          nodeId={item.id}
          labelText={item.label}
          labelInfo={item.description}
          labelIcon={AttachFileIcon}
        />
      );
    }

    if (item.kind === 'directory' && item.children) {
      return (
        <StyledTreeItem
          sx={{marginLeft: 4 * depth}}
          key={item.id}
          nodeId={item.id}
          labelText={item.name}
          labelIcon={FolderIcon}
        >
          {item.children.map((subItem) => {
            return renderItem(subItem, depth + 1);
          })}
        </StyledTreeItem>
      );
    }
  };

  return (
    <TreeView
      aria-label="downloads"
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{width: 24}} />}
      sx={{flexGrow: 1, width: 1, overflowY: 'auto'}}
    >
      {items.map((item) => {
        return renderItem(item);
      })}
    </TreeView>
  );
};

export default DownloadsTree;
