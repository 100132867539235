import React, {FC} from 'react';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {logout} from 'redux/actions/authAction';
import {Trans} from '@lingui/macro';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import {ListItemIcon, ListItemText} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Esa from 'assets/esa.svg';
import Logo from 'assets/logo.png';
import TraXLogo from 'assets/traxEngine_logo_text_black.png';
import {redirectTo} from 'utils/functions';

type PrivateAppBar = {
  logout: () => any;
};

const PrivateAppBar: FC<PrivateAppBar> = (props) => {
  const {logout} = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    return logout().then(() => {
      redirectTo('/login');
    });
  };

  const handleRedirect = (path: string) => () => {
    redirectTo(path);
  };

  return (
    <AppBar
      color={'transparent'}
      elevation={2}
      position="fixed"
      sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
    >
      <Toolbar
        variant="dense"
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            '& img': {
              maxHeight: 30,
              marginRight: '12px',
            },
          }}
        >
          <img src={Logo} alt={'logo'} />
          <img src={Esa} alt={'esa'} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& img': {
              maxHeight: 44,
            },
          }}
        >
          <img src={TraXLogo} alt={'trax-logo'} />
        </Box>
        <IconButton size="medium" onClick={handleMenu} sx={{color: 'text.secondary'}}>
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText disableTypography={true} primary={<Trans>Logout</Trans>} />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({logout}, dispatch);
};

export default connect(null, mapDispatchToProps)(PrivateAppBar);
