import React, {FC} from 'react';
import SetPasswordForm from 'components/forms/SetPasswordForm';
import PublicCard from 'layouts/Public/PublicCard';
import PublicLayout from 'layouts/Public/PublicLayout';

const SetPasswordPage: FC<any> = () => {
  return (
    <PublicLayout>
      <PublicCard>
        <SetPasswordForm />
      </PublicCard>
    </PublicLayout>
  );
};

export default SetPasswordPage;
