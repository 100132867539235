import React, {FC} from 'react';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import {Breadcrumbs, Grid, Typography} from '@mui/material';
import {redirectTo} from 'utils/functions';

type PrivateBreadcrumbsProps = {
  breadcrumbs: any;
  actions?: any;
};

const PrivateBreadcrumbs: FC<PrivateBreadcrumbsProps> = (props) => {
  const {breadcrumbs, actions} = props;

  let previousBreadcrumb: string | number | null = null;

  // object lookup
  const breadcrumbsTransObject: any = {
    'user-profile': <Trans>User profile</Trans>,
    'user-management': <Trans>User management</Trans>,
    dataset: <Trans>Dataset</Trans>,
    processing: <Trans>Processing</Trans>,
  };

  const getBreadcrumbTrans = (string: any) => {
    const translation = breadcrumbsTransObject[string];

    const name = previousBreadcrumb && breadcrumbs[previousBreadcrumb]?.name;
    previousBreadcrumb = string;

    return translation || name || <span>{string}</span>;
  };

  const getBreadcrumbs = () => {
    const breadcrumbs = [];
    const pathList = location.pathname.split('/');
    const breadCrumbUrl = location.pathname.split('/');

    // remove first element of array, in this case it is empty string
    // pathList is for names of breadcrumbs (advancing from left to right)
    pathList.shift();

    // remove last element of array, in this case current page which has no link
    // breadCrumbUrl is for link addresses of breadcrumbs
    breadCrumbUrl.pop();

    // base path of breadcrumb
    let basePath = '';

    while (pathList?.length > 1) {
      // add slash and new part of the url
      basePath += '/' + pathList[0];

      // create scoped variable to not override in
      const path = basePath;

      breadcrumbs.push(
        <Typography
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          key={pathList?.length}
          color="textPrimary"
          onClick={() => redirectTo(path)}
        >
          {getBreadcrumbTrans(pathList[0])}
        </Typography>
      );

      breadCrumbUrl.pop();
      pathList.shift();
    }

    breadcrumbs.push(
      <Typography
        key={'last-breadcrumb'}
        color="textPrimary"
        sx={{fontWeight: 600, textTransform: 'capitalize'}}
      >
        {getBreadcrumbTrans(pathList[0])}
      </Typography>
    );

    return breadcrumbs;
  };

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{pb: 5, minHeight: 60}}
    >
      <Grid item>
        <Breadcrumbs>{getBreadcrumbs()}</Breadcrumbs>
      </Grid>
      <Grid item>{actions}</Grid>
    </Grid>
  );
};

const mapStateToProps = (store: any) => {
  return {
    breadcrumbs: store.breadcrumbs,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateBreadcrumbs);
