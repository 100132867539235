import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {getPlots} from 'redux/actions/datasetAction';
import {Grid} from '@mui/material';
import SpatialMap from 'components/outputs/SpatialMap';

const SpatialMapTab: FC<any> = (props) => {
  const {getPlots} = props;

  const [plotsLoading, setPlotsLoading] = useState(false);
  const [plots, setPlots] = useState([]);

  const {id, pId} = useParams();

  useEffect(() => {
    setPlotsLoading(true);
    getPlots(id, pId, 'spatial_maps__2d_histogram')
      .then((res: any) => {
        setPlots(res);
      })
      .finally(() => {
        setPlotsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <SpatialMap
          plots={plots}
          plotsLoading={plotsLoading}
          graphId={'2d-histogram-one'}
          plotIndex={0}
        />
      </Grid>
      <Grid item xs={6}>
        <SpatialMap
          plots={plots}
          plotsLoading={plotsLoading}
          graphId={'2d-histogram-two'}
          plotIndex={1}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (store: any) => {
  return {
    plots: store.datasets.plots,
    plotsLoading: store.datasets.plotsLoading,
    particleClassesFilters: store.datasets.particleClassesFilters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getPlots}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SpatialMapTab);
