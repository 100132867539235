import {UPDATE_BREADCRUMBS} from 'redux/actionTypes';

const initState = {};

export const breadcrumbsReducer = (state = initState, action: any = null) => {
  if (action.type === UPDATE_BREADCRUMBS) {
    return {...state, ...action.data};
  } else {
    return state;
  }
};
