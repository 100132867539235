import React, {FC, useEffect} from 'react';
import {connect} from 'react-redux';
import {Outlet, useNavigate} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {logout} from 'redux/actions/authAction';
import {Trans} from '@lingui/macro';
import {Box, Grid} from '@mui/material';
import Footer from 'assets/footer.svg';
import {DRAWER_WIDTH, FORCE_NO_AUTH, LS_LOGGED_USER, LS_SESSION_ID_NAME} from 'constants/config';
import {getItemFromStorage} from 'utils/functions';
import PrivateAppBar from 'layouts/Private/PrivateAppBar';
import PrivateDrawerMenu from 'layouts/Private/PrivateDrawerMenu';

const PrivateLayout: FC = () => {
  const navigate = useNavigate();

  const hasSessionId = () => {
    return !!getItemFromStorage(LS_SESSION_ID_NAME);
  };

  const hasLoggedUser = () => {
    return !!getItemFromStorage(LS_LOGGED_USER);
  };

  const isNotAuthorized = !FORCE_NO_AUTH && (!hasSessionId() || !hasLoggedUser());

  useEffect(() => {
    if (isNotAuthorized) {
      navigate('login');
    }
  }, []);

  return (
    <Box sx={{display: 'flex'}}>
      <PrivateAppBar />
      <PrivateDrawerMenu />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 5,
          marginTop: '50px',
          maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
          minHeight: `calc(100vh - 50px)`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Grid container>{!isNotAuthorized && <Outlet />}</Grid>

        <Box
          sx={{
            height: '30px',
            mt: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span
            style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}
            onClick={() => window.open('https://www.eluvia.com/')}
          >
            <span style={{color: '#999999', fontSize: 12}}>
              <Trans>Launched from land to cloud by Eluvians</Trans>
            </span>
            <img style={{height: 18, padding: '0 10px'}} src={Footer} alt={'footer'} />
          </span>
        </Box>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({logout}, dispatch);
};

export default connect(null, mapDispatchToProps)(PrivateLayout);
