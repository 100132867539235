import {diff} from 'deep-object-diff';
import React, {FC, useEffect} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {getRoles} from 'redux/actions/authAction';
import {patchUser, postUser} from 'redux/actions/userManagementActions';
import {Trans} from '@lingui/macro';
import {Button, Grid} from '@mui/material';
import MultiSelectField from 'components/formComponents/MultiSelectField';
import TextField from 'components/formComponents/TextField';
import {UserType} from 'types/userType';
import {mapErrorsToForm} from 'utils/functions';

type UserManagementFormProps = {
  postUser: any;
  patchUser: any;
  getRoles: any;
  onClose: any;
  initData?: UserType | null;
  roles: any[];
  rolesLoading: boolean;
  handleFetchUsers: any;
};

const UserManagementForm: FC<UserManagementFormProps> = (props) => {
  const {postUser, patchUser, onClose, initData, getRoles, roles, handleFetchUsers, rolesLoading} =
    props;

  const handleSubmit = (data: UserType) => {
    if (initData?.id) {
      const patchData = diff(initData, data);
      return patchUser(initData?.id, patchData)
        .then(() => {
          handleFetchUsers();
          onClose();
        })
        .catch((err: any) => {
          return mapErrorsToForm(err);
        });
    } else {
      return postUser(data)
        .then(() => {
          handleFetchUsers();
          onClose();
        })
        .catch((err: any) => {
          return mapErrorsToForm(err);
        });
    }
  };

  const getInitialValues = () => {
    return {
      email: initData?.email,
      name: initData?.name,
      role_ids: initData?.roles?.map((role) => role.id),
    };
  };

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={getInitialValues()}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <Grid container alignItems={'center'} justifyContent={'flex-start'} spacing={5}>
            <Grid item xs={12}>
              <Field
                name={'email'}
                render={(props) => <TextField label={<Trans>E-mail</Trans>} {...props} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={'name'}
                render={(props) => <TextField label={<Trans>Name</Trans>} {...props} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={'role_ids'}
                render={(props) => (
                  <MultiSelectField
                    options={roles.map((role) => ({label: role.name, value: role.id}))}
                    loading={rolesLoading}
                    label={<Trans>Roles</Trans>}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent={'flex-end'}>
              <Button type={'submit'} size={'small'} variant={'contained'}>
                {initData?.email ? <Trans>Update</Trans> : <Trans>Create</Trans>}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (store: any) => {
  return {
    roles: store.users.roles,
    rolesLoading: store.users.rolesLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({postUser, patchUser, getRoles}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementForm);
