import React, {FC, ReactElement} from 'react';
import {Button} from '@mui/material';

type FormButtonProps = {
  text: ReactElement;
  onClick?: any;
  sx?: any;
  type?: any;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large' | undefined;
  fullWidth?: boolean;
};

const FormButton: FC<FormButtonProps> = (props) => {
  const {
    text,
    sx,
    type,
    onClick,
    size = 'small',
    variant = 'contained',
    disabled = false,
    fullWidth = false,
  } = props;

  return (
    <Button
      fullWidth={fullWidth}
      sx={{'&.MuiButton-sizeSmall': {fontSize: 14}, ...sx}}
      type={type}
      size={size}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default FormButton;
