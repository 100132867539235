import React, {FC, useState} from 'react';
import {Paper, Tab, Tabs} from '@mui/material';
import TabPanel from 'components/TabPanel';
import {renderStruct} from 'components/outputs/structures/utils';

type Section = {
  category: string;
  feature: string;
  name: string;
  type: string;
};

const TabsStruct: FC<any> = (props) => {
  const {data} = props;

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Paper elevation={2} sx={{width: '100%', padding: 2, mb: 5}}>
      <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        {data?.sections?.map((section: Section, index: number) => (
          <Tab key={index} sx={{textTransform: 'none'}} label={section.name} />
        ))}
      </Tabs>
      {data?.sections?.map((section: Section, index: number) => (
        <TabPanel key={index} value={tabValue} index={index} sx={{p: 5, pb: 0}}>
          {renderStruct(section)}
        </TabPanel>
      ))}
    </Paper>
  );
};

export default TabsStruct;
