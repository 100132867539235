import {BaseQueryApi} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {GATEWAY_URL, PUBLIC_API_KEY} from 'constants/config';
import {DownloadsItem} from 'pages/Private/Donwloads/types';

export const downloadsApi = createApi({
  reducerPath: 'downloads',
  baseQuery: fetchBaseQuery({
    baseUrl: GATEWAY_URL,
    prepareHeaders: (
      headers: Headers,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
    ) => {
      headers.set('x-public-api-key', `${PUBLIC_API_KEY}`);
      return headers;
    },
  }),
  tagTypes: ['Downloads'],
  endpoints: (builder) => ({
    downloads: builder.query<{objects: DownloadsItem[]}, void>({
      query: () => '/api/downloads/tree',
      providesTags: ['Downloads'],
    }),
  }),
});

export const {useDownloadsQuery} = downloadsApi;
