import React, {ReactElement} from 'react';
import {Box, Card, Grid} from '@mui/material';
import ADVLogo from 'assets/advacam_logo_light.png';
import ESALogo from 'assets/esa_logo.png';
import TraxLogo from 'assets/traxEngine_logo_text_black.png';

const PublicCard = (props: {children: ReactElement}) => {
  const {children} = props;
  return (
    <Grid
      container
      item
      direction={'column'}
      alignContent={'center'}
      sx={{position: 'relative', top: '-20px'}}
    >
      <Card
        sx={{
          width: 400,
          maxWidth: 400,
          p: 10,
        }}
        raised={true}
        elevation={5}
        square
      >
        <Box
          sx={{
            pb: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            style={{
              width: '260px',
              maxWidth: '100%',
            }}
            src={TraxLogo}
            alt={'Trax-engine-logo'}
          />
        </Box>
        {children}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 16,
          }}
        >
          <img
            style={{
              width: '150px',
              height: 'auto',
            }}
            src={ADVLogo}
            alt={'advacam-logo'}
          />
          <img
            style={{
              width: '70px',
              height: 'auto',
            }}
            src={ESALogo}
            alt={'esa-logo'}
          />
        </Box>
      </Card>
    </Grid>
  );
};

export default PublicCard;
