import {MockPortMappingType} from 'types/constants';

export const FORCE_NO_AUTH = false;
export const MOCK_API = false;

export const MOCK_PORT_MAPPING: MockPortMappingType = {
  app: 8000,
};

// different localstorage constant names for each project
const LS_PREFIX = 'adv';
export const LS_SESSION_ID_NAME = `${LS_PREFIX}SessionId`;
export const LS_LOGGED_USER = `${LS_PREFIX}LoggedUser`;
export const LS_GLOBAL_SETTINGS = `${LS_PREFIX}GlobalSettings`;
export const LS_DATASET_UPLOAD = `${LS_PREFIX}DatasetUpload`;

export const DRAWER_WIDTH = 220;

export const DATE_TIME_FORMAT = 'HH:mm DD.MM.YYYY';

export const resolveEnv = (envName: string) => {
  if (ENVIRONMENT === 'production') {
    return window._env_[envName];
  }
  return process.env[envName];
};

export const ENVIRONMENT = process.env.NODE_ENV;
export const PUBLIC_API_KEY = resolveEnv('REACT_APP_PUBLIC_API_KEY');
export const GATEWAY_URL = resolveEnv('REACT_APP_GATEWAY_URL');
export const CHUNK_SIZE = resolveEnv('REACT_APP_CHUNK_SIZE') || 1000000; // 1MB
export const API_CMS_URL = resolveEnv('REACT_APP_API_CMS_URL');
export const API_CMS_TOKEN = resolveEnv('REACT_APP_API_CMS_TOKEN');
