import {
  GET_LISTS_FAIL,
  GET_LISTS_REQUEST,
  GET_LISTS_SUCCESS,
  GET_PARTICLES_FAIL,
  GET_PARTICLES_REQUEST,
  GET_PARTICLES_SUCCESS,
  GET_TABS_FAIL,
  GET_TABS_REQUEST,
  GET_TABS_SUCCESS,
} from 'redux/actionTypes';
import {RequestThunkAction} from 'types/general';
import endpoints from 'utils/endpoints';
import {globalApiErrorHandler} from 'utils/functions';
import httpClient from 'utils/httpClient';

export const getOutputTabs = (datasetId: number, processingId: number): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_TABS_REQUEST});
    return (
      httpClient
        .get(`${endpoints.outputs(datasetId, processingId)}/tabs`)
        .then((res) => {
          dispatch({type: GET_TABS_SUCCESS, data: res.data});
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((err) => {
          dispatch({type: GET_TABS_FAIL});
        })
    );
  };
};

export const getParticleClasses = (datasetId: number, processingId: number): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_PARTICLES_REQUEST});
    return (
      httpClient
        .get(`${endpoints.outputs(datasetId, processingId)}/particle-classes`)
        .then((res) => {
          dispatch({type: GET_PARTICLES_SUCCESS, data: res.data});
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((err) => {
          dispatch({type: GET_PARTICLES_FAIL});
        })
    );
  };
};

export const getDataForSpecifiedTab = (
  datasetId: number,
  processingId: number,
  data: {tab_ref: string}
): RequestThunkAction => {
  return (dispatch) => {
    dispatch({type: GET_LISTS_REQUEST});
    return httpClient
      .post(`${endpoints.outputs(datasetId, processingId)}/lists`, data)
      .then((res) => {
        dispatch({type: GET_LISTS_SUCCESS, data: res.data});
        return res.data;
      })
      .catch((err) => {
        dispatch({type: GET_LISTS_FAIL});
        return globalApiErrorHandler(err);
      });
  };
};

export const getBitmaps = (
  datasetId: number,
  processingId: number,
  category: string,
  feature: string,
  sub_plot: string | null
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    const data: {feature: string; category: string; sub_plot?: string} = {
      category,
      feature,
    };
    if (sub_plot) {
      data.sub_plot = sub_plot;
    }
    return httpClient
      .post(`${endpoints.outputs(datasetId, processingId)}/bitmaps`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};

export const getTimePlots = (
  datasetId: number,
  processingId: number,
  data: {feature: string; category: string; sub_plots: string[]}
): RequestThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.outputs(datasetId, processingId)}/time-plots`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return globalApiErrorHandler(err);
      });
  };
};
