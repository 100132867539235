import axios from 'axios';
import {LS_SESSION_ID_NAME, PUBLIC_API_KEY} from 'constants/config';
import {getItemFromStorage} from 'utils/functions';

// `withCredentials` indicates whether or not cross-site Access-Control requests
// should be made using credentials , default = false
axios.defaults.withCredentials = true;

if (PUBLIC_API_KEY) {
  axios.defaults.headers.common['X-PUBLIC-API-KEY'] = PUBLIC_API_KEY;
}

axios.interceptors.request.use((config: any) => {
  const configCopy = config;
  const token = getItemFromStorage(LS_SESSION_ID_NAME);

  if (token) {
    configCopy.headers['session-id'] = token;
  }

  return configCopy;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const post = (
  url: string,
  data = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  return axios.post(url, data, config);
};

const get = (
  url: string,
  params = {},
  config: any = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  const configCopy = config;
  configCopy.params = params;

  return axios.get(url, configCopy);
};

const put = (url: string, data = {}, config = {}) => {
  return axios.put(url, data, config);
};

const patch = (url: string, data = {}, config = {}) => {
  return axios.patch(url, data, config);
};

const del = (url: string, config = {}) => {
  return axios.delete(url, config);
};

const httpClient = {
  post,
  get,
  put,
  del,
  patch,
};

export default httpClient;
