import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {getPlots} from 'redux/actions/datasetAction';
import {Grid} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import HistogramStruct from 'components/outputs/structures/HistogramStruct';

const HistogramTab: FC<any> = (props) => {
  const {getPlots} = props;

  const [plotsLoading, setPlotsLoading] = useState(false);
  const [plots, setPlots] = useState([]);

  const {id, pId} = useParams();

  useEffect(() => {
    setPlotsLoading(true);
    getPlots(id, pId, 'histograms__1d_histogram,histograms__2d_histogram')
      .then((res: any) => {
        setPlots(res);
      })
      .finally(() => {
        setPlotsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container sx={{position: 'relative'}}>
      <LoadingMask loading={plotsLoading} containerStyle={{width: '100%'}}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <HistogramStruct plots={plots} graphId={'histogram-one'} plotIndex={0} />
          </Grid>
          <Grid item xs={6}>
            <HistogramStruct plots={plots} graphId={'histogram-two'} plotIndex={1} />
          </Grid>
        </Grid>
      </LoadingMask>
    </Grid>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (store: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getPlots,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HistogramTab);
