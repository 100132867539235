import {BaseQueryApi} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {API_CMS_TOKEN, API_CMS_URL} from 'constants/config';
import {
  ListResponse,
  NavigationDetail,
  NavigationList,
  PageDetail,
  SingleResponse,
} from 'pages/Private/Help/services/spina/types';

export const navigationApi = createApi({
  reducerPath: 'spina-cms',
  baseQuery: fetchBaseQuery({
    baseUrl: API_CMS_URL,
    prepareHeaders: (
      headers: Headers,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
    ) => {
      headers.set('Authorization', `Token ${API_CMS_TOKEN}`);
      return headers;
    },
  }),
  tagTypes: ['Navigations', 'Navigation', 'Page'],
  endpoints: (builder) => ({
    navigations: builder.query<ListResponse<NavigationList>, void>({
      query: () => '/navigations.json',
      providesTags: ['Navigations'],
    }),
    navigation: builder.query<SingleResponse<NavigationDetail>, number>({
      query: (id) => `/navigations/${id}.json`,
      providesTags: ['Navigation'],
    }),
    page: builder.query<SingleResponse<PageDetail>, number>({
      query: (id) => `/pages/${id}.json`,
      providesTags: (result, error, arg) => [{type: 'Page' as const, id: arg}],
    }),
  }),
});

export const {useNavigationsQuery, useNavigationQuery, usePageQuery} = navigationApi;
