import React, {FC} from 'react';
import {Grid} from '@mui/material';
import DatasetDetailCard from 'components/datasets/DatasetDetailCard';
import DatasetProcessingCard from 'components/datasets/DatasetProcessingCard';
import PrivateBreadcrumbs from 'layouts/Private/PrivateBreadcrumbs';

const DetailDatasetPage: FC<any> = () => {
  return (
    <Grid container item xs={12} sm={12} md={12} lg={11} xl={10}>
      <PrivateBreadcrumbs />

      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DatasetDetailCard />
        </Grid>

        {/* PROCESSING CARD */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DatasetProcessingCard />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailDatasetPage;
