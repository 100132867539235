import React, {FC} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {deleteTag, getTags, postTag} from 'redux/actions/tagAction';
import {Trans} from '@lingui/macro';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Chip, Divider, Grid, IconButton} from '@mui/material';
import AdminTable from 'components/AdminTable';
import CustomTooltip from 'components/CustomTooltip';
import DialogWindow from 'components/DialogWindow';
import FormButton from 'components/formComponents/FormButton';
import SelectField from 'components/formComponents/SelectField';
import TextField from 'components/formComponents/TextField';
import {TAG_COLORS} from 'constants/enums';

const DatasetTagDialog: FC<any> = (props) => {
  const {open, onClose, tagList, tagLoading, getTags, postTag, deleteTag} = props;

  const handleSubmit = (values: {name: string; color: string}, formProps: any) => {
    return postTag(values).then(() => {
      formProps.change('name', '');
      formProps.change('color', '');
      getTags();
    });
  };

  const handleDeleteTag = (row: any) => () => {
    if (row.id) {
      return deleteTag(row.id).then(() => getTags());
    }
  };

  const tagColumns = [
    {
      name: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'color',
      label: <Trans>Color</Trans>,
      render: (val: string) => val && TAG_COLORS.find((col) => col.value === val)?.label,
    },
    {
      name: 'display',
      label: <Trans>Display</Trans>,
      render: (val: any, row: any) => {
        if (row.color) {
          return (
            <Chip
              size={'small'}
              label={row.name}
              style={{
                color: '#ffffff',
                fontSize: 12,
                border: '2px solid ' + TAG_COLORS.find((c) => c.value === row.color)?.color,
                backgroundColor: TAG_COLORS.find((c) => c.value === row.color)?.color + 'dd',
              }}
            />
          );
        }
      },
    },
    {
      name: 'action',
      align: 'right',
      render: (val: any, row: any) => (
        <CustomTooltip title={<Trans>Delete tag</Trans>}>
          <IconButton color={'error'} sx={{p: 0}} onClick={handleDeleteTag(row)}>
            <DeleteOutlineIcon fontSize={'small'} />
          </IconButton>
        </CustomTooltip>
      ),
    },
  ];

  return (
    <DialogWindow
      maxWidth={'sm'}
      fullWidth={true}
      title={<Trans>Manage tags</Trans>}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={handleSubmit}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <Grid container alignItems={'center'} justifyContent={'flex-start'} spacing={5}>
              <Grid item xs={4}>
                <Field
                  required
                  name={'name'}
                  render={(props) => <TextField label={<Trans>Name</Trans>} {...props} />}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  required
                  name={'color'}
                  render={(props) => (
                    <SelectField label={<Trans>Color</Trans>} options={TAG_COLORS} {...props} />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <FormButton
                  fullWidth={true}
                  size={'small'}
                  type={'submit'}
                  sx={{px: 2, mb: 4}}
                  text={<Trans>Create new tag</Trans>}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <AdminTable
                  data={tagList}
                  columns={tagColumns}
                  loading={tagLoading}
                  pagination={false}
                />
              </Grid>
            </Grid>
          </form>
        )}
      />
    </DialogWindow>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTags,
      postTag,
      deleteTag,
    },
    dispatch
  );
};

const mapStateToProps = (store: any) => {
  return {
    tagList: store.tags.tagList,
    tagLoading: store.tags.tagLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetTagDialog);
