import React, {FC} from 'react';
import {CircularProgress, Grid} from '@mui/material';

const Loading: FC = () => {
  return (
    <Grid
      sx={{
        position: 'flex',
        width: 1,
        pointerEvents: 'auto',
        zIndex: 1,
      }}
      container
    >
      <Grid item container xs={12} alignContent={'center'} justifyContent={'center'}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Loading;
