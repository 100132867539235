import React, {FC} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {setPassword} from 'redux/actions/authAction';
import {Trans} from '@lingui/macro';
import {Grid} from '@mui/material';
import HoverTypography from 'components/HoverTypography';
import FormButton from 'components/formComponents/FormButton';
import TextField from 'components/formComponents/TextField';
import {mapErrorsToForm, redirectTo} from 'utils/functions';

const SetPasswordForm: FC<any> = (props) => {
  const {setPassword} = props;

  const {token} = useParams();

  const handleSetPassword = (values: {password: string; 'password-confirm': string}) => {
    if (values.password !== values['password-confirm']) {
      return {'password-confirm': <Trans>Passwords must match</Trans>};
    } else {
      return setPassword({password: values.password}, token)
        .then(() => {
          redirectTo('/datasets');
        })
        .catch((err: any) => {
          return mapErrorsToForm(err);
        });
    }
  };

  const handleRedirectToLogin = () => {
    redirectTo('/datasets');
  };

  return (
    <Form
      onSubmit={handleSetPassword}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <Grid container alignItems={'center'} justifyContent={'flex-start'}>
            <Grid item xs={12}>
              <Field
                name={'password'}
                render={(props) => (
                  <TextField type={'password'} label={<Trans>Password</Trans>} {...props} />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                name={'password-confirm'}
                render={(props) => (
                  <TextField
                    type={'password'}
                    label={<Trans>Password confirmation</Trans>}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{mt: 6}}
            >
              <HoverTypography
                variant={'body2'}
                onClick={handleRedirectToLogin}
                text={<Trans>Back to login page</Trans>}
              />

              <FormButton text={<Trans>Set</Trans>} type={'submit'} />
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({setPassword}, dispatch);
};

export default connect(null, mapDispatchToProps)(SetPasswordForm);
