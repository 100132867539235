import React, {useMemo, useState} from 'react';
import WarningDialogWindow from 'components/WarningDialogWindow';

export const useWarningDialog: () => {
  handleOpenDialog: (row: Record<string, any>) => void;
  handleCloseDialog: () => void;
  Dialog: (props: {onOk: any; message: any}) => JSX.Element;
  dialogData: Record<string, any>;
  handleSetLoading: (loading: boolean) => void;
} = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const handleOpenDialog = (row: Record<string, any>) => {
    setVisible(true);
    setDialogData(row);
  };

  const handleCloseDialog = () => {
    setLoading(false);
    setVisible(false);
    setDialogData({});
  };

  const handleSetLoading = (loading: boolean) => {
    setLoading(loading);
  };

  const Dialog = useMemo(() => {
    const DialogComponent = (props: {onOk: any; message: any}) => {
      const {onOk, message} = props;
      return (
        <WarningDialogWindow
          onClose={handleCloseDialog}
          onOk={onOk}
          open={visible}
          message={message}
          loading={loading}
        />
      );
    };
    DialogComponent.displayName = 'Dialog';
    return DialogComponent;
  }, [visible, loading]);

  return {Dialog, handleOpenDialog, handleCloseDialog, handleSetLoading, dialogData};
};
