import moment from 'moment';
import React, {FC, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {
  deleteDatasetProcessing,
  enqueueDatasetProcessing,
  getDatasetProcessingParams,
  getDatasetProcessings,
} from 'redux/actions/datasetAction';
import {Trans} from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MemoryIcon from '@mui/icons-material/Memory';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import DialogWindow from 'components/DialogWindow';
import LoadingMask from 'components/LoadingMask';
import NewDatasetProcessing from 'components/datasets/NewDatasetProcessing';
import {DATE_TIME_FORMAT} from 'constants/config';
import {useWarningDialog} from 'hooks/useWarningDialog';
import {ProcessingType} from 'types/datasetType';
import {redirectTo} from 'utils/functions';

const DatasetProcessingCard: FC<any> = (props) => {
  const {
    getDatasetProcessings,
    datasetProcessingData,
    datasetProcessingLoading,
    enqueueDatasetProcessing,
    getDatasetProcessingParams,
    processingParamsLoading,
    processingParamsData,
    deleteDatasetProcessing,
  } = props;

  const timerRef = useRef<any>(null);
  let isUseEffectCanceled = false;

  const [openDialog, setOpenDialog] = useState<Record<string, any>>({visible: false, row: null});

  const {
    Dialog: DeleteWarning,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
    handleSetLoading: handleSetLoadingDeleteDialog,
    dialogData: deleteDialogData,
  } = useWarningDialog();

  const {id} = useParams();

  const handleCloseDialog = () => {
    setOpenDialog({visible: false, row: null});
  };

  const handleOpenDialog = (row: any) => {
    setOpenDialog({visible: true, row: row});
  };

  const renderIcon = (proc: ProcessingType) => {
    if (proc.enqueued_at) {
      if (proc.failed_at) {
        return <CloseIcon fontSize={'small'} color={'error'} />;
      } else if (proc.finished_at) {
        return <DoneIcon fontSize={'small'} color={'success'} />;
      } else {
        return <CircularProgress size={20} color="info" />;
      }
    } else {
      return <PendingOutlinedIcon fontSize={'small'} color={'disabled'} />;
    }
  };

  const handleEnqueue = (proc: ProcessingType) => {
    if (proc.id) {
      clearTimeout(timerRef.current);
      return enqueueDatasetProcessing(id, proc.id).then((res: any) => {
        getDatasetProcessings(id);
        repeatFetchOfEnqueue(res);
      });
    }
  };

  const repeatFetchOfEnqueue = (prevRes: any) => {
    if (prevRes.failed_at || prevRes.finished_at) {
      clearTimeout(timerRef.current);
    } else {
      timerRef.current = setTimeout(() => {
        getDatasetProcessings(id).then((res: any) => {
          const lastProcessing = res?.objects?.[0];
          repeatFetchOfEnqueue(lastProcessing);
        });
      }, 7000);
    }
  };

  const fetchDatasetProcessing = () => {
    getDatasetProcessings(id);
  };

  const handleDeleteProcessing = () => {
    if (deleteDialogData?.id) {
      handleSetLoadingDeleteDialog(true);
      return deleteDatasetProcessing(id, deleteDialogData?.id).then(() => {
        handleCloseDeleteDialog();
        fetchDatasetProcessing();
      });
    }
  };

  useEffect(() => {
    getDatasetProcessings(id).then((res: any) => {
      if (!isUseEffectCanceled) {
        const lastProcessing = res?.objects?.[0];
        if (
          lastProcessing.enqueued_at &&
          !(lastProcessing.failed_at || lastProcessing.finished_at)
        ) {
          repeatFetchOfEnqueue(lastProcessing);
        }
      }
    });
    getDatasetProcessingParams();
    return () => {
      isUseEffectCanceled = true;
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <>
      <DialogWindow
        maxWidth={'md'}
        fullWidth={true}
        title={
          openDialog.row ? (
            <Trans>Edit dataset processing</Trans>
          ) : (
            <Trans>New dataset processing</Trans>
          )
        }
        open={openDialog.visible}
        onClose={handleCloseDialog}
      >
        <NewDatasetProcessing
          fetchDatasetProcessing={fetchDatasetProcessing}
          onClose={handleCloseDialog}
          initData={openDialog?.row}
        />
      </DialogWindow>

      <DeleteWarning
        onOk={handleDeleteProcessing}
        message={<Trans>Delete processing {`Run ${deleteDialogData.runNumber}`}</Trans>}
      />

      <Card elevation={2} sx={{p: 2.5, position: 'relative'}}>
        <LoadingMask loading={datasetProcessingLoading}>
          <>
            <Grid container item xs={12} justifyContent={'space-between'} sx={{pb: 1.25}}>
              <Grid item container xs={'auto'} alignItems={'center'} sx={{height: 29}}>
                <MemoryIcon fontSize={'small'} sx={{mr: 2.5}} />
                <Typography variant={'h6'}>
                  <Trans>Processing</Trans>
                </Typography>
              </Grid>
              <Grid item container xs={'auto'} alignItems={'center'} justifyContent={'flex-end'}>
                <Button
                  onClick={() => handleOpenDialog(null)}
                  variant={'contained'}
                  size={'small'}
                  color={'success'}
                  sx={{fontSize: 12, lineHeight: 1, height: 26}}
                >
                  <Trans>New run</Trans>
                </Button>
              </Grid>
            </Grid>

            <Divider sx={{mb: 1.25, width: '100%'}} />

            <Grid container item xs={12} sx={{pt: 1.25}}>
              {datasetProcessingData?.length ? (
                datasetProcessingData?.map((proc: ProcessingType, index: number) => {
                  const processingParams = proc.params;

                  return (
                    <Accordion
                      key={index}
                      sx={{width: '100%', mt: index ? 2.5 : 0}}
                      elevation={2}
                      disableGutters={true}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container justifyContent={'space-between'}>
                          <Grid item container xs={'auto'} alignItems={'center'}>
                            {renderIcon(proc)}
                            <Typography variant={'body2'} sx={{alignSelf: 'center', pl: 1.25}}>
                              <Trans>Run {datasetProcessingData?.length - index}</Trans>
                            </Typography>
                          </Grid>
                          <Grid item container xs={'auto'} alignItems={'center'}>
                            {!proc.enqueued_at && (
                              <CustomTooltip title={<Trans>Enqueue</Trans>}>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEnqueue(proc);
                                  }}
                                  variant={'contained'}
                                  size={'small'}
                                  color={'success'}
                                  sx={{
                                    fontSize: 12,
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 'unset',
                                    p: 1,
                                    mr: 2.5,
                                  }}
                                >
                                  <PlayArrowIcon
                                    sx={{p: 0, color: '#ffffff', width: 18, height: 18}}
                                    fontSize={'small'}
                                  />
                                </Button>
                              </CustomTooltip>
                            )}

                            {proc.finished_at ? (
                              <CustomTooltip title={<Trans>Show results</Trans>}>
                                <Button
                                  onClick={() =>
                                    redirectTo(
                                      `/dataset/${id}/processing/${proc.id}/result?run=${
                                        datasetProcessingData?.length - index
                                      }`
                                    )
                                  }
                                  variant={'contained'}
                                  size={'small'}
                                  color={'info'}
                                  sx={{
                                    fontSize: 12,
                                    display: 'flex',
                                    alignItems: 'center',
                                    lineHeight: 1,
                                    height: 26,
                                    mr: 2.5,
                                  }}
                                >
                                  <Trans>Results</Trans>
                                </Button>
                              </CustomTooltip>
                            ) : (
                              !proc.enqueued_at && (
                                <CustomTooltip title={<Trans>Edit</Trans>}>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenDialog(proc);
                                    }}
                                    variant={'contained'}
                                    size={'small'}
                                    color={'info'}
                                    sx={{
                                      fontSize: 12,
                                      display: 'flex',
                                      alignItems: 'center',
                                      minWidth: 'unset',
                                      p: 1,
                                      mr: 2.5,
                                    }}
                                  >
                                    <EditIcon
                                      sx={{p: 0, color: '#ffffff', width: 18, height: 18}}
                                      fontSize={'small'}
                                    />
                                  </Button>
                                </CustomTooltip>
                              )
                            )}
                            <CustomTooltip title={<Trans>Delete processing</Trans>}>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOpenDeleteDialog({
                                    ...proc,
                                    runNumber: datasetProcessingData?.length - index,
                                  });
                                }}
                                size={'small'}
                                color={'error'}
                              >
                                <DeleteOutlineIcon fontSize={'small'} />
                              </IconButton>
                            </CustomTooltip>
                          </Grid>
                        </Grid>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Divider sx={{mb: 2.5}} />
                        <Stepper
                          alternativeLabel
                          activeStep={2}
                          sx={{width: '100%', pb: 5, px: 5, pt: 1}}
                        >
                          <Step>
                            <StepLabel
                              color="primary"
                              icon={
                                proc?.enqueued_at ? (
                                  proc?.started_at ? (
                                    <DoneIcon fontSize={'small'} color={'success'} />
                                  ) : (
                                    <CircularProgress size={20} color="info" />
                                  )
                                ) : (
                                  <PendingOutlinedIcon fontSize={'small'} color={'disabled'} />
                                )
                              }
                            >
                              <Typography variant={'body2'}>
                                {proc?.enqueued_at ? (
                                  <Trans>enqueued</Trans>
                                ) : (
                                  <Trans>not enqueued</Trans>
                                )}
                              </Typography>
                              <Typography variant={'body2'} sx={{fontWeight: 500}}>
                                {proc?.enqueued_at
                                  ? moment(proc?.enqueued_at).format(DATE_TIME_FORMAT)
                                  : ''}
                              </Typography>
                            </StepLabel>
                          </Step>
                          <Step>
                            <StepLabel
                              color="primary"
                              icon={
                                proc?.started_at ? (
                                  proc?.finished_at || proc?.failed_at ? (
                                    <DoneIcon fontSize={'small'} color={'success'} />
                                  ) : (
                                    <CircularProgress size={20} color="info" />
                                  )
                                ) : (
                                  <PendingOutlinedIcon fontSize={'small'} color={'disabled'} />
                                )
                              }
                            >
                              <Typography variant={'body2'}>
                                {proc?.started_at ? (
                                  <Trans>started</Trans>
                                ) : (
                                  <Trans>not started</Trans>
                                )}
                              </Typography>
                              <Typography variant={'body2'} sx={{fontWeight: 500}}>
                                {proc?.started_at
                                  ? moment(proc?.started_at).format(DATE_TIME_FORMAT)
                                  : ''}
                              </Typography>
                            </StepLabel>
                          </Step>
                          <Step>
                            <StepLabel
                              color="primary"
                              icon={
                                proc?.finished_at ? (
                                  <DoneIcon fontSize={'small'} color={'success'} />
                                ) : proc?.failed_at ? (
                                  <CloseIcon fontSize={'small'} color={'error'} />
                                ) : (
                                  <PendingOutlinedIcon fontSize={'small'} color="disabled" />
                                )
                              }
                            >
                              <Typography variant={'body2'}>
                                {proc?.finished_at ? (
                                  <Trans>finished</Trans>
                                ) : proc?.failed_at ? (
                                  <Trans>failed</Trans>
                                ) : (
                                  <Trans>not finished</Trans>
                                )}
                              </Typography>
                              <Typography variant={'body2'} sx={{fontWeight: 500}}>
                                {proc?.finished_at
                                  ? moment(proc?.finished_at).format(DATE_TIME_FORMAT)
                                  : proc?.failed_at
                                    ? moment(proc?.failed_at).format(DATE_TIME_FORMAT)
                                    : ''}
                              </Typography>
                            </StepLabel>
                          </Step>
                        </Stepper>

                        <Divider sx={{mb: 2.5}} />

                        <Grid container item xs={12} sx={{position: 'relative', minHeight: 40}}>
                          <LoadingMask
                            loading={processingParamsLoading}
                            containerStyle={{width: '100%'}}
                          >
                            <>
                              {processingParams ? (
                                processingParams.map(
                                  (param: {ref: string; value: string}, index: number) => (
                                    <Grid container item xs={12} key={index}>
                                      <Grid item xs={8} sm={8} md={8} lg={7} sx={{pr: 1}}>
                                        <Typography variant={'body2'} sx={{fontSize: 13}}>
                                          {param.ref}:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4} sm={4} md={4} lg={5}>
                                        <Typography variant={'body2'} sx={{fontSize: 13}}>
                                          {param?.value?.toString()}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )
                                )
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography color="text.disabled" variant={'body2'}>
                                    No processing params
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          </LoadingMask>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <Typography
                    variant={'body1'}
                    color={'text.disabled'}
                    align={'center'}
                    sx={{my: 2}}
                  >
                    <Trans>No dataset processing</Trans>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        </LoadingMask>
      </Card>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getDatasetProcessings,
      enqueueDatasetProcessing,
      getDatasetProcessingParams,
      deleteDatasetProcessing,
    },
    dispatch
  );
};

const mapStateToProps = (store: any) => {
  return {
    detailLoading: store.datasets.detailLoading,
    detailData: store.datasets.detailData,
    datasetProcessingData: store.datasets.datasetProcessingData,
    datasetProcessingLoading: store.datasets.datasetProcessingLoading,
    processingParamsLoading: store.datasets.processingParamsLoading,
    processingParamsData: store.datasets.processingParamsData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetProcessingCard);
