import React, {FC, ReactElement, useId} from 'react';
import {FieldRenderProps} from 'react-final-form';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

interface SelectFieldProps extends FieldRenderProps<string> {
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  label: ReactElement | string;
  options: any[];
  disabled?: boolean;
  loading?: boolean;
  helperText?: any;
}

const SelectField: FC<SelectFieldProps> = (props) => {
  const {helperText, variant = 'standard', input, meta, label, options, loading, disabled} = props;

  const id = useId();

  const hasError = meta.touched && ((meta.submitError && meta.submitFailed) || !!meta.error);
  const error = meta.submitError;

  return (
    <FormControl disabled={meta.submitting} error={hasError} variant={variant} fullWidth={true}>
      <InputLabel sx={{fontSize: 14}} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        id={id}
        {...input}
        sx={{height: 33, minHeight: 33, fontSize: 14}}
        endAdornment={
          loading && (
            <InputAdornment position="end">
              <CircularProgress size={20} color="primary" sx={{mr: 8}} />
            </InputAdornment>
          )
        }
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText sx={{minHeight: 24, fontSize: 11}}>
        {(hasError && error) || helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default SelectField;
