import React, {ReactElement} from 'react';
import {Typography} from '@mui/material';

type HoverTypographyProps = {
  text: ReactElement;
  onClick: any;
  color?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'error';
  variant?:
    | 'button'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'subtitle2'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6';
};

const HoverTypography = (props: HoverTypographyProps) => {
  const {text, onClick, color = 'primary', variant = 'body2'} = props;
  return (
    <Typography
      variant={variant}
      sx={{
        cursor: 'pointer',
        color: (theme) => theme.palette?.[color]?.main,
        '&:hover': {color: (theme) => theme.palette?.[color]?.dark},
      }}
      component="span"
      onClick={onClick}
    >
      {text}
    </Typography>
  );
};

export default HoverTypography;
