import React, {ReactElement} from 'react';
import {toast} from 'react-toastify';
import {Trans} from '@lingui/macro';
import {
  ENVIRONMENT,
  GATEWAY_URL,
  LS_LOGGED_USER,
  LS_SESSION_ID_NAME,
  MOCK_API,
  MOCK_PORT_MAPPING,
} from 'constants/config';
import {ResponseError} from 'types/general';
import browserHistory from 'utils/history';

export const getItemFromStorage = (key: string, defaultValue: any = null) => {
  const storageValue = JSON.parse(localStorage.getItem(key) as string);
  return storageValue === null && defaultValue ? defaultValue : storageValue;
};

export const setItemToStorage = (key: string, item: any) => {
  localStorage.setItem(key, JSON.stringify(item));
};

export const redirectTo = (path: string) => {
  browserHistory.push(path);
};

export const getServiceUrl = (name: string) => {
  if (ENVIRONMENT !== 'production' && MOCK_API) {
    return `http://localhost:${MOCK_PORT_MAPPING[name]}`;
  }
  return `${GATEWAY_URL}/api/${name}`;
};

export const fireSuccessToast = (message: ReactElement) => {
  return toast.success(message);
};

export const fireErrorToast = (message: ReactElement) => {
  return toast.error(message, {autoClose: 15000});
};

export const fireWarningToast = (message: ReactElement) => {
  return toast.warning(message);
};

export const fireInfoToast = (message: ReactElement) => {
  return toast.info(message);
};

const loopThroughErrors = (message: ReactElement, errors: any) => {
  if (Array.isArray(errors) && errors?.length !== 0) {
    errors.forEach((err) => {
      fireErrorToast(err?.message || message);
      if (err?.message === 'Session ID not authenticated.') {
        localStorage.removeItem(LS_SESSION_ID_NAME);
        localStorage.removeItem(LS_LOGGED_USER);
        redirectTo('/login');
      }
    });
  } else {
    fireErrorToast(message);
  }
};

export const globalApiErrorHandler = (error: {
  response: {data: any; status?: number};
  message: string;
}) => {
  if (error.response) {
    const {status, data} = error.response;

    switch (status) {
      case 400: // bad request
        loopThroughErrors(<Trans>Bad request</Trans>, data?.errors);
        return Promise.reject(error?.response?.data?.errors);
      case 401: // unauthorized
        loopThroughErrors(<Trans>Unauthorized</Trans>, data?.errors);
        localStorage.removeItem(LS_SESSION_ID_NAME);
        localStorage.removeItem(LS_LOGGED_USER);
        redirectTo('/login');
        break;
      case 403: // forbidden
        loopThroughErrors(<Trans>Forbidden</Trans>, data?.errors);
        return Promise.reject(error?.response?.data?.errors);
      case 404: // not found
        fireErrorToast(<Trans>Not found</Trans>);
        return Promise.reject(error?.response);
      case 422: // unprocessable entity
        return Promise.reject(error?.response?.data?.errors);
      case 500: // internal server error
        fireErrorToast(<Trans>Internal server error</Trans>);
        return Promise.reject(error?.response);
      default:
        // data?.errors
        //   ? loopThroughErrors(<Trans>Bad request</Trans>, data.errors)
        //   : fireErrorToast(<Trans>Unknown server error</Trans>);
        return Promise.reject(error?.response?.data?.errors);
    }
  } else if (error.message === 'Network Error' || error.message === 'Server connection issue') {
    fireErrorToast(<Trans>Server connection issue</Trans>);
  } else {
    fireErrorToast(<Trans>Unknown error</Trans>);
  }
  return false;
};

export const mapErrorsToForm = (err: ResponseError[]) => {
  const parsedError: any = {};
  if (Array.isArray(err)) {
    err.forEach((e) => {
      if (e.source.includes('.')) {
        const errorSplit = e.source.split('.');
        if (Object.prototype.hasOwnProperty.call(parsedError, errorSplit[0])) {
          parsedError[errorSplit[0]][errorSplit[1]] = e.message;
        } else {
          parsedError[errorSplit[0]] = {};
          parsedError[errorSplit[0]][errorSplit[1]] = e.message;
        }
      } else {
        parsedError[e.source] = e.message;
      }
    });
  }
  return parsedError;
};

export const createFile = (data: any, filename: string) => {
  return new File([data], filename, {
    type: 'text/plain',
  });
};
