import {UPDATE_GLOBAL_SETTINGS} from 'redux/actionTypes';
import {LS_GLOBAL_SETTINGS} from 'constants/config';
import {getItemFromStorage, setItemToStorage} from 'utils/functions';

const initState = getItemFromStorage(LS_GLOBAL_SETTINGS, {
  rowsPerPage: 20,
  rowsPerPageOptions: [5, 10, 20, 50],
  currentLanguage: 'en',
});

export const globalSettingReducer = (
  state: {rowsPerPage: number; rowsPerPageOptions: number[]; currentLanguage: string} = initState,
  action: any = null
) => {
  if (action.type === UPDATE_GLOBAL_SETTINGS) {
    const settings = {
      ...state,
      ...action.data,
    };

    setItemToStorage(LS_GLOBAL_SETTINGS, settings);
    return settings;
  }
  return state;
};
