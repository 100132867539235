import {createTheme} from '@mui/material';

const defaultTheme = createTheme({
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'",
    h1: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '2.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '1.7',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '1.3rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '1.1rem',
      lineHeight: 1.4,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontSize: '14px',
      fontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    },
  },
  palette: {
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    primary: {
      light: '#335b6b',
      main: '#003247',
      dark: '#002331',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#cbcccc',
      main: '#BFC0C0',
      dark: '#858686',
      contrastText: '#ffffff',
    },
    success: {
      light: '#dff0d8',
      main: '#4caf50',
      dark: '#3c763d',
      contrastText: '#ffffff',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#ffffff',
    },
    warning: {
      light: '#fcf8e3',
      main: '#ff9800',
      dark: '#8a6d3b',
      contrastText: '#ffffff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#ffffff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#ffffff',
      default: '#fafafa',
    },
  },
  // border radius of input fields and buttons
  shape: {
    borderRadius: 2,
  },
  // spacing sets size of padding and margin
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default defaultTheme;
