import React from 'react';
import SpatialMap from 'components/outputs/SpatialMap';
import HistogramStruct from 'components/outputs/structures/HistogramStruct';
import Table1DStruct from 'components/outputs/structures/Table1DStruct';
import Table2DStruct from 'components/outputs/structures/Table2DStruct';
import TabsStruct from 'components/outputs/structures/TabsStruct';
import TimePlotStruct from 'components/outputs/structures/TimePlotStruct';

export const renderStruct = (record: Record<string, any>, tabRef?: string) => {
  const categoryIncludesRef = record?.category?.includes(tabRef);

  switch (record.type) {
    case '1d_table':
      return <Table1DStruct data={record} />;
    case '2d_table':
      return <Table2DStruct data={record} />;
    case 'tabs':
      return <TabsStruct data={record} />;
    case 'time_plot':
      return <TimePlotStruct data={record} />;
    case '2d_histogram':
      return categoryIncludesRef && <SpatialMap plots={[record]} graphId={record?.feature} />;
    case '1d_histogram':
      return categoryIncludesRef && <HistogramStruct data={record} graphId={record?.feature} />;
    default:
      return;
  }
};

export const renderUnits = (unit: string) => {
  const splitBySpace = unit.split(' ');

  const isMicro = (u: string) => (u === 'uGy' ? 'μGy' : u);

  return splitBySpace.map((part, pI) => {
    if (part.includes('^')) {
      const splitBy = part.split('^');
      if (splitBy.length === 2) {
        return (
          <span key={pI}>
            {isMicro(splitBy[0])}
            <sup>{splitBy[1]}</sup>
            {pI + 1 < splitBySpace.length && ' '}
          </span>
        );
      }
    } else {
      return (
        <span key={pI}>
          {isMicro(part)}
          {pI + 1 < splitBySpace.length && ' '}
        </span>
      );
    }

    return isMicro(unit);
  });
};

export const renderUnitsLabel = (str = '') => {
  if (str) {
    if ((str.includes('^') && str.split('^')?.length === 2) || str.split('^')?.length === 3) {
      const start = str.indexOf('[');
      const end = str.indexOf(']');

      const units = str.substring(start + 1, end);

      const before = str.split('[')?.[0];

      const splitBy = units.split('^');

      if (splitBy.length === 2) {
        return before + '[' + splitBy[0] + splitBy[1].sup() + ']';
      } else {
        const splitBySpace = units.split(' ');
        return (
          before +
          '[' +
          splitBySpace?.map((unit: string) => {
            const splitBy = unit.split('^');
            return splitBy[0] + splitBy[1].sup();
          }) +
          ']'
        ).replaceAll(',', ' ');
      }
    } else {
      return str;
    }
  }
};
