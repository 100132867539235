import React, {FC} from 'react';
import {FormControl, FormControlLabel, FormHelperText, Switch, Typography} from '@mui/material';

const SwitchField: FC<any> = (props) => {
  const {
    variant = 'standard',
    label,
    labelPlacement = 'end',
    input,
    meta,
    helperText,
    size = 'small',
  } = props;

  const hasError = meta.touched && ((meta.submitError && meta.submitFailed) || !!meta.error);
  const error = meta.submitError || meta.error;

  return (
    <FormControl disabled={meta.submitting} error={hasError} fullWidth={true} variant={variant}>
      <FormControlLabel
        control={<Switch {...input} size={size} disableRipple={true} />}
        label={<Typography variant={'body2'}>{label}</Typography>}
        labelPlacement={labelPlacement}
        sx={{ml: -1, minHeight: 33, fontSize: 14}}
      />
      <FormHelperText sx={{minHeight: 24, fontSize: 11}}>
        {(hasError && error) || helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default SwitchField;
