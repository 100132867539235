import App from 'app/App';
import ToastProvider from 'app/ToastProvider';
import catalogEn from 'app/locale/en/messages';
import {en} from 'make-plural';
import React from 'react';
import {FC} from 'react';
import {Provider} from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import {store} from 'redux/store';
import defaultTheme from 'styles/defaultTheme';
import {I18nProvider} from '@lingui/react';
import {ThemeProvider} from '@mui/material';
import i18nProvider from 'utils/i18n';

const AppProvider: FC = () => {
  const activeLang = 'en';

  i18nProvider.load({
    en: catalogEn.messages,
  });

  i18nProvider.loadLocaleData('en', {plurals: en});

  i18nProvider.activate(activeLang);

  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <I18nProvider i18n={i18nProvider}>
          <ToastProvider />
          <App />
        </I18nProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default AppProvider;
