import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {
  getDatasetProcessingParams,
  patchDatasetProcessing,
  postDatasetProcessing,
} from 'redux/actions/datasetAction';
import {Trans} from '@lingui/macro';
import {Step, StepLabel, Stepper} from '@mui/material';
import AdditionalParamsForm from 'components/forms/AdditionalParamsForm';
import NewDatasetProcessingForm from 'components/forms/NewDatasetProcessingForm';
import {AdditionalParamType} from 'types/datasetType';
import {mapErrorsToForm} from 'utils/functions';

const NewDatasetProcessing: FC<any> = (props) => {
  const {
    getDatasetProcessingParams,
    postDatasetProcessing,
    patchDatasetProcessing,
    onClose,
    initData,
    fetchDatasetProcessing,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [processingData, setProcessingData] = useState<any>({});
  const [additionalParams, setAdditionalParams] = useState([]);

  const {id} = useParams();

  const handleSubmit = () => {
    const formData = new FormData();

    additionalParams.forEach((file: AdditionalParamType, index: number) => {
      if (file.data && file.type) {
        formData.append(`additional_params[${index}]file`, file.data);
        formData.append(`additional_params[${index}]type`, file.type);
        if (file.type === 'masking_n' && file.number) {
          formData.append(`additional_params[${index}]number`, file.number);
        }
      }
    });

    if (processingData) {
      const preparedProcessingData: any = [];
      Object.entries(processingData).forEach(([key, value]) => {
        preparedProcessingData.push({ref: key, value: value});
      });
      formData.append('params', `${JSON.stringify(preparedProcessingData)}`);
    }

    if (initData?.id) {
      return patchDatasetProcessing(id, initData?.id, formData)
        .then(() => {
          fetchDatasetProcessing();
          onClose();
        })
        .catch((err: any) => {
          return mapErrorsToForm(err);
        });
    } else {
      return postDatasetProcessing(id, formData)
        .then(() => {
          fetchDatasetProcessing();
          onClose();
        })
        .catch((err: any) => {
          return mapErrorsToForm(err);
        });
    }
  };

  const handleSetProcessingData = (data: any) => {
    setProcessingData((prevState: any) => ({...prevState, ...data}));
  };

  useEffect(() => {
    getDatasetProcessingParams();
    if (initData?.additional_params) {
      setAdditionalParams(initData.additional_params);
    }
  }, []);

  return (
    <>
      <Stepper activeStep={activeStep} sx={{width: '100%', pb: 8, px: 8}}>
        <Step>
          <StepLabel color="primary">
            <Trans>Processing params</Trans>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel color="primary">
            <Trans>Additional params</Trans>
          </StepLabel>
        </Step>
      </Stepper>

      {activeStep === 0 ? (
        <NewDatasetProcessingForm
          initData={initData}
          setProcessingData={handleSetProcessingData}
          setActiveStep={setActiveStep}
        />
      ) : (
        <AdditionalParamsForm
          initData={initData}
          additionalParams={additionalParams}
          setAdditionalParams={setAdditionalParams}
          setActiveStep={setActiveStep}
          handleCreateProcessing={handleSubmit}
        />
      )}
    </>
  );
};

const mapStateToProps = (store: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getDatasetProcessingParams,
      postDatasetProcessing,
      patchDatasetProcessing,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDatasetProcessing);
