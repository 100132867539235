import React, {FC} from 'react';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {changeParticleClassFilter} from 'redux/actions/datasetAction';
import {Trans} from '@lingui/macro';
import {Grid, Typography} from '@mui/material';
import DialogWindow from 'components/DialogWindow';
import GraphCheckBox from 'components/outputs/GraphCheckBox';

const TimePlotSettingDialog: FC<any> = (props) => {
  const {
    open,
    onClose,
    changeParticleClassFilter,
    particleClassesFilters,
    handleChangeScale,
    logScale,
  } = props;

  const handleChangeClassFilter = (val: boolean, name: string) => {
    changeParticleClassFilter(
      particleClassesFilters.map((particleClass: any) => {
        if (String(particleClass.class_num) === String(name)) {
          return {...particleClass, visible: val};
        } else {
          return particleClass;
        }
      })
    );
  };

  return (
    <DialogWindow
      maxWidth={'md'}
      fullWidth={true}
      title={<Trans>Time plot setting</Trans>}
      open={open}
      onClose={onClose}
    >
      <Grid container>
        <Grid container item xs={7} alignContent={'flex-start'}>
          <Typography variant={'body1'}>
            <Trans>Particle classes</Trans>
          </Typography>
          {particleClassesFilters?.map((particleClass: any, index: number) => (
            <Grid item xs={12} key={index}>
              <GraphCheckBox
                label={`${particleClass.class_num} - ${particleClass.class_name}`}
                name={String(particleClass.class_num)}
                checked={particleClass.visible}
                handleChange={handleChangeClassFilter}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={5} alignContent={'flex-start'}>
          <Typography variant={'body1'}>
            <Trans>Scale</Trans>
          </Typography>
          <Grid item xs={12}>
            <GraphCheckBox
              label={<Trans>Logarithmic scale</Trans>}
              checked={logScale}
              handleChange={handleChangeScale}
            />
          </Grid>
        </Grid>
      </Grid>
    </DialogWindow>
  );
};

const mapStateToProps = (store: any) => {
  return {
    particleClassesFilters: store.datasets.particleClassesFilters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      changeParticleClassFilter,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimePlotSettingDialog);
