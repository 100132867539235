import React, {FC, ReactElement} from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';

type GraphCheckBoxProps = {
  label?: ReactElement | string | any;
  name?: any;
  handleChange?: any;
  checked?: boolean;
};

const GraphCheckBox: FC<GraphCheckBoxProps> = (props) => {
  const {label, name, handleChange, checked} = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={{py: 1, px: 3}}
          disableRipple={true}
          size={'small'}
          name={name}
          onChange={(e) => {
            handleChange(e.target.checked, e.target.name);
          }}
          checked={checked}
        />
      }
      sx={{fontSize: 14}}
      disableTypography={true}
      label={label}
      labelPlacement={'end'}
    />
  );
};
export default GraphCheckBox;
