import React, {FC} from 'react';
import {Trans} from '@lingui/macro';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import FormButton from 'components/formComponents/FormButton';
import {CALIBRATION_MATRIX_TYPES} from 'constants/enums';
import {CalibrationMatrixType} from 'types/datasetType';
import {createFile} from 'utils/functions';

const DatasetCalibrationMatricesForm: FC<any> = (props) => {
  const {
    setActiveStep,
    calibrationFiles,
    setCalibrationFiles,
    loading = false,
    edit = false,
  } = props;

  const getFileType = (name: string) => {
    if (name === 'a.txt') {
      return 'a';
    } else if (name === 'b.txt') {
      return 'b';
    } else if (name === 'c.txt') {
      return 'c';
    } else if (name === 't.txt') {
      return 't';
    } else {
      return '';
    }
  };

  const getFileIndex = (state: any) => {
    const indexArray = state.map((rec: any) => rec.index);

    if (!indexArray.includes(0)) {
      return 0;
    } else if (!indexArray.includes(1)) {
      return 1;
    } else if (!indexArray.includes(2)) {
      return 2;
    } else if (!indexArray.includes(3)) {
      return 3;
    }
  };

  const handleUploadCalibrationFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files && Array.from(event.target.files);

    files?.forEach((file: any) => {
      const reader = new FileReader();

      reader.onloadend = function (event: any) {
        if (event.target.readyState !== FileReader.DONE) {
          return;
        }

        setCalibrationFiles((prevState: CalibrationMatrixType[]) => [
          ...prevState,
          {
            data: createFile(event.target.result, file.name),
            filename: file.name,
            type: getFileType(file.name),
            index: getFileIndex(prevState),
          },
        ]);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleRemoveAdditionalFile = (mat: CalibrationMatrixType) => {
    setCalibrationFiles((prevState: CalibrationMatrixType[]) => [
      ...prevState.filter((m: CalibrationMatrixType) => m.index !== mat.index),
    ]);
  };

  const handleChangeMatrixType = (mat: CalibrationMatrixType, e: SelectChangeEvent<unknown>) => {
    setCalibrationFiles((prevState: CalibrationMatrixType[]) => [
      ...prevState.map((file: CalibrationMatrixType) => {
        if (mat.index === file.index) {
          return {...file, type: e.target.value};
        } else if (file.type === e.target.value) {
          return {...file, type: ''};
        } else {
          return file;
        }
      }),
    ]);
  };

  return (
    <>
      <Grid container sx={{position: 'relative', minHeight: 200}} alignItems={'flex-start'}>
        <LoadingMask loading={loading} containerStyle={{width: '100%'}}>
          <Grid container>
            {!edit && (
              <Grid item xs={5}>
                <Typography variant={'h6'}>Calibration matrices upload</Typography>
                <Stack direction="column" alignItems={'flex-start'} sx={{mt: 2.5}}>
                  <FormButton
                    disabled={calibrationFiles?.length >= 4}
                    onClick={() => {
                      // reset file input values
                      const input = document.getElementById('calibration-matrices');

                      input?.setAttribute('type', 'text');
                      input?.setAttribute('type', 'file');

                      input?.click();
                    }}
                    text={<Trans>Upload</Trans>}
                  />

                  {calibrationFiles?.length >= 4 && !edit && (
                    <Typography
                      sx={{mt: 2, mr: 2, fontSize: 12}}
                      variant={'body2'}
                      color={'text.primary'}
                    >
                      <Trans>There can be 4 calibration files at max.</Trans>
                    </Typography>
                  )}
                </Stack>
                <input
                  style={{display: 'none'}}
                  id="calibration-matrices"
                  type={'file'}
                  multiple={true}
                  onChange={(e) => handleUploadCalibrationFile(e)}
                />
              </Grid>
            )}

            <Grid container item xs={7}>
              <Typography variant={'h6'}>Matrices file list</Typography>
              {calibrationFiles?.length ? (
                calibrationFiles
                  ?.sort((a: CalibrationMatrixType, b: CalibrationMatrixType) => a.index - b.index)
                  .map((mat: CalibrationMatrixType, index: number) => (
                    <Grid
                      container
                      item
                      key={index}
                      xs={12}
                      justifyContent={'space-between'}
                      alignItems={'flex-end'}
                      sx={{mb: 2}}
                    >
                      {index !== 0 && (
                        <Grid item xs={12}>
                          <Divider light={true} />
                        </Grid>
                      )}
                      <Grid item xs={'auto'}>
                        <Typography variant={'body2'}>{mat.filename}</Typography>
                      </Grid>
                      <Grid item xs={'auto'} container alignItems={'flex-end'}>
                        <Select
                          size={'small'}
                          variant={'standard'}
                          sx={{height: 33, minHeight: 33, minWidth: 130, fontSize: 14, mr: 2}}
                          onChange={(e) => handleChangeMatrixType(mat, e)}
                          value={mat.type || ''}
                          displayEmpty={true}
                          disabled={edit}
                        >
                          <MenuItem value={''}>
                            <Typography variant={'body2'} color={'text.secondary'}>
                              <em>
                                <Trans>Type</Trans>
                              </em>
                            </Typography>
                          </MenuItem>
                          {CALIBRATION_MATRIX_TYPES?.map((option: any, index: number) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>

                        <IconButton
                          disabled={edit}
                          color={'error'}
                          onClick={() => handleRemoveAdditionalFile(mat)}
                        >
                          <DeleteOutlineIcon fontSize={'small'} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))
              ) : (
                <Grid container item xs={12} alignItems={'center'}>
                  <Typography
                    color={'text.disabled'}
                    variant={'body1'}
                    sx={{width: '100%', py: 10}}
                    align={'center'}
                  >
                    <Trans>No calibration matrix files uploaded</Trans>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </LoadingMask>
      </Grid>

      <Grid sx={{mt: 5, pb: 5}} container item xs={12} justifyContent={'space-between'}>
        <FormButton
          variant={'text'}
          sx={{px: 4}}
          text={<Trans>Back</Trans>}
          onClick={() => setActiveStep(0)}
        />
        <FormButton
          disabled={calibrationFiles.some((mat: CalibrationMatrixType) => mat.type === '')}
          sx={{px: 4}}
          text={<Trans>Continue</Trans>}
          onClick={() => setActiveStep(2)}
        />
      </Grid>
    </>
  );
};

export default DatasetCalibrationMatricesForm;
