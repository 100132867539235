import React, {FC, ReactElement, useId} from 'react';
import {FieldRenderProps} from 'react-final-form';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

interface MultiSelectFieldProps extends FieldRenderProps<string> {
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  label: ReactElement | string;
  options: any[];
  loading?: boolean;
  disabled?: boolean;
  helperText?: any;
}

const MultiSelectField: FC<MultiSelectFieldProps> = (props) => {
  const {variant = 'standard', input, meta, label, options, loading, disabled, helperText} = props;

  const id = useId();

  const hasError = meta.touched && ((meta.submitError && meta.submitFailed) || !!meta.error);
  const error = meta.submitError;

  return (
    <FormControl disabled={meta.submitting} error={hasError} variant={variant} fullWidth={true}>
      <InputLabel sx={{fontSize: 14}} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        {...input}
        disabled={loading || disabled}
        id={id}
        multiple
        value={[...input.value]}
        renderValue={(selected) => {
          return (
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
              {selected?.map((value) => {
                return (
                  <Chip
                    sx={{fontSize: 12}}
                    size={'small'}
                    key={value}
                    label={options.find((option) => option.value === value)?.label}
                  />
                );
              })}
            </Box>
          );
        }}
        endAdornment={
          loading && (
            <InputAdornment position="end">
              <CircularProgress size={20} color="primary" sx={{mr: 8}} />
            </InputAdornment>
          )
        }
        sx={{height: 33, minHeight: 33, fontSize: 14}}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText sx={{minHeight: 24, fontSize: 11}}>
        {' '}
        {(hasError && error) || helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default MultiSelectField;
