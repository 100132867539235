import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {getCurrentUser} from 'redux/actions/authAction';
import {updateBreadcrumbs} from 'redux/actions/breadcrumbsAction';
import {Grid} from '@mui/material';
import PrivateBreadcrumbs from 'layouts/Private/PrivateBreadcrumbs';

const UserProfilePage: FC<any> = (props) => {
  const {updateBreadcrumbs, getCurrentUser} = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    getCurrentUser().then((res: any) => setCurrentUser(res));
    updateBreadcrumbs({'user-profile': {}});
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container>
      <PrivateBreadcrumbs />
    </Grid>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (store: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getCurrentUser, updateBreadcrumbs}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
