// @ts-ignore
import download from 'downloadjs';
import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {updateBreadcrumbs} from 'redux/actions/breadcrumbsAction';
import {
  getDataset,
  getDatasetProcessing,
  getProcessingOutputsDownload,
  getProcessingSamplingList,
} from 'redux/actions/datasetAction';
import {getOutputTabs} from 'redux/actions/outputAction';
import {Trans} from '@lingui/macro';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {Box, Grid, Tab, Tabs} from '@mui/material';
import LoadingMask from 'components/LoadingMask';
import TabPanel from 'components/TabPanel';
import FormButton from 'components/formComponents/FormButton';
import FrameViewerTab from 'components/outputs/FrameViewerTab';
import HistogramTab from 'components/outputs/HistogramTab';
import SpatialMapTab from 'components/outputs/SpatialMapTab';
import TabWrapper from 'components/outputs/TabWrapper';
import TableOutputAndTimePlots from 'components/outputs/TableOutputAndTimePlots';
import BitmapStruct from 'components/outputs/structures/BitmapStruct';
import {DatasetResType, ProcessingResType} from 'types/datasetType';
import PrivateBreadcrumbs from 'layouts/Private/PrivateBreadcrumbs';

type ProcessingResultPageProps = {
  updateBreadcrumbs: (obj: Record<string, any>) => void;
  breadcrumbs: Record<string, any>;
  getDataset: any;
  getDatasetProcessing: any;
  getProcessingSamplingList: any;
  getOutputTabs: any;
  getProcessingOutputsDownload: any;
  samplingList: any;
  tabs: any;
  tabsLoading: boolean;
};

const ProcessingResultPage: FC<ProcessingResultPageProps> = (props) => {
  const {
    updateBreadcrumbs,
    breadcrumbs,
    getDataset,
    getDatasetProcessing,
    getProcessingSamplingList,
    getOutputTabs,
    tabs,
    tabsLoading,
    getProcessingOutputsDownload,
  } = props;

  const {id, pId} = useParams();

  const location = useLocation();

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDownloadOutput = () => {
    return getProcessingOutputsDownload(id, pId).then(async (res: any) => {
      download(res.data, 'Output.zip', res.headers['content-type']);
    });
  };

  useEffect(() => {
    if (breadcrumbs?.dataset?.id !== pId) {
      getDataset(id).then((res: DatasetResType) => {
        updateBreadcrumbs({dataset: {id: res.id, name: res.name}});
      });
    }

    getDatasetProcessing(id, pId).then((res: ProcessingResType) => {
      updateBreadcrumbs({
        processing: {id: res.id, name: `Run ${location?.search?.split('=')?.[1]}`},
      });
    });

    // get sampling list used for table output and time plot
    getProcessingSamplingList(id, pId);

    getOutputTabs(id, pId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={11} sx={{position: 'relative'}}>
      <PrivateBreadcrumbs
        actions={
          <FormButton
            sx={{px: 4, mr: 4}}
            text={
              <Grid container>
                <FileDownloadOutlinedIcon fontSize={'small'} sx={{mr: 2}} />
                <Trans>Output</Trans>
              </Grid>
            }
            onClick={handleDownloadOutput}
          />
        }
      />
      <LoadingMask loading={tabsLoading} containerStyle={{width: '100%'}}>
        <Box sx={{width: '100%'}}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs?.map((tab: any, index: number) => (
                <Tab sx={{textTransform: 'none'}} key={index} label={tab.name} />
              ))}
            </Tabs>
          </Box>

          {tabs?.map((tab: any, index: number) => (
            <TabPanel key={index} value={tabValue} index={index}>
              {tab.ref === 'pregenerated_plots' ? (
                <BitmapStruct />
              ) : tab.ref === 'spatial_maps' ? (
                <SpatialMapTab />
              ) : tab.ref === 'histograms' ? (
                <HistogramTab />
              ) : tab.ref === 'basic' ? (
                <TableOutputAndTimePlots />
              ) : tab.ref === 'frame_viewer' ? (
                <FrameViewerTab />
              ) : (
                <TabWrapper tabRef={tab.ref} />
              )}
            </TabPanel>
          ))}
        </Box>
      </LoadingMask>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      updateBreadcrumbs,
      getDataset,
      getDatasetProcessing,
      getProcessingSamplingList,
      getOutputTabs,
      getProcessingOutputsDownload,
    },
    dispatch
  );
};

const mapStateToProps = (store: any) => {
  return {
    breadcrumbs: store.breadcrumbs,
    samplingList: store.datasets.samplingList,
    tabs: store.outputs.tabs,
    tabsLoading: store.outputs.tabsLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingResultPage);
