import React, {FC} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {forgottenPassword} from 'redux/actions/authAction';
import {Trans} from '@lingui/macro';
import {Grid} from '@mui/material';
import FormButton from 'components/formComponents/FormButton';
import TextField from 'components/formComponents/TextField';
import {mapErrorsToForm} from 'utils/functions';

const ForgottenPasswordForm: FC<any> = (props) => {
  const {forgottenPassword, onClose} = props;

  const handleForgottenPassword = (values: {email: string}) => {
    return forgottenPassword(values)
      .then(() => {
        onClose();
      })
      .catch((err: any) => {
        return mapErrorsToForm(err);
      });
  };

  return (
    <Form
      onSubmit={handleForgottenPassword}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <Grid container alignItems={'center'} justifyContent={'flex-start'}>
            <Grid item xs={12}>
              <Field
                name={'email'}
                render={(props) => <TextField label={<Trans>E-mail</Trans>} {...props} />}
              />
            </Grid>

            <Grid item xs={12} container justifyContent={'flex-end'}>
              <FormButton sx={{mt: 4, px: 4}} text={<Trans>Send</Trans>} type={'submit'} />
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({forgottenPassword}, dispatch);
};

export default connect(null, mapDispatchToProps)(ForgottenPasswordForm);
