import React, {FC, ReactElement} from 'react';
import {Trans} from '@lingui/macro';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Button, DialogActions, Stack} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingMask from 'components/LoadingMask';

type WarningDialogWindowProps = {
  open: boolean;
  onClose: any;
  onOk: any;
  message?: ReactElement;
  title?: ReactElement;
  closeText?: ReactElement;
  okText?: ReactElement;
  loading?: boolean;
};

const WarningDialogWindow: FC<WarningDialogWindowProps> = (props) => {
  const {
    open,
    title = <Trans>Are you sure?</Trans>,
    onClose,
    closeText = <Trans>No</Trans>,
    onOk,
    okText = <Trans>Yes</Trans>,
    message,
    loading = false,
  } = props;

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth={true}>
      <LoadingMask loading={loading} containerStyle={{width: '100%'}}>
        <>
          <DialogTitle>
            <Stack direction={'row'} alignItems="center">
              <WarningAmberIcon color={'warning'} fontSize={'large'} sx={{mr: 4}} />
              {title}
            </Stack>
          </DialogTitle>
          <DialogContent sx={{textAlign: 'center'}}>{message}</DialogContent>
          <DialogActions sx={{px: 6, pb: 4, pt: 0}}>
            <Button size={'small'} onClick={onClose} variant={'text'}>
              {closeText}
            </Button>
            <Button disabled={loading} size={'small'} variant={'contained'} onClick={onOk}>
              {okText}
            </Button>
          </DialogActions>
        </>
      </LoadingMask>
    </Dialog>
  );
};

export default WarningDialogWindow;
