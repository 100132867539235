export function makeCoordValues(arrayOfValues: any[]) {
  const coords: any = {};
  const fractionDigits = 10;

  arrayOfValues.forEach((values) => {
    if (values.length === 3) {
      if (!coords[values[0].toFixed(fractionDigits)]) {
        coords[values[0].toFixed(fractionDigits)] = {};
      }
      coords[values[0].toFixed(fractionDigits)][values[1].toFixed(fractionDigits)] = values[2];
    }
  });
  return coords;
}

export function makeArray(
  minX: number,
  maxX: number,
  minY: number,
  maxY: number,
  xSize: number,
  ySize: number,
  fill: any = {}
) {
  const w = Math.abs(minX - maxX) * (1 / xSize);
  const h = Math.abs(minY - maxY) * (1 / ySize);
  const arr: any = [];

  for (let i = 0; i < h; i++) {
    arr[i] = [];
    for (let j = 0; j < w; j++) {
      arr[i][j] = 0;
    }
  }

  function calculateIndex(value: number, min: number, size: number) {
    const offset = min < 0 ? Math.abs(min) * (1 / size) : 0;
    return (value * (1 / size) + offset).toFixed(0);
  }

  Object.entries(fill).forEach((entry) => {
    const [x, yValues] = entry;
    const xIndex = calculateIndex(parseFloat(x), minX, xSize);

    Object.entries(yValues as object).forEach((subEntry) => {
      const [y, value] = subEntry;
      const yIndex = calculateIndex(parseFloat(y), minY, ySize);

      if (arr[yIndex]) {
        arr[yIndex][xIndex] = value;
      }
    });
  });

  return arr;
}

export function getLowestAndHeightsValuesForAxis(data: any[]) {
  const values = {
    x: {
      min: 0,
      max: 0,
    },
    y: {
      min: 0,
      max: 0,
    },
  };

  data.forEach((row) => {
    const x = row[0];
    const y = row[1];
    const z = row[2];
    if (z !== 0) {
      if (x < values.x.min) {
        values.x.min = x;
      }

      if (x > values.x.max) {
        values.x.max = x;
      }

      if (y < values.y.min) {
        values.y.min = y;
      }

      if (y > values.y.max) {
        values.y.max = y;
      }
    }
  });

  return values;
}

const convertDataToLog = (data: [number[]]) => {
  return data?.map((row: number[]) => row.map((rec) => (rec > 0 ? Math.log10(rec) : 0)));
};

const getMaxVal = (data: number[][]) => {
  return Math.max(...(data ?? []).map((row: number[]) => Math.max(...row)));
};

const getTickIntervals = (data: number[][]) => {
  const maxVal = getMaxVal(data);
  const maxValExp = 10 ** maxVal;
  const numOfDigitsLen = maxValExp.toFixed(0)?.length;
  const tickInterval = [];
  const tickText = [];

  for (let i = 0; i < numOfDigitsLen; i++) {
    const exp = 10 ** i;
    tickInterval.push(Math.log10(exp));
    tickText.push(exp);
    if (i + 1 === numOfDigitsLen) {
      if (Math.log10(exp * 2) < maxVal) {
        tickInterval.push(Math.log10(exp * 2));
        tickText.push(2);
      }
      if (Math.log10(exp * 5) < maxVal) {
        tickInterval.push(Math.log10(exp * 5));
        tickText.push(5);
      }
    } else {
      tickInterval.push(Math.log10(exp * 2));
      tickText.push(2);

      tickInterval.push(Math.log10(exp * 5));
      tickText.push(5);
    }
  }
  return [tickInterval, tickText];
};

export const getHistogramData = (data: any, isLogScaleZ: boolean) => {
  if (data) {
    let graphData = [];

    if (data.data_coord) {
      graphData = makeArray(
        data?.axis?.x_min,
        data?.axis?.x_max,
        data?.axis?.y_min,
        data?.axis?.y_max,
        data?.axis?.x_bin_size,
        data?.axis?.y_bin_size,
        makeCoordValues(data.data_coord)
      );
    }

    if (data.data_bitmap) {
      graphData = data.data_bitmap;
    }

    const logGraphData = convertDataToLog(graphData);

    return isLogScaleZ
      ? data?.axis?.x_bin_bounds && data?.axis?.y_bin_bounds
        ? {
            colorscale: 'YlGnBu',
            type: 'heatmap',
            z: logGraphData,
            customdata: graphData,
            x: data?.axis?.x_bin_bounds.map((b: string) => Number(b)),
            y: data?.axis?.y_bin_bounds.map((b: string) => Number(b)),
            colorbar: {
              tick0: 0,
              tickmode: 'array',
              tickvals: getTickIntervals(logGraphData)[0],
              ticktext: getTickIntervals(logGraphData)[1],
            },
            hovertemplate: 'X: %{x}<br>' + 'Y: %{y}<br>' + 'z: %{customdata}' + '<extra></extra>',
            nbinsx: data?.axis?.x_bins_count,
            nbinsy: data?.axis?.y_bins_count,
          }
        : {
            colorscale: 'YlGnBu',
            type: 'heatmap',
            z: logGraphData,
            customdata: graphData,
            colorbar: {
              title: data?.axis?.z_label ? data.axis.z_label : 'Z',
              tickmode: 'array',
              tickvals: getTickIntervals(logGraphData)[0],
              ticktext: getTickIntervals(logGraphData)[1],
            },
            hovertemplate: 'X: %{x}<br>' + 'Y: %{y}<br>' + 'z: %{customdata}' + '<extra></extra>',
            x0: data?.axis?.x_min,
            dx: data?.axis?.x_bin_size,
            y0: data?.axis?.y_min,
            dy: data?.axis?.y_bin_size,
          }
      : data?.axis?.x_bin_bounds && data?.axis?.y_bin_bounds
        ? {
            colorscale: 'YlGnBu',
            type: 'heatmap',
            z: graphData,
            x: data?.axis?.x_bin_bounds.map((b: string) => Number(b)),
            y: data?.axis?.y_bin_bounds.map((b: string) => Number(b)),
            nbinsx: data?.axis?.x_bins_count,
            nbinsy: data?.axis?.y_bins_count,
            colorbar: {
              title: data?.axis?.z_label ? data.axis.z_label : 'Z',
            },
          }
        : {
            colorscale: 'YlGnBu',
            type: 'heatmap',
            z: graphData,
            x0: data?.axis?.x_min,
            dx: data?.axis?.x_bin_size,
            y0: data?.axis?.y_min,
            dy: data?.axis?.y_bin_size,
            colorbar: {
              title: data?.axis?.z_label ? data.axis.z_label : 'Z',
            },
          };
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPlotLayout = (graphId: string, data?: any, isLogScaleZ?: boolean) => {
  let boxWidth = document.getElementById(graphId)?.clientWidth;
  if (boxWidth) {
    boxWidth = boxWidth > 600 ? 600 : boxWidth;
  } else {
    boxWidth = 600;
  }

  const plotLayout = {
    width: boxWidth,
    height: boxWidth * 0.83,
    margin: {
      l: 60,
      r: 20,
      t: 40,
      b: 40,
    },
    xaxis: {
      title: {
        text: data?.axis?.x_label ? data?.axis?.x_label : 'X',
      },
    },
    yaxis: {
      title: {
        text: data?.axis?.x_label ? data?.axis?.y_label : 'Y',
      },
    },
    legend: {
      yanchor: 'right',
      xanchor: 'top',
      y: 1.1,
    },
  };
  if (data && data.data_coord) {
    const minMaxValues = getLowestAndHeightsValuesForAxis(data.data_coord);

    return {
      ...plotLayout,
      xaxis: {
        ...plotLayout.xaxis,
        range: [minMaxValues.x.min, minMaxValues.x.max],
      },
      yaxis: {
        ...plotLayout.yaxis,
        range: [minMaxValues.y.min, minMaxValues.y.max],
      },
    };
  }

  return plotLayout;
};
