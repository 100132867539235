import React, {FC} from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {renderUnits} from 'components/outputs/structures/utils';

type Table1DRecord = {
  label: string;
  unit: string;
  value: string | number;
};

type Table1DStruct = {
  '1d_table_data': Table1DRecord[];
  name: string;
  type: string;
};

// TODO: fix Record<string, any>
const Table1DStruct: FC<{data: Table1DStruct | undefined | Record<string, any>}> = (props) => {
  const {data} = props;

  return (
    <Paper elevation={2} sx={{width: '100%', padding: 2, mb: 5}}>
      <Typography variant={'body1'} color={'primary'} sx={{fontWeight: 500, mb: 2}}>
        {data?.name}
      </Typography>
      <Box sx={{overflow: 'auto'}}>
        <Table sx={{fontSize: 14}} padding={'none'} size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell sx={{py: 1, px: 2}}>Name</TableCell>
              <TableCell sx={{py: 1, px: 2}} align="right">
                Value
              </TableCell>
              <TableCell sx={{py: 1, px: 2}}>Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.['1d_table_data']?.map((record: Table1DRecord, index: number) => {
              return (
                <TableRow key={index} sx={index % 2 ? {} : {backgroundColor: '#eeeeee'}}>
                  <TableCell sx={{py: 1, px: 2}}>{`${record.label}`}</TableCell>
                  <TableCell sx={{py: 1, px: 2}} align="right">{`${record.value}`}</TableCell>
                  <TableCell sx={{py: 1, px: 2}}>{renderUnits(record.unit)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default Table1DStruct;
