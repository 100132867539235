import React, {FC} from 'react';
import {Typography} from '@mui/material';

const HelpPage: FC = () => {
  return (
    <Typography variant="body2">
      For more detailed information, please visit our&nbsp;
      <a href="https://wiki.advacam.cz/wiki/TraX_Engine_Web" target="_blank" rel="noreferrer">
        wiki
      </a>
      &nbsp;page.
    </Typography>
  );
};

export default HelpPage;
