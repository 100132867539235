import React, {useState} from 'react';
import {FC} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {register} from 'redux/actions/authAction';
import {Trans} from '@lingui/macro';
import {Grid, Typography} from '@mui/material';
import HoverTypography from 'components/HoverTypography';
import FormButton from 'components/formComponents/FormButton';
import TextField from 'components/formComponents/TextField';
import {UserType} from 'types/userType';
import {mapErrorsToForm, redirectTo} from 'utils/functions';

type RegisterFormProps = {
  register: any;
};

const RegisterForm: FC<RegisterFormProps> = (props) => {
  const {register} = props;

  const [showThankYou, setShowThankYou] = useState(false);

  const handleLogin = (values: UserType) => {
    return register(values)
      .then(() => {
        setShowThankYou(true);
      })
      .catch((err: any) => {
        return mapErrorsToForm(err);
      });
  };

  const handleRedirectToLogin = () => {
    redirectTo('/login');
  };

  return (
    <Form
      onSubmit={handleLogin}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <Grid container alignItems={'center'} justifyContent={'flex-start'}>
            <Grid item container sx={{minHeight: 220}}>
              {showThankYou ? (
                <>
                  <Grid item xs={12} alignSelf={'center'}>
                    <Typography align={'center'}>
                      <Trans>
                        Your registration was successful, you will receive e-mail with link to set
                        your password.
                      </Trans>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent={'space-between'}
                    alignItems={'flex-end'}
                    sx={{mt: 6}}
                  >
                    <HoverTypography
                      variant={'body2'}
                      onClick={handleRedirectToLogin}
                      text={<Trans>Back to login page</Trans>}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} alignItems={'flex-start'}>
                    <Field
                      name={'email'}
                      render={(props) => <TextField label={<Trans>E-mail</Trans>} {...props} />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'name'}
                      render={(props) => <TextField label={<Trans>Name</Trans>} {...props} />}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{mt: 6}}
                  >
                    <HoverTypography
                      variant={'body2'}
                      onClick={handleRedirectToLogin}
                      text={<Trans>Back to login page</Trans>}
                    />
                    <FormButton sx={{px: 4}} text={<Trans>Register</Trans>} type={'submit'} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({register}, dispatch);
};

export default connect(null, mapDispatchToProps)(RegisterForm);
