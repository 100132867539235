import React, {FC} from 'react';
import {useLocation} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import DataArrayOutlinedIcon from '@mui/icons-material/DataArrayOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {DRAWER_WIDTH, LS_LOGGED_USER} from 'constants/config';
import {getItemFromStorage, redirectTo} from 'utils/functions';

const PrivateDrawerMenu: FC = () => {
  const loggedUser = getItemFromStorage(LS_LOGGED_USER);

  const location = useLocation();

  const menuItems = [
    {
      icon: <DataArrayOutlinedIcon />,
      label: <Trans>Datasets</Trans>,
      url: '/dataset',
    },
    {
      icon: <PeopleAltOutlinedIcon />,
      label: <Trans>Users</Trans>,
      url: '/user-management',
      authorized: loggedUser?.roles?.some((role: Record<string, any>) => role.name === 'admin'),
    },
    {
      icon: <DownloadIcon />,
      label: <Trans>Downloads</Trans>,
      url: '/downloads',
    },
    {
      icon: <HelpOutlineIcon />,
      label: <Trans>Help</Trans>,
      url: '/help',
    },
  ];

  const handleRedirect = (url: string) => () => {
    redirectTo(url);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          marginTop: '50px',
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          zIndex: 1050, // to be under PrivateAppBar
        },
      }}
    >
      <Box sx={{overflow: 'auto'}}>
        <List sx={{pt: 12}}>
          {menuItems.map(
            (item, index) =>
              (item.authorized === undefined || item.authorized === true) && (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    backgroundColor: (theme) =>
                      location.pathname.includes(item.url)
                        ? theme.palette.primary.main + '20'
                        : theme.palette.background.paper,
                    '&:hover': {
                      backgroundColor: (theme) =>
                        location.pathname.includes(item.url)
                          ? theme.palette.primary.main + '20'
                          : theme.palette.primary.main + '10',
                    },
                  }}
                >
                  <ListItemButton
                    onClick={handleRedirect(item.url)}
                    sx={{
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: (theme) => theme.palette.primary.main + 'dd',
                        minWidth: 46,
                        ml: 1,
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              )
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default PrivateDrawerMenu;
