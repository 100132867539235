import React, {FC} from 'react';
import {ToastContainer} from 'react-toastify';

const ToastProvider: FC = () => {
  return (
    <ToastContainer
      position="bottom-right"
      closeOnClick
      pauseOnHover
      pauseOnFocusLoss
      draggable
      newestOnTop
      closeButton={false}
      autoClose={4000}
    />
  );
};

export default ToastProvider;
