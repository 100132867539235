import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {updateBreadcrumbs} from 'redux/actions/breadcrumbsAction';
import {getDataset} from 'redux/actions/datasetAction';
import {Trans} from '@lingui/macro';
import {Grid, Step, StepLabel, Stepper, Typography} from '@mui/material';
import FormButton from 'components/formComponents/FormButton';
import DatasetBasicInfoForm from 'components/forms/DatasetBasicInfoForm';
import DatasetCalibrationMatricesForm from 'components/forms/DatasetCalibrationMatricesForm';
import {CalibrationMatrixResType, DatasetResType} from 'types/datasetType';
import PrivateBreadcrumbs from 'layouts/Private/PrivateBreadcrumbs';

const EditDatasetUploadPage: FC<any> = (props) => {
  const {getDataset, updateBreadcrumbs, detailData, detailLoading} = props;

  const [activeStep, setActiveStep] = useState(2);
  const [calibrationFiles, setCalibrationFiles] = useState<any[]>([]);

  const [datasetBasicInfo, setDatasetBasicInfo] = useState({
    name: detailData.name,
    description: detailData.description,
  });

  const {id} = useParams();

  useEffect(() => {
    getDataset(id).then((res: DatasetResType) => {
      setCalibrationFiles(
        res.calibration_matrices.map((matrix: CalibrationMatrixResType, index: number) => ({
          ...matrix,
          index: index,
        }))
      );
      updateBreadcrumbs({dataset: {id: id, name: res.name}});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item xs={12} sm={12} md={12} lg={11} xl={10}>
      <PrivateBreadcrumbs />
      <Grid item container xs={12} sx={{maxHeight: `calc(100vh - 150px)`, overflow: 'auto'}}>
        <Stepper activeStep={activeStep} sx={{width: '100%', pb: 8, px: 8}}>
          <Step>
            <StepLabel color="primary">
              <Trans>Dataset upload</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel color="primary">
              <Trans>Calibration matrices upload</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel color="primary">
              <Trans>Basic dataset info</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 ? (
          <Grid container>
            <Grid container item xs={12}>
              <Typography variant={'h6'} sx={{mb: 2.5}}>
                <Trans>Dataset upload</Trans>
              </Typography>
              {detailData?.datasets?.map(
                (dataset: {filename: string; size: number}, index: number) => (
                  <Grid key={index} item xs={12}>
                    <Typography
                      sx={{minWidth: '20px', display: 'inline-block'}}
                      component={'span'}
                      variant={'body2'}
                    >{`${index + 1}. `}</Typography>
                    <Typography component={'span'} variant={'body2'}>
                      {dataset.filename}
                    </Typography>
                  </Grid>
                )
              )}
            </Grid>
            <Grid item xs={12} container sx={{mt: 5, pb: 5}} justifyContent={'flex-end'}>
              <FormButton
                sx={{px: 4}}
                onClick={() => {
                  setActiveStep(1);
                }}
                text={<Trans>Continue</Trans>}
              />
            </Grid>
          </Grid>
        ) : activeStep === 1 ? (
          <DatasetCalibrationMatricesForm
            loading={detailLoading}
            setActiveStep={setActiveStep}
            calibrationFiles={calibrationFiles}
            setCalibrationFiles={setCalibrationFiles}
            edit={true}
          />
        ) : (
          <DatasetBasicInfoForm
            setActiveStep={setActiveStep}
            datasetUploadsData={[]}
            detailData={detailData}
            calibrationFiles={calibrationFiles}
            datasetBasicInfo={datasetBasicInfo}
            setDatasetBasicInfo={setDatasetBasicInfo}
            edit={true}
          />
        )}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getDataset, updateBreadcrumbs}, dispatch);
};

const mapStateToProps = (store: any) => {
  return {
    detailLoading: store.datasets.detailLoading,
    detailData: store.datasets.detailData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDatasetUploadPage);
