import moment from 'moment';
import React, {FC, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {updateBreadcrumbs} from 'redux/actions/breadcrumbsAction';
import {delDataset, getDataset} from 'redux/actions/datasetAction';
import {Trans} from '@lingui/macro';
import DataArrayOutlinedIcon from '@mui/icons-material/DataArrayOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import {Card, Chip, Divider, Grid, IconButton, Typography} from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import LoadingMask from 'components/LoadingMask';
import {DATE_TIME_FORMAT} from 'constants/config';
import {TAG_COLORS} from 'constants/enums';
import {useWarningDialog} from 'hooks/useWarningDialog';
import {CalibrationMatrixType} from 'types/datasetType';
import {redirectTo} from 'utils/functions';

const DatasetDetailCard: FC<any> = (props) => {
  const {detailData, detailLoading, delDataset, getDataset, updateBreadcrumbs} = props;

  const {id} = useParams();

  const {
    Dialog: DeleteWarningDialog,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
    handleSetLoading: handleSetLoadingDeleteDialog,
  } = useWarningDialog();

  const handleDeleteDataset = () => {
    handleSetLoadingDeleteDialog(true);
    return delDataset(id).then(() => {
      handleCloseDeleteDialog();
      redirectTo('/dataset');
    });
  };

  useEffect(() => {
    getDataset(id).then((res: any) => {
      updateBreadcrumbs({dataset: {id: res.id, name: res.name}});
    });
  }, []);

  return (
    <>
      <DeleteWarningDialog
        onOk={handleDeleteDataset}
        message={<Trans>Delete dataset {detailData?.name}</Trans>}
      />

      <Card elevation={2} sx={{p: 2.5, position: 'relative'}}>
        <LoadingMask loading={detailLoading}>
          <>
            <Grid container item xs={12} justifyContent={'space-between'} sx={{pb: 1.25}}>
              <Grid item container xs={'auto'} alignItems={'center'} sx={{height: 29}}>
                <DataArrayOutlinedIcon fontSize={'small'} sx={{mr: 2.5}} />
                <Typography variant={'h6'} component={'span'}>
                  {detailData?.name}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={'auto'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                sx={{height: 29}}
              >
                <CustomTooltip title={<Trans>Edit dataset</Trans>}>
                  <IconButton
                    color={'info'}
                    sx={{p: 0, mr: 1.25}}
                    onClick={() => redirectTo(`/dataset/${id}/edit`)}
                  >
                    <EditIcon fontSize={'small'} />
                  </IconButton>
                </CustomTooltip>
                <CustomTooltip title={<Trans>Delete dataset</Trans>}>
                  <IconButton color={'error'} sx={{p: 0}} onClick={handleOpenDeleteDialog}>
                    <DeleteOutlineIcon fontSize={'small'} />
                  </IconButton>
                </CustomTooltip>
              </Grid>
            </Grid>

            <Divider sx={{mb: 2.5, width: '100%'}} />

            <Grid container item xs={12} spacing={1} sx={{mb: 1.25}}>
              {detailData?.tags?.map((tag: any) => (
                <Grid item xs={false} key={tag.id}>
                  <Chip
                    size={'small'}
                    variant="filled"
                    label={tag?.name}
                    style={{
                      color: '#ffffff',
                      fontSize: 11,
                      height: 20,
                      border: '2px solid ' + TAG_COLORS.find((c) => c.value === tag.color)?.color,
                      backgroundColor: TAG_COLORS.find((c) => c.value === tag.color)?.color + 'dd',
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={4}>
                <Typography variant={'body2'}>
                  <Trans>Uploaded:</Trans>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'body2'} sx={{fontWeight: 500}}>
                  {detailData?.created_at
                    ? moment(detailData?.created_at).format(DATE_TIME_FORMAT)
                    : ''}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} sx={{mt: 2.5}}>
              <Grid item xs={4}>
                <Typography variant={'body2'}>
                  <Trans>Calibration matrices:</Trans>
                </Typography>
              </Grid>
              <Grid item container xs={8}>
                <Grid item xs={12}>
                  <Typography variant={'body2'} component={'span'}>
                    <Trans>A:</Trans>
                  </Typography>
                  <Typography variant={'body2'} component={'span'} sx={{ml: 1.25, fontWeight: 500}}>
                    {
                      detailData.calibration_matrices?.find(
                        (mat: CalibrationMatrixType) => mat.type === 'a'
                      )?.filename
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'body2'} component={'span'}>
                    <Trans>B:</Trans>
                  </Typography>
                  <Typography variant={'body2'} component={'span'} sx={{ml: 1.25, fontWeight: 500}}>
                    {
                      detailData.calibration_matrices?.find(
                        (mat: CalibrationMatrixType) => mat.type === 'b'
                      )?.filename
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'body2'} component={'span'}>
                    <Trans>C:</Trans>
                  </Typography>
                  <Typography variant={'body2'} component={'span'} sx={{ml: 1.25, fontWeight: 500}}>
                    {
                      detailData.calibration_matrices?.find(
                        (mat: CalibrationMatrixType) => mat.type === 'c'
                      )?.filename
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'body2'} component={'span'}>
                    <Trans>T:</Trans>
                  </Typography>
                  <Typography variant={'body2'} component={'span'} sx={{ml: 1.25, fontWeight: 500}}>
                    {
                      detailData.calibration_matrices?.find(
                        (mat: CalibrationMatrixType) => mat.type === 't'
                      )?.filename
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} sx={{mt: 2.5}}>
              <Typography variant={'body2'}>{detailData?.description}</Typography>
            </Grid>
          </>
        </LoadingMask>
      </Card>
    </>
  );
};

const mapStateToProps = (store: any) => {
  return {
    detailLoading: store.datasets.detailLoading,
    detailData: store.datasets.detailData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({delDataset, getDataset, updateBreadcrumbs}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetDetailCard);
