import React, {ReactElement} from 'react';
import {Trans} from '@lingui/macro';
import {Box, Grid} from '@mui/material';
import BackgroundImage from 'assets/background.png';
import Footer from 'assets/footer.svg';

function PublicLayout({children}: {children: ReactElement}) {
  const publicLayoutStyle = {
    minHeight: '100vh',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Grid container sx={publicLayoutStyle} justifyContent={'center'} alignContent={'center'}>
      {children}

      <Box
        sx={{
          height: '30px',
          mt: '40px',
          mb: '20px',
          display: 'flex',
          position: 'fixed',
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span
          style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}
          onClick={() => window.open('https://www.eluvia.com/')}
        >
          <span style={{color: '#999999', fontSize: 12}}>
            <Trans>Launched from land to cloud by Eluvians</Trans>
          </span>
          <img style={{height: 18, padding: '0 10px'}} src={Footer} alt={'footer'} />
        </span>
      </Box>
    </Grid>
  );
}

export default PublicLayout;
