// @ts-ignore
import Plotly from 'plotly.js-dist-min';
import React, {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dispatch, bindActionCreators} from 'redux';
import {getTimePlots} from 'redux/actions/outputAction';
import {Trans} from '@lingui/macro';
import SettingsIcon from '@mui/icons-material/Settings';
import {Box, Grid, IconButton, Paper, Typography} from '@mui/material';
import DialogWindow from 'components/DialogWindow';
import LoadingMask from 'components/LoadingMask';
import DownloadButtons from 'components/outputs/DownloadButtons';
import GraphCheckBox from 'components/outputs/GraphCheckBox';
import {renderUnitsLabel} from 'components/outputs/structures/utils';
import {GRAPH_COLORS} from 'constants/enums';

const TimePlotStruct: FC<any> = (props) => {
  const {data, getTimePlots} = props;

  const {id, pId} = useParams();

  const [logScale, setLogScale] = useState(false);
  const [timePlot, setTimePlot] = useState<Record<string, any>>({});
  const [timePlotData, setTimePlotData] = useState([]);
  const [plotLoading, setPlotLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const onClose = () => {
    setOpenDialog(false);
  };

  const plotLayout = {
    margin: {
      l: 60,
      r: 20,
      t: 80,
      b: 60,
    },
    xaxis: {
      title: {
        text: 'Time [s]',
      },
    },
    yaxis: {
      type: logScale ? 'log' : 'linear',
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      yanchor: 'right',
      xanchor: 'top',
      y: 6,
    },
  };

  const setDataToState = (data: any) => {
    const finalData: any = [];

    Object.entries(data.data)?.forEach(([key, value]) => {
      finalData.push({
        mode: 'lines',
        type: 'scatter',
        meta: {key: key},
        line: {
          color: GRAPH_COLORS.find((col: any) => String(col.value) === String(key))?.color,
        },
        x: data.axis?.x_values,
        y: value,
        name: 'total',
      });
    });

    finalData && setTimePlotData(finalData.filter((data: any) => !!data.name));
  };

  const getLayout = () => {
    return {
      ...plotLayout,
      xaxis: {
        title: {
          text: renderUnitsLabel(timePlot?.axis?.x_label) || 'Time [s]',
        },
      },
      yaxis: {
        type: logScale ? 'log' : 'linear',
        title: {
          text: renderUnitsLabel(timePlot?.axis?.y_label),
        },
      },
    };
  };

  const handleDownload = (format = 'png') => {
    const options = {
      format: format,
      width: 1280,
      height: 960,
      filename: data.name,
    };

    Plotly.downloadImage(data.category, options);
  };

  useEffect(() => {
    if (timePlotData) {
      Plotly.react(data.category, timePlotData, getLayout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePlotData, logScale]);

  useEffect(() => {
    if (data?.category) {
      Plotly.newPlot(data.category, timePlotData, plotLayout, {displaylogo: false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data?.category && data?.feature) {
      setPlotLoading(true);
      getTimePlots(id, pId, {category: data.category, feature: data.feature})
        .then((res: any) => {
          if (res?.data?.total) {
            setDataToState(res);
          }
          setTimePlot(res);
        })
        .finally(() => {
          setPlotLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper elevation={2} sx={{width: '100%', padding: 2, mb: 5}}>
      <DialogWindow
        title={<Trans>Time plot setting</Trans>}
        maxWidth={'xs'}
        fullWidth={true}
        open={openDialog}
        onClose={onClose}
      >
        <Grid container alignContent={'flex-start'}>
          <Typography variant={'body1'}>
            <Trans>Scale</Trans>
          </Typography>
          <Grid item xs={12}>
            <GraphCheckBox
              label={<Trans>Logarithmic scale</Trans>}
              checked={logScale}
              handleChange={(val: boolean) => setLogScale(val)}
            />
          </Grid>
        </Grid>
      </DialogWindow>

      <Grid container item xs={12} justifyContent={'center'} sx={{position: 'relative'}}>
        <LoadingMask
          loading={plotLoading}
          containerStyle={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <>
            <div>
              <IconButton
                onClick={() => {
                  setOpenDialog(true);
                }}
                color={'primary'}
                sx={{mr: 2, p: 0}}
              >
                <SettingsIcon fontSize={'small'} />
              </IconButton>
            </div>
            <Box
              sx={{
                maxWidth: 'calc(100% - 16px)',
                width: '100%',
                height: 440,
                boxSizing: 'border-box',
              }}
              id={data?.category}
            />
          </>
        </LoadingMask>
      </Grid>

      <DownloadButtons handleDownload={handleDownload} />
    </Paper>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (store: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getTimePlots}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TimePlotStruct);
